import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  MapAssetL1WithAccordion,
  MapAssetL1WithoutAccordion,
} from "./assetForm";
import CategoryAccordion from "./categoryAccordion";

export const UpperDeckForm = (props) => {
  const {
    upperDeckAssets,
    updateCoachFormData,
    updateInitialData,
    numOfCompartments,
  } = props;
  const [compartmentKey, setCompartmentKey] = useState("compartment_0");

  const exteriorAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Exterior"
  );
  const interiorAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Interior"
  );
  const compartmentAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Compartment"
  );
  const lavatoryAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Lavatories"
  );
  const vestibuleAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Vestibule"
  );

  const exteriorGuardAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Exterior Guard"
  );
  const internalGuardAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Internal Guard"
  );
  const guardSectionCompartmentAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Guard Section Compartment"
  );
  const guardSectionLavatoryAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Guard Section Lavatories"
  );

  const exteriorGeneralAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Exterior General"
  );
  const internalGeneralAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Internal General"
  );
  const generalSectionCompartmentAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "General Section Compartment"
  );
  const generalSectionLavatoryAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "General Section Lavatories"
  );

  const luggageSectionAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Luggage Section"
  );

  const exteriorDivyangAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Exterior Divyang"
  );
  const internalDivyangAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Internal Divyang"
  );
  const divyangSectionCompartmentAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Divyang Section Compartment"
  );
  const divyangSectionLavatoryAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Divyang Section Lavatories"
  );
  const kitchenAreaAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Kitchen Area"
  );
  const foodStorageArea1Assets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Food Storage Area 1"
  );
  const foodStorageArea2Assets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Food Storage Area 2"
  );
  const foodStorageArea3Assets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Food Storage Area 3"
  );
  const gasRoomEquipmentAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Gas Room Equipment"
  );
  const managerCompartmentAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Manager Compartment"
  );
  const staffCompartmentAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Staff Compartment"
  );
  const galleryAssets = upperDeckAssets.filter(
    (asset) => asset.asset_category === "Gallery"
  );
  return (
    <Accordion>
      {exteriorAssets.length > 0 && (
        <CategoryAccordion
          eventKey="exterior"
          label="Exterior"
          categoryData={exteriorAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}

      {interiorAssets.length > 0 && (
        <CategoryAccordion
          eventKey="interior"
          label="Interior"
          categoryData={interiorAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}

      {numOfCompartments > 0 && (
        <Accordion.Item eventKey="compartment" className="no__background">
          <Accordion.Button
            variant="link"
            className="w-100 d-flex justify-content-between product__button"
            bg="light"
          >
            <div className="d-flex justify-content-between">
              <span className="h6 mb-0 fw-bold">Compartments</span>
            </div>
          </Accordion.Button>
          <Accordion.Body className="no__background bg-white shadow-sm">
            <Tabs
              id="controlled-tab-example"
              activeKey={compartmentKey}
              onSelect={(k) => setCompartmentKey(k)}
              className="mb-3"
            >
              {[...Array(numOfCompartments).keys()].map((item) => {
                return (
                  <Tab
                    eventKey={`compartment_${item}`}
                    key={`compartment_${item}`}
                    title={`Compartment ${item + 1}`}
                  >
                    <CompartmentForm
                      compartmentAssets={compartmentAssets}
                      compartmentNum={item}
                      updateCoachFormData={updateCoachFormData}
                      callbackinit={updateInitialData}
                    />
                  </Tab>
                );
              })}
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {lavatoryAssets.length > 0 && (
        <CategoryAccordion
          eventKey="lavatories"
          label="Lavatories"
          categoryData={lavatoryAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}

      {vestibuleAssets.length > 0 && (
        <CategoryAccordion
          eventKey="vestibule"
          label="Vestibule"
          categoryData={vestibuleAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}

      {exteriorGuardAssets.length > 0 && (
        <CategoryAccordion
          eventKey="exterior_guard"
          label="Guard Section Exterior"
          categoryData={exteriorGuardAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {internalGuardAssets.length > 0 && (
        <CategoryAccordion
          eventKey="internal_guard"
          label="Guard Section Internal"
          categoryData={internalGuardAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {guardSectionCompartmentAssets.length > 0 && (
        <CategoryAccordion
          eventKey="compartment_guard"
          label="Guard Section Compartment"
          categoryData={guardSectionCompartmentAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {guardSectionLavatoryAssets.length > 0 && (
        <CategoryAccordion
          eventKey="lavatories_guard"
          label="Guard Section Lavatories"
          categoryData={guardSectionLavatoryAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}

      {exteriorDivyangAssets.length > 0 && (
        <CategoryAccordion
          eventKey="exterior_divyang"
          label="Divyang Section External"
          categoryData={exteriorDivyangAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {internalDivyangAssets.length > 0 && (
        <CategoryAccordion
          eventKey="internal_Divyang"
          label="Divyang Section Internal"
          categoryData={internalDivyangAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {divyangSectionCompartmentAssets.length > 0 && (
        <CategoryAccordion
          eventKey="compartment_divyang"
          label="Divyang Section Compartment"
          categoryData={divyangSectionCompartmentAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {divyangSectionLavatoryAssets.length > 0 && (
        <CategoryAccordion
          eventKey="lavatory_divyang"
          label="Divyang Section Lavatories"
          categoryData={divyangSectionLavatoryAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}

      {luggageSectionAssets.length > 0 && (
        <CategoryAccordion
          eventKey="luggage_section"
          label="Luggage Section"
          categoryData={luggageSectionAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}

      {exteriorGeneralAssets.length > 0 && (
        <CategoryAccordion
          eventKey="exterior_general"
          label="General Section Exterior"
          categoryData={exteriorGeneralAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {internalGeneralAssets.length > 0 && (
        <CategoryAccordion
          eventKey="internal_general"
          label="General Section Internal"
          categoryData={internalGeneralAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {generalSectionCompartmentAssets.length > 0 && (
        <CategoryAccordion
          eventKey="compartment_general"
          label="General Section Compartment"
          categoryData={generalSectionCompartmentAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {generalSectionLavatoryAssets.length > 0 && (
        <CategoryAccordion
          eventKey="general_lavatory"
          label="General Section Lavatories"
          categoryData={generalSectionLavatoryAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {kitchenAreaAssets.length > 0 && (
        <CategoryAccordion
          eventKey="kitchen_area"
          label="Kitchen Area"
          categoryData={kitchenAreaAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {foodStorageArea1Assets.length > 0 && (
        <CategoryAccordion
          eventKey="food_storage_area_1"
          label="Food Storage Area 1"
          categoryData={foodStorageArea1Assets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {foodStorageArea2Assets.length > 0 && (
        <CategoryAccordion
          eventKey="food_storage_area_2"
          label="Food Storage Area 2"
          categoryData={foodStorageArea2Assets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {foodStorageArea3Assets.length > 0 && (
        <CategoryAccordion
          eventKey="food_storage_area_3"
          label="Food Storage Area 3"
          categoryData={foodStorageArea3Assets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {gasRoomEquipmentAssets.length > 0 && (
        <CategoryAccordion
          eventKey="gas_room_equipment"
          label="Gas Room Equipment"
          categoryData={gasRoomEquipmentAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {managerCompartmentAssets.length > 0 && (
        <CategoryAccordion
          eventKey="manager_compartment"
          label="Manager Compartment"
          categoryData={managerCompartmentAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {staffCompartmentAssets.length > 0 && (
        <CategoryAccordion
          eventKey="staff_compartment"
          label="Staff Compartment"
          categoryData={staffCompartmentAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
      {galleryAssets.length > 0 && (
        <CategoryAccordion
          eventKey="gallery"
          label="Gallery"
          categoryData={galleryAssets}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
        />
      )}
    </Accordion>
  );
};

const CompartmentForm = (props) => {
  const {
    compartmentAssets,
    compartmentNum,
    updateCoachFormData,
    callbackinit,
  } = props;
  // console.log("compartment form");
  const updateCompartmentData = (assetName, assetResponse) => {
    // console.log(data);
    // console.log(`Compartment ${compartmentNum} updated!`);
    updateCoachFormData(`${assetName}`, {
      ...assetResponse,
      compartment: compartmentNum + 1,
    });
  };

  const initalData = (assetName, assetResponse) => {
    callbackinit(`${assetName}`, {
      ...assetResponse,
      compartment: compartmentNum + 1,
    });
  };
  return (
    <Accordion>
      <Row>
        {compartmentAssets.map((asset, index) => {
          return (
            <Col
              sm="6"
              key={`${compartmentNum}_asset_${asset.asset_l1_dbid}_${index}`}
            >
              <MapAssetL1WithoutAccordion
                assetCategory={"Compartment"}
                assetL1={asset}
                index={index}
                callback={updateCompartmentData}
                callbackinit={initalData}
                compartmentNum={compartmentNum}
              />
            </Col>
          );
        })}
      </Row>
    </Accordion>
  );
};
