import React, { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { FormatStringDate } from "../../../../components/dateformat";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../../../axios";
import "../../ioh.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { IOHAddressIssues } from "./coachIssueResolution";
import TrainInformation from "../../../../components/TrainInformation";

// const coachOptions = ["13143", "021112", "151342", "10125", "10126", "151383"];

export const IOHIssues = (props) => {
  // const [coachID, setCoachID] = useState(null);
  const [addressIssueData, setAddressIssueData] = useState({});
  const [addressIssueDataOnTable, updateAddressIssueDataOnTable] = useState({});
  const [showAddressIssueTable, setShowAddressIssueTable] = useState(true);
  const [coachOptions, updateCoachOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");
  const [selectedCoach, setSelectedCoach] = useState(null);

  useEffect(() => {
    //Loads data for first page
    axiosInstance
      .get(`assets/coaches/?keyword=issues`, {})
      .then((res) => {
        const data = res.data;
        setAddressIssueData(data);
        updateAddressIssueDataOnTable(data);
        const tempCoachData = [];
        Object.values(data).forEach((item) => {
          // console.group(item.coach_id);
          tempCoachData.push(item.coach_id);
        });
        updateCoachOptions(tempCoachData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCoachView = (coachID) => {
    // console.log(coachID);
    setSelectedCoach(coachID);
  };

  const handleFormSubmission = () => {
    console.log("submitted");
    setSelectedCoach(null);
    updateAddressIssueDataOnTable(addressIssueData);
    setShowAddressIssueTable(true);
  };

  const handleChange = (e) => {
    setSelected();
    setTypeAheadValue(e[0]);
  };

  const handleFilterButtonSubmit = (e) => {
    e.preventDefault();
    // console.log(typeAheadValue);
    handleCoachNumberSubmit(typeAheadValue);
  };
  const handleCoachNumberSubmit = (coachID) => {
    // e.preventDefault();
    if (coachID) {
      let filteredData = addressIssueData.filter(
        (item) => item.coach_id === coachID
      );
      // console.log(filteredData);
      updateAddressIssueDataOnTable(filteredData);
    } else {
      updateAddressIssueDataOnTable(addressIssueData);
    }
  };

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>Address Issues</h6>
        </Card.Header>{" "}
        <Card.Body>
          <Form onSubmit={handleFilterButtonSubmit}>
            <Row>
              <Col sm="4">
                <Form.Group className="">
                  <InputGroup name="coach_id">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Typeahead
                      id="basic-example"
                      onChange={handleChange}
                      options={coachOptions}
                      placeholder="Enter Coach Number..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCoachNumberSubmit(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm="2">
                <Button
                  variant="outline-gray"
                  className=""
                  type="submit"
                  // disabled={buttonDisable}
                >
                  Lookup
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card className="my-2">
        {addressIssueData && showAddressIssueTable && (
          <Table
            // striped
            bordered
            hover
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="text-center">
              <tr>
                <th>#</th>
                <th>Coach Number</th>
                <th>Coach Type</th>
                <th>Train Number</th>
                {/* <th>Coach Returning Date</th> */}
                <th>IOH Date</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {Object.values(addressIssueDataOnTable).map((coach, index) => {
                return (
                  <AddressIssueTable
                    key={`issue_${index}`}
                    coachData={coach}
                    index={index}
                    handleCoachView={handleCoachView}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </Card>
      {selectedCoach && (
        <IOHAddressIssues
          coachID={selectedCoach}
          handleFormSubmission={handleFormSubmission}
          setShowAddressIssueTable={setShowAddressIssueTable}
        />
      )}
    </div>
  );
};

const AddressIssueTable = (props) => {
  const { coachData, index, handleCoachView } = props;
  // console.log("called", coachData.coach_id);
  let [datesToShow, updateDatesToShow] = useState([]);
  let [buttonVariant, updateButtonVariant] = useState("gray");

  useEffect(() => {
    let returnDate = FormatStringDate(coachData.ioh_planned_date);
    updateDatesToShow([returnDate]);
  }, []);

  const handleViewClick = (e) => {
    // updateButtonVariant("success");
    handleCoachView(coachData.coach_id);
  };

  return (
    <tr>
      <td className="text-center">{index + 1}</td>
      <td className="text-center">{coachData.coach_id}</td>
      <td className="text-center">{coachData.coach_type}</td>
      <td className="text-center">
        <TrainInformation coachDataSchedule={coachData.schedule} />
      </td>
      {/* <td className="text-center">
        {datesToShow &&
          datesToShow.map((givenDate) => {
            return (
              <span className="mx-1" key={givenDate}>
                {givenDate}
              </span>
            );
          })}
      </td> */}

      <td className="text-center">{coachData.ioh_planned_date}</td>
      <td className="text-center">
        <Button
          variant={buttonVariant}
          onClick={handleViewClick}
          size="sm"
          // disabled={showChecked}
        >
          View
        </Button>
      </td>
    </tr>
  );
};
