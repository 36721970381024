import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../axios";
import CoachDetails from "./ViewandEditCoach";
import { coachTypeList, coachTypeListUtkrisht } from "../trc_manifest";
import { FormatStringDate } from "../../../../components/dateformat";
import { makePROentryAPI } from "../../../../api/Coaches";

const CoachTable = (props) => {
  const { coachData } = props;

  const StationList = JSON.parse(localStorage.getItem("StationList"));
  const firstStationCode = Object.keys(StationList)[0];
  const initialSt = {
    coach_type_id: 2,
    coach_depot_id: StationList[firstStationCode],
    poh_cycle: "18",

    p_shop: null,
    p_date: null,
    r_date: null,
    reduced_r_date: null,
    extended_r_date: null,
    ioh_shop: null,
    ioh_due_date: null,

    ioh_status: null,
    coach_manuf_info: null,
    certificate: null,
    sick_marking_date: null,
    ioh_planned_date: null,
    ioh_execution_date: null,
    planned_for_ioh_flag: false,
    detachment_reason: null,
    location: null,
  };

  const [coachID, setcoachID] = useState(null);
  const [toggleTable, setToggleTable] = useState(true);
  const [showProModal, setShowProModal] = useState(false);
  const [addPROData, setAddPROData] = useState(initialSt);
  const [iohDueDate, updateIohDueDate] = useState(null);
  const [pohCycle, updatePohCycle] = useState("18");
  // added below 3 states for concatenation of coachId using coach no. & O-rly
  const [str, setStr] = useState(null);
  const [num, setNum] = useState(null);
  const [strNum, setStrNum] = useState(null);
  const [coachType, updateCoachType] = useState("ICF GS");
  const [pohCycleOptions, updatePohCycleOptions] = useState(["18", "24"]);
  const [ifUtkrisht, updateIfUtkrisht] = useState(false);

  const handleCloseProModal = () => {
    setAddPROData(initialSt);
    setShowProModal(false);
  };
  const handleShowProModal = (item) => {
    console.log(item);
    setShowProModal(true);
    setAddPROData({
      ...addPROData,
      coach_id: item.coach_id,
      coach_dbid: item.coach_dbid,
    });
  };

  const handleSubmitPRO = async () => {
    const todayDate = FormatStringDate(new Date());
    console.log(pohCycle);
    const submittedCoachData = {
      ...addPROData,
      ["last_data_update_date"]: todayDate,
      ["poh_cycle"]: pohCycle,
      //now removed because coach_id set in handleShowProModal
      // coach_id: strNum,
    };
    console.log(submittedCoachData);
    try {
      const res = await makePROentryAPI(submittedCoachData);
   
      console.log(res);
      if (res.status === 201) {
        setShowProModal(false);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateDueDate = (p_date, given_poh_cycle = pohCycle) => {
    const date = new Date(p_date);
    date.setMonth(date.getMonth() + given_poh_cycle / 2);
    updateIohDueDate(FormatStringDate(date));
  };
  useEffect(() => {
    setAddPROData({
      ...addPROData,
      ioh_due_date: iohDueDate,
    });
  }, [iohDueDate]);
  const onChangeType = (e) => {
    updateCoachType(e.target.value);
    if (e.target.value.includes("ICF")) {
      updatePohCycleOptions(["18", "24"]);
      onChangePohCycle("18");
    } else {
      updatePohCycleOptions(["36"]);
      onChangePohCycle("36");
    }
    if (!ifUtkrisht) {
      setAddPROData({
        ...addPROData,
        coach_type_id: coachTypeList[e.target.value],
      });
    } else {
      setAddPROData({
        ...addPROData,
        coach_type_id: coachTypeListUtkrisht[e.target.value],
      });
    }
  };
  const onChangePohCycle = (sent_poh_cycle) => {
    updatePohCycle(sent_poh_cycle);
    // setAddCoachData({
    //   ...addCoachData,
    //   poh_cycle: sent_poh_cycle,
    // });

    // if (Object.keys(addPROData).includes("p_date")) {
    //   updateDueDate(addPROData.p_date, sent_poh_cycle);
    // }
    if (
      addPROData.p_date !== null &&
      addPROData.p_date !== undefined &&
      addPROData.p_date !== ""
    ) {
      console.log("p_date available");
      updateDueDate(addPROData.p_date, sent_poh_cycle);
    } else {
      console.log("p_date unavailable");
    }
  };
  const onValueChange2 = (e) => {
    setAddPROData({ ...addPROData, [e.target.name]: e.target.value });
    if (e.target.name === "p_date") {
      updateDueDate(e.target.value);
    }
    //s---concat logic for 2 feilds
    if (e.target.name === "o_rly") {
      setStr(e.target.value);
    }
    if (e.target.name === "coach_id") {
      setNum(e.target.value);
    }

    //E---concat logic for 2 feilds
  };
  useEffect(() => {
    if (num && str) {
      setStrNum(str + num);
      console.log(strNum);
    }
  }, [str, num]);

  const onDepotChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    setAddPROData({
      ...addPROData,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeDueDate = (e) => {
    updateIohDueDate(FormatStringDate(e.target.value));
  };
  const onChangeUtkrisht = (e) => {
    if (!ifUtkrisht) {
      updateIfUtkrisht(true);
      setAddPROData({
        ...addPROData,
        coach_type_id: coachTypeListUtkrisht[coachType],
      });
    } else {
      updateIfUtkrisht(false);
      setAddPROData({
        ...addPROData,
        utkrisht: false,
        coach_type_id: coachTypeList[coachType],
      });
    }
  };

  const ViewCoachDetails = (coachDBID) => {
    setcoachID(coachDBID);
    setToggleTable(false);
  };

  return (
    <>
      {toggleTable && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Coach No.</th>
              <th>Coach Type</th>
              <th>Coach Status</th>
              <th>IOH Status</th>
              <th>Depot</th>
              <th>Location</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {coachData &&
              coachData.map((item, index) => {
                return (
                  <tr key={item.coach_id}>
                    <td>{index + 1}</td>
                    <td>{item.coach_id}</td>
                    <td>{item.coach_type}</td>
                    <td>{item.coach_status}</td>
                    <td>{item.ioh_status}</td>
                    <td>{item.coach_depot}</td>
                    <td>{item.location}</td>
                    <td>
                      <Button
                        onClick={() => ViewCoachDetails(item.coach_dbid)}
                        variant="primary"
                      >
                        Details
                      </Button>
                      {item.coach_status === "Marked for Return" && (
                        <Button
                          onClick={() => handleShowProModal(item)}
                          variant="secondary"
                          style={{ marginLeft: "5px" }}
                        >
                          PRO entry
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}

      {coachID && <CoachDetails coachID={coachID} />}

      {showProModal && (
        <Modal
          show={true}
          onHide={handleCloseProModal}
          centered
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>PRO entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-3">
                <Col sm="3" className="my-2">
                  <Form.Label>Coach No.</Form.Label>
                </Col>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    defaultValue={addPROData.coach_id}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="my-3">
                <Col sm="3" className="my-2">
                  <Form.Label>Coach Type</Form.Label>
                </Col>
                <Col sm="9">
                  <Form.Select
                    aria-label="Default select example"
                    onChange={onChangeType}
                    name="coach_type"
                    required
                  >
                    {Object.keys(coachTypeList).map((coachtype, index) => {
                      return (
                        <option value={coachtype} key={`coach_type_${index}`}>
                          {coachtype}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="my-3">
                <Col sm="3" className="my-2">
                  <Form.Label>Depot</Form.Label>
                </Col>
                <Col sm="9">
                  <Form.Select
                    onChange={onDepotChange}
                    name="coach_depot_id"
                    required
                  >
                    {Object.keys(StationList).map((station, index) => {
                      return (
                        <option
                          value={StationList[station]}
                          key={`coach_depot_id_${index}`}
                        >
                          {station}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              {/* <MyFormComponent
                label="O Rly"
                type="text"
                placeholder="O Rly"
                name="o_rly"
                handleChange={onValueChange2}
                required
              /> */}
              <MyFormComponent
                label="POH Station"
                type="text"
                placeholder="POH Station"
                name="p_shop"
                handleChange={onValueChange2}
              />

              <MyFormComponent
                label="POH Date"
                type="date"
                name="p_date"
                required={true}
                handleChange={onValueChange2}
              />

              <Row className="my-3">
                <Col sm="3" className="my-2">
                  <Form.Label>POH Cycle</Form.Label>
                </Col>
                <Col sm="9">
                  <Form.Select
                    aria-label="Default select"
                    onChange={(e) => {
                      onChangePohCycle(e.target.value);
                    }}
                    name="poh_cycle"
                    required
                  >
                    {pohCycleOptions.map((p_cycle, index) => {
                      return (
                        <option
                          value={p_cycle}
                          key={`p_cycle_${index}`}
                          selected={p_cycle === pohCycle}
                        >
                          {p_cycle}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>

              <MyFormComponent
                label="Return Date"
                type="date"
                name="r_date"
                handleChange={onValueChange2}
              />

              <MyFormComponent
                label="Reduced Return Date"
                type="date"
                name="reduced_r_date"
                handleChange={onValueChange2}
              />

              <MyFormComponent
                label="Extended Return Date"
                type="date"
                name="extended_r_date"
                handleChange={onValueChange2}
              />

              <MyFormComponent
                label="IOH Shop"
                type="text"
                placeholder="IOH Shop"
                name="ioh_shop"
                handleChange={onValueChange2}
              />

              <Row className="my-3">
                <Col sm="3" className="my-2">
                  <Form.Label>IOH Due Date</Form.Label>
                </Col>
                <Col sm="9">
                  <Form.Control
                    type="date"
                    onChange={onChangeDueDate}
                    value={iohDueDate}
                    name="ioh_due_date"
                  />
                </Col>
              </Row>

              {/* <MyFormComponent
                label="IOH Execution Date"
                type="date"
                name="ioh_execution_date"
                handleChange={onValueChange2}
              /> */}
              <MyFormComponent
                label="Utkrisht Modification"
                type="checkbox"
                name="utkrisht"
                handleChange={onChangeUtkrisht}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseProModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmitPRO}>
              Make PRO entry
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const MyFormComponent = (props) => {
  const {
    label,
    type,
    placeholder = null,
    defaultValue,
    name,
    required = false,
    handleChange,
  } = props;
  return (
    <Row className="my-3">
      <Col sm="3" className="my-2">
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col sm="9">
        {type !== "checkbox" && (
          <Form.Control
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            defaultValue={defaultValue}
            name={name}
            required={required}
          />
        )}
        {type === "checkbox" && (
          <Form.Check
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            name={name}
            required={required}
          />
        )}
      </Col>
    </Row>
  );
};
export default CoachTable;
