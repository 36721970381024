import React from 'react'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Badge from "react-bootstrap/Badge";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";


const RakeInfo = (props) => {

    const { RakeInfoSchedule } = props;

    return (<>
        {RakeInfoSchedule.map((r) => {
            return (
                <OverlayTrigger
                key={r.train_number}
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {r?.sun && (
                                <span className="mx-1" key="sun">
                                    <Badge className="mx-2" bg="gray">
                                        Sun
                                    </Badge>
                                </span>
                            )}
                            {r?.mon && (
                                <span className="mx-1" key="mon">
                                    <Badge className="mx-2" bg="gray">
                                        Mon
                                    </Badge>
                                </span>
                            )}
                            {r?.tue && (
                                <span className="mx-1">
                                    <Badge className="mx-2" bg="gray">
                                        Tue
                                    </Badge>
                                </span>
                            )}
                            {r?.wed && (
                                <span className="mx-1">
                                    <Badge className="mx-2" bg="gray">
                                        Wed
                                    </Badge>
                                </span>
                            )}
                            {r?.thu && (
                                <span className="mx-1">
                                    <Badge className="mx-2" bg="gray">
                                        Thu
                                    </Badge>
                                </span>
                            )}
                            {r?.fri && (
                                <span className="mx-1">
                                    <Badge className="mx-2" bg="gray">
                                        Fri
                                    </Badge>
                                </span>
                            )}
                            {r?.sat && (
                                <span className="mx-1">
                                    <Badge className="mx-2" bg="gray">
                                        Sat
                                    </Badge>
                                </span>
                            )}
                        </Tooltip>
                    }
                >
                    <Button variant="light">{r.train_number}</Button>
                </OverlayTrigger>
           
            )
        })}
    </>
    )
}

export default RakeInfo