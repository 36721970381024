const ClearLocalStorage = () => {
  let filteredObj = Object.keys(localStorage).filter(
    (item) =>
      item !== "refresh_token" &&
      item !== "user_group" &&
      item !== "user_name" &&
      item !== "user_email" &&
      item !== "access_token" &&
      item !== "user_dept" &&
      item !== "StationList" &&
      item !== "DeptList"
  );
  filteredObj.forEach((item) => localStorage.removeItem(item));
};
export default ClearLocalStorage;
