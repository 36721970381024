import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../axios";
// import { StationList } from "../trc_manifest";

const EditTrain = (props) => {
  const { onClickEditTrainClose, selectedTrainData, handleTrainEdit } = props;
  const StationList = JSON.parse(localStorage.getItem("StationList"));
  const [trainData, setTrainData] = useState(selectedTrainData);
  const [patchData, updatePatchData] = useState({});
  // console.log(selectedTrainData);

  const onValueChange = (e) => {
    if (e.target.name === "train_name" || e.target.name === "down_no") {
      let tempAliasData = trainData.alias ? trainData.alias : {};
      tempAliasData[e.target.name] = e.target.value;
      updatePatchData({ ...patchData, ["alias"]: tempAliasData });
    } else {
      updatePatchData({
        ...patchData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const updateTrainDetailsSubmit = (e) => {
    e.preventDefault();
    console.log(patchData);
    if (patchData) {
      axiosInstance
        .patch(`assets/trains/${selectedTrainData.train_dbid}/`, patchData)
        .then((res) => {
          const data = res.data;
          console.log(data);
          updatePatchData({});
          handleTrainEdit(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onClickEditTrainClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Form onSubmit={updateTrainDetailsSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Train No. {selectedTrainData.train_number}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Train No.</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="train_number"
                  defaultValue={trainData.train_number}
                  type="text"
                  placeholder="Train No."
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Down No.</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="down_no"
                  defaultValue={trainData.alias?.down_no}
                  type="text"
                  placeholder="Train Name"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Train Name</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="train_name"
                  defaultValue={trainData.alias?.train_name}
                  type="text"
                  placeholder="Train Name"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Starting Station</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="starting_station_id"
                >
                  {/* <option>Open this select menu</option> */}
                  {Object.keys(StationList).map((station, index) => {
                    return (
                      <option
                        value={StationList[station]}
                        selected={station === trainData.starting_station}
                        key={`ctrain_type_${index}`}
                      >
                        {station}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Destination Station</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="destination_station_id"
                >
                  {/* <option>Open this select menu</option> */}
                  {Object.keys(StationList).map((station, index) => {
                    return (
                      <option
                        value={StationList[station]}
                        selected={station === trainData.destination_station}
                        key={`ctrain_type_${index}`}
                      >
                        {station}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Depot</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select onChange={onValueChange} name="depot_id">
                  {/* <option>Open this select menu</option> */}
                  {Object.keys(StationList).map((station, index) => {
                    return (
                      <option
                        value={StationList[station]}
                        selected={station === trainData.depot}
                        key={`ctrain_depot_${index}`}
                      >
                        {station}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Running hours (1 way trip)</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="running_hrs"
                  defaultValue={trainData.running_hrs}
                  type="number"
                  placeholder="running_hrs"
                  max="999.99"
                  min="0"
                  step="0.01"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>No. of Monthly trips</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="monthly_trips"
                  defaultValue={trainData.monthly_trips}
                  type="number"
                  placeholder="monthly_trips"
                />
              </Col>
            </Row>
            {/* <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Train type</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="train_type"
                  required
                >
                  {["","ICF", "LHB"].map((typ, index) => {
                    return (
                      <option value={typ} key={`${typ}_${index}`}>
                        {typ}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Up / Down</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="way_up_down"
                  required
                >
                  {["","UP", "DOWN"].map((way, index) => {
                    return (
                      <option value={way} key={`${way}_${index}`}>
                        {way}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>
                 Days After Departure
                </Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="opposite_start_day_days_after_departure_from_pune"
                  required
                >
                  {["",0, 1, 2, 3, 4, 5].map((day, index) => {
                    return (
                      <option value={day} key={`${day}_${index}`}>
                        {day}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Opposite station departure time</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  placeholder="Opposite station departure time"
                  name="opposite_station_departure_time"
                  onChange={onValueChange}
                  type="time"
                  required
                />
              </Col>
            </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="gray" onClick={onClickEditTrainClose}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Update Train Data
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditTrain;
