import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from "react-bootstrap";

const ComplaintTable = (props) => {
  const {
    complaintsDataOnTable,
    setShowViewModal,
    setSelectedComplaint,
    activePage,
  } = props;
// console.log(complaintsDataOnTable)
  return (
    <Table
      bordered
      hover
      responsive
      className="table-centered rounded mb-0 align-middle"
    >
      <thead className="text-center">
        <tr>
          <th>#</th>
          <th>Complaint Number</th>
          <th>Train Number</th>
          <th>Coach Number</th>
          <th>Receiving DateTime</th>
          <th>Source</th>
          <th>Main Heading</th>
          <th>On Duty Staff</th>
          <th>Department</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {complaintsDataOnTable && (
          <ComplaintPages
            activePage={activePage}
            pageData={complaintsDataOnTable}
            setShowViewModal={setShowViewModal}
            setSelectedComplaint={setSelectedComplaint}
          />
        )}
      </tbody>
    </Table>
  );
};

const ComplaintPages = (props) => {
  const { activePage, pageData, setShowViewModal, setSelectedComplaint } =
    props;

  return (
    <>
      {pageData &&
        pageData.map((complaint, index) => {
          return (
            <tr key={`complaint-${(activePage - 1) * 10 + index}`}>
              <td className="text-center">
                {(activePage - 1) * 10 + index + 1}
              </td>
              <td className="text-center">{complaint.complaint_number}</td>
              <td className="text-center">{complaint.train_number}</td>
              <td className="text-center">{complaint.coach_number}</td>
              <td className="text-center">
                {complaint.receiving_datetime}
                {/* {new Date(complaint.receiving_datetime).toString()} */}
              </td>
              <td className="text-center">{complaint.source}</td>
              <td className="text-center">{complaint.main_heading}</td>
              <td className="text-center">{complaint.on_duty_staff}</td>
              <td className="text-center">{complaint.department_name}</td>
              <td>
                <Button
                  variant="gray"
                  onClick={(e) => {
                    setShowViewModal(true);
                    setSelectedComplaint(complaint);
                  }}
                >
                  View
                </Button>
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default ComplaintTable;
