export const StationList = {
  PA: 1,
  DNR: 2,
  DBG: 3,
  ERS: 4,
  JAT: 5,
  HWH: 6,
  BHUJ: 7,
  ADI: 8,
  VRVL: 9,
  BGKT: 10,
  MUV: 11,
  GKP: 12,
  LIN: 13,
  KZP: 14,
  JASIDIH: 15,
  CSMT: 16,
  AJI: 17,
  NGP: 18,
  KOP: 19,
  GKP: 20,
  HNZM: 21,
  DHN: 22,
  MRJ: 23,
};

export const IOHStatus = {
  "Planned / Under Diagnosis": "Planned / Under Diagnosis",
  "Issues Registered": "Issues Registered",
  "Full Fit": "Full Fit",
  "Fit with Pending Issues": "Fit with Pending Issues",
  Unfit: "Unfit",
};

export const CoachStatus = {
  "Not Specified": "Not Specified",
  Sick: "Sick",
  Spare: "Spare",
  Running: "Running",
  "Marked for Return": "Marked for Return",
  Condemned: "Condemned",
  Accidental: "Accidental",
  "POH - Non Returnable": "POH - Non Returnable",
  "At Foreign Railway": "At Foreign Railway",
  "At Parent Depot": "At Parent Depot",
};

export const DetachmentReasons = {
  IOH: "IOH",
  "IOH-NSM": "IOH-NSM",
  POH: "POH",
  ACCIDENTAL: "ACCIDENTAL",
  "Rake Adjustments": "Rake Adjustments",
  "Enroute Detachment": "Enroute Detachment",
  "Secondary End Detachment": "Secondary End Detachment",
};

export const coachTypeList = {
  "ICF GS": 2,
  "ICF CN": 3,
  "ICF WCB": 4,
  "ICF GSCZ": 5,
  "ICF CZAC": 6,
  "ICF ACCW": 7,
  "ICF ACCN": 8,
  "ICF FAC": 9,
  "ICF GSLR": 10,
  "ICF GSLRD": 12,
  "ICF NMG": 22,
  // "ICF SCZ": 32,
  "ICF ACCZ": 33,
  "ICF VP": 34,
  "LHB LS": 13,
  "LHB LWSCZAC": 14,
  "LHB LWFCZAC": 15,
  "LHB LWSCZ": 16,
  "LHB LWFAC": 17,
  "LHB LWACCW": 18,
  "LHB LWACCN": 19,
  "LHB LWLRRM": 20,
  "LHB LSLRD": 21,
  "LHB LVPH": 23,
  "LHB LWACCNE": 24,
  "LHB LWCBAC": 25,
  "LHB LWSCN": 26,
  "LHB LWS": 30,
  // "LHB LWCZAC": 31,
  "LHB LVP": 35,
  "LHB LWCTZAC": 66,
  "ICF FCWAC": 68,
};

export const coachTypeListUtkrisht = {
  "ICF GS": 36,
  "ICF CN": 37,
  "ICF WCB": 38,
  "ICF GSCZ": 39,
  "ICF CZAC": 49,
  "ICF ACCW": 40,
  "ICF ACCN": 41,
  "ICF FAC": 42,
  "ICF GSLR": 43,
  "ICF GSLRD": 44,
  "ICF NMG": 45,
  // "ICF SCZ": 46,
  "ICF ACCZ": 47,
  "ICF VP": 48,
  "LHB LS": 50,
  "LHB LWSCZAC": 51,
  "LHB LWFCZAC": 52,
  "LHB LWSCZ": 53,
  "LHB LWFAC": 54,
  "LHB LWACCW": 55,
  "LHB LWACCN": 56,
  "LHB LWLRRM": 57,
  "LHB LSLRD": 58,
  "LHB LVPH": 59,
  "LHB LWACCNE": 60,
  "LHB LWCBAC": 61,
  "LHB LWSCN": 62,
  "LHB LWS": 63,
  // "LHB LWCZAC": 64,
  "LHB LVP": 65,
  "LHB LWCTZAC": 67,
  "ICF FCWAC": 69,
};
