import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { FormatSimpleDate } from "../../../../components/dateformat";
import { InputGroup } from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../../../axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AlertMessage } from "../diagnose/formHandling/formComponents";

export const IOHAddressIssues = (props) => {
  const { coachID, handleFormSubmission, setShowAddressIssueTable } = props;
  const [coachData, setCoachData] = useState(null);
  const [infoToShow, updateInfoToShow] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [infoVariant, setInfoVariant] = useState(null);
  const [infoMsg, setInfoMsg] = useState(null);
  const [issueAction, updateIssueAction] = useState({});
  const [enableResolveButton, updateEnableResolveButton] = useState(false);

  useEffect(() => {
    //Loads data for first page
    axiosInstance
      .get(`assets/coach-issues/?coach_id=${coachID}`, {})
      .then((res) => {
        const data = res.data;
        console.log(data);
        setCoachData(data);
        const tempAction = [];
        data.issues.map((item) => {
          tempAction.push({
            issue_dbid: item.issue_dbid,
            status: item.status,
            asset_group: item.asset_group,
          });
        });
        if (tempAction.length === 0) {
          updateEnableResolveButton(true);
        }
        updateIssueAction(tempAction);
        if (
          data.ioh_status === "Issues Registered" ||
          data.ioh_status === "Fit with Pending Issues" ||
          data.ioh_status === "Unfit"
        ) {
          updateInfoToShow("data");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setShowForm(false);
  }, [coachID]);

  const handleShowForm = (e) => {
    e.preventDefault();
    setShowAddressIssueTable(false);
    setShowForm(true);
  };

  const handleIssueStatusChange = (issue_id, issue_status) => {
    const tempAction = issueAction;

    let givenIndex = tempAction.findIndex(
      (item) => item.issue_dbid == issue_id
    );
    let givenItem = tempAction[givenIndex];
    givenItem.status = issue_status;
    tempAction.splice(givenIndex, 1, givenItem);
    // console.log(tempAction);
    let nullCount = 0;
    tempAction.forEach((issue) => {
      if (issue.status === null) {
        nullCount += 1;
        console.log(nullCount);
      }
    });
    if (nullCount === 0) {
      updateEnableResolveButton(true);
    }
    updateIssueAction(tempAction);
  };

  const handleIssueResolution = (e) => {
    e.preventDefault();
    // console.log(issueAction);
    let new_ioh_status = "Full Fit";
    let new_coach_status = "Spare";
    let infoVariantTemp = "success";
    let infoMsgTemp = `IOH of ${coachData.coach_id} is completed and it is ready to use.`;

    issueAction.forEach((issue) => {
      if (issue.status === "Pending") {
        console.log(issue.asset_group);
        if (issue.asset_group === "Upper Deck") {
          if (new_ioh_status !== "Unfit") {
            new_ioh_status = "Fit with Pending Issues";

            infoVariantTemp = "warning";
            infoMsgTemp = `IOH of ${coachData.coach_id} is completed and it is ready to use with Pending Issues.`;
          }
        } else if (issue.asset_group === "Under Gear") {
          new_ioh_status = "Unfit";
          new_coach_status = "Sick";
          infoVariantTemp = "danger";
          infoMsgTemp = `IOH of ${coachData.coach_id} is not completed & coach is marked Unfit`;
        }
      }
    });
    // console.log(new_ioh_status);
    axiosInstance
      .patch(`assets/coach-issues/?coach_id=${coachData.coach_id}`, {
        ioh_status: new_ioh_status,
        coach_status: new_coach_status,
        planned_for_ioh_flag: false,
        issue_action: issueAction,
        detachment_reason: null,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        updateInfoToShow("submitted");
        setInfoVariant(infoVariantTemp);
        setInfoMsg(infoMsgTemp);
        setShowAddressIssueTable(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {coachData && (
        <Card className="text-center">
          <Card.Body>
            <Row>
              <Col sm="4">
                <Table hover>
                  <tbody>
                    <tr>
                      <th>Coach No</th>
                      <td>{coachData.coach_id}</td>
                    </tr>
                    <tr>
                      <th>Coach Type</th>
                      <td>{`${coachData.coach_properties?.coach_make} ${coachData.coach_properties?.coach_type} ${coachData.coach_properties?.coach_level}`}</td>
                    </tr>
                    <tr>
                      <th>Rake/Train</th>
                      <td>
                        {coachData.rake_details?.map((item, index) => {
                          return (
                            <span
                              key={`rake_details-${index}`}
                              className="mx-1"
                            >
                              {item.rake_name}
                            </span>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col sm="4">
                <Table hover>
                  <tbody>
                    <tr>
                      <th>POH Date</th>
                      <td>{FormatSimpleDate(coachData.p_date)}</td>
                    </tr>
                    <tr>
                      <th>IOH Planned Date</th>
                      <td>{FormatSimpleDate(coachData.ioh_planned_date)}</td>
                    </tr>
                    <tr>
                      <th>IOH Execution Date</th>
                      <td>{FormatSimpleDate(coachData.ioh_execution_date)}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col sm="4">
                <Table hover>
                  <tbody>
                    <tr>
                      <th>Return Date</th>
                      <td>{FormatSimpleDate(coachData.r_date)}</td>
                    </tr>
                    <tr>
                      <th>POH Station</th>
                      <td>{coachData.p_shop}</td>
                    </tr>
                    <tr>
                      <th>Data Updated on</th>
                      <td>
                        {FormatSimpleDate(coachData.last_data_update_date)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            {!showForm && (
              <Button
                variant="outline-gray"
                className="my-3"
                onClick={handleShowForm}
              >
                Start Issue Resolution
              </Button>
            )}
          </Card.Body>
        </Card>
      )}
      {infoToShow === "no-issue" && (
        <div className="my-2 d-flex justify-content-center">
          <AlertMessage
            variant={"success"}
            heading={`${coachData.coach_id}`}
            message={`${coachData.coach_id} has no issue.`}
          />
        </div>
      )}

      {infoToShow === "submitted" && (
        <div className="my-2 d-flex justify-content-center">
          <AlertMessage
            variant={infoVariant}
            heading={`${coachData.coach_id}`}
            message={infoMsg}
          />
        </div>
      )}

      {showForm && (
        <>
          <div className="my-2">
            {infoToShow === "data" && (
              <MiscInfo misc_info={coachData.misc_info} />
            )}
          </div>

          {infoToShow === "data" && (
            <Card>
              <Table
                bordered
                hover
                responsive
                className="table-centered table-nowrap rounded mb-0"
              >
                <thead>
                  <tr>
                    <th colSpan="1" className="text-center">
                      #
                    </th>
                    <th colSpan="1" className="text-center">
                      Particulars
                    </th>
                    <th colSpan="1" className="text-center">
                      Response
                    </th>
                    <th colSpan="1" className="text-center">
                      Problem
                    </th>
                    <th colSpan="1" className="text-center">
                      Description
                    </th>
                    <th colSpan="3" className="text-center">
                      Action
                    </th>
                    <th colSpan="1" className="text-center">
                      Remark
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Replaced</th>
                    <th>Repaired</th>
                    <th>Pending</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {coachData.issues.map((assetIssue, index) => {
                    return (
                      <IssueTable
                        key={`issue_${index}`}
                        // assetName={assetIssue}
                        asset={assetIssue}
                        index={index}
                        handleIssueStatusChange={handleIssueStatusChange}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          )}

          {infoToShow === "data" && (
            <div className="d-flex justify-content-center">
              <Button
                variant="success"
                onClick={handleIssueResolution}
                disabled={!enableResolveButton}
              >
                Resolve Issues
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

const IssueTable = (props) => {
  const { asset, index, handleIssueStatusChange } = props;
  const [assetStatus, updateAssetStatus] = useState(asset.status);
  const handleChange = (e) => {
    // let tempAssetData = assetData;
    // tempAssetData.status = e.target.value;
    // console.log(tempAssetData);
    updateAssetStatus(e.target.value);
    handleIssueStatusChange(asset.issue_dbid, e.target.value);
  };
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{asset.asset_l1_detailed_name}</td>
      <td>{asset.asset_l1_issue}</td>
      <td>
        {asset.asset_l1_subset
          ? asset.asset_l1_subset
          : asset.asset_l2_detailed_name}
      </td>
      <td>{asset.asset_l2_issue}</td>
      <td className="text-center">
        <Form.Check
          inline
          value="Replaced"
          name={`${asset.asset_l1_detailed_name}_${index}`}
          type={"radio"}
          id={`${index}_replaced_radio`}
          onChange={handleChange}
          checked={assetStatus == "Replaced"}
        />
      </td>
      <td className="text-center">
        <Form.Check
          inline
          value="Repaired"
          name={`${asset.asset_l1_detailed_name}_${index}`}
          type={"radio"}
          id={`${index}_replaired_radio`}
          onChange={handleChange}
          checked={assetStatus == "Repaired"}
        />
      </td>
      <td className="text-center">
        <Form.Check
          inline
          value="Pending"
          name={`${asset.asset_l1_detailed_name}_${index}`}
          type={"radio"}
          id={`${index}_pending_radio`}
          onChange={handleChange}
          checked={assetStatus == "Pending"}
        />
      </td>
      <td></td>
    </tr>
  );
};

const MiscInfo = (props) => {
  const { misc_info } = props;
  // console.log(misc_info);
  return (
    <Card>
      <Form className="m-2">
        <Row>
          <Col lg="3">
            <fieldset disabled>
              <InputGroup>
                <InputGroup.Text>Working Date</InputGroup.Text>

                <Form.Control
                  id="disabledTextInput"
                  defaultValue={misc_info["Working Date"]}
                  type="date"
                  name="Working Date"
                />
              </InputGroup>
            </fieldset>
          </Col>

          <Col lg="3">
            <fieldset disabled>
              <InputGroup>
                <InputGroup.Text>Line No.</InputGroup.Text>

                <Form.Control
                  type="number"
                  name="Line No"
                  placeholder={misc_info["Line No"]}
                  defaultValue={misc_info["Line No"]}
                />
              </InputGroup>
            </fieldset>
          </Col>

          <Col lg="2">
            <fieldset disabled>
              <InputGroup>
                <InputGroup.Text>IOH Type</InputGroup.Text>

                <Form.Control
                  name="IOH Type"
                  defaultValue={misc_info["IOH Type"]}
                ></Form.Control>
              </InputGroup>
            </fieldset>
          </Col>

          <Col lg="4">
            <fieldset disabled>
              <InputGroup>
                <InputGroup.Text>Supervisor</InputGroup.Text>

                <Form.Control
                  name="Supervisor"
                  defaultValue={misc_info["Supervisor"]}
                />
              </InputGroup>
            </fieldset>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
