import React, { useState } from "react";
import { OnPitItems } from "./ChecklistOnPit";
import { OtherItems } from "./ChecklistOnPit";
import {
  Accordion,
  Card,
  Form,
  Modal,
  Button,
  Row,
  Table,
  Col,
} from "react-bootstrap";
import { DropdownButton, Dropdown } from "react-bootstrap";
import {
  MapAssetL1WithoutAccordion,
  MapAssetL1WithAccordion,
} from "../ioh/diagnose/formHandling/assetForm";
import CategoryAccordion from "../ioh/diagnose/formHandling/categoryAccordion";
import ConfirmationModal from "./ConfirmationModal";

const DiagnoseOnPit = (props) => {
  const { handleDiagnosisOnPit } = props;
  const [markingData, updateMarkingData] = useState(null);

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (markingData && Object.keys(markingData).length > 0) {
      setShow(true);
    }
  };

  const handleIssueMarking = (label, data) => {
    let tempMarkingData = markingData;
    if (data.response === "OK") {
      delete tempMarkingData[label];
      updateMarkingData(tempMarkingData);
      console.log(tempMarkingData);
    } else {
      updateMarkingData({ ...markingData, [label]: data });
      console.log({ ...markingData, [label]: data });
    }
  };
  const handleSubmit = (actionData) => {
    // e.preventDefault();
    // console.log(actionData);
    handleDiagnosisOnPit(actionData);
  };

  return (
    <>
      <Card>
        <Form>
          <Card.Body>
            <MainHeadRender
              label="On Pit"
              eventKey="on_pit"
              categoryData={OnPitItems}
              handleIssueMarking={handleIssueMarking}
            />

            <MainHeadRender
              label="Other"
              eventKey="other"
              categoryData={OtherItems}
              handleIssueMarking={handleIssueMarking}
            />
          </Card.Body>
          <Card.Footer>
            <Button variant="outline-success" onClick={handleShow}>
              Next
            </Button>
          </Card.Footer>
        </Form>
      </Card>

      {/* modal */}
      {show && (
        <ConfirmationModal
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          markingData={markingData}
        />
      )}
    </>
  );
};

const MainHeadRender = (props) => {
  const { label, eventKey, categoryData, handleIssueMarking } = props;

  const updateInitialData = (data, data2) => {
    // console.log(data, data2);
  };
  const updateCoachFormData = (heading, data) => {
    if (data) {
      data["main_head"] = label;
      // console.log(heading, data);
      handleIssueMarking(heading, data);
    }
  };
  return (
    <>
      {label !== "Other" &&
        categoryData.map((asset, index) => {
          return (
            <Col sm="12" key={`${asset.asset_l1_dbid}${index}`}>
              <Accordion>
                <MapAssetL1WithAccordion
                  assetCategory={label}
                  assetL1={asset}
                  index={index}
                  callback={updateCoachFormData}
                  mode={"on-pit"}
                  callbackinit={updateInitialData}
                />
              </Accordion>
            </Col>
          );
        })}
      {/* {label === "Other" && (
        <Row className="my-2 mx-1">
          <Col sm="6">
            <MapAssetL1WithoutAccordion
              assetCategory={"Other"}
              assetL1={categoryData[0]}
              index={0}
              callback={updateCoachFormData}
              callbackinit={updateInitialData}
              mode="nsm"
              colSize="12"
            />
          </Col>
        </Row>
      )} */}
    </>
  );
};

export default DiagnoseOnPit;
