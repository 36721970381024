import React, { useEffect } from "react";
import { axiosInstance } from "../../axios";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes";

const LogOut = (props) => {
  let navigate = useNavigate();

  useEffect(() => {
    // const response = axiosInstance.post("user/logout/blacklist/", {
    //   refresh_token: localStorage.getItem("refresh_token"),
    // });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_group");
    localStorage.removeItem("DeptList");
    localStorage.removeItem("user_dept");
    localStorage.removeItem("StationList");
    // localStorage.removeItem("group");
    // localStorage.removeItem("manifest");
    // localStorage.removeItem("subscribed_products");
    // localStorage.removeItem("table_data_manifest");
    axiosInstance.defaults.headers["Authorization"] = null;
    navigate(URLs.Signin.path);
  });
  return (
    // <Redirect
    //   to={{ pathname: URLs.Signin.path, state: { from: props.location } }}
    // />
    <div></div>
  );
};

export default LogOut;
