export const nsmchecklistDataL1 = [
  {
    asset_dbid: 209,
    asset_name: "Other",
    responseType: "text_field",
    options: [],
    default_response: "",
    transition_key: null,
    extra_label: "",
    hint: "",
    //   options: ["OK", "NOT OK"],
    //   default_response: "OK",
    //   default_type: "",
    //   default_reading: null,
    //   transition_key: null,
  },
  {
    asset_dbid: 148,
    asset_name: "AR Branch Pipe",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Air Brake",
  },
  {
    asset_dbid: 210,
    asset_name: "P Tube",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "P Tube",
  },
  {
    asset_dbid: 211,
    asset_name: "Buffer Case",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Buffer Case",
  },
  {
    asset_dbid: 212,
    asset_name: "Buffer Plunger",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Buffer Plunger",
  },
  {
    asset_dbid: 213,
    asset_name: "Dashpot Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Dashpot Spring",
  },
  {
    asset_dbid: 214,
    asset_name: "Oil Cup",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Oil Cup",
  },

  {
    asset_dbid: 149,
    asset_name: "BP Pipe",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Air Brake",
  },
  {
    asset_dbid: 150,
    asset_name: "Control Reservoir",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Air Brake",
  },
  {
    asset_dbid: 151,
    asset_name: "DV",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Air Brake",
  },
  {
    asset_dbid: 152,
    asset_name: "FP Pipe",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Air Brake",
  },

  {
    asset_dbid: 7,
    asset_name: "Berth",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Carpentry",
  },

  {
    asset_dbid: 11,
    asset_name: "Door",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Carpentry",
  },
  {
    asset_dbid: 153,
    asset_name: "Flooring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Carpentry",
  },
  {
    asset_dbid: 154,
    asset_name: "Luggage Door",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Carpentry",
  },
  {
    asset_dbid: 155,
    asset_name: "Shutter",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Carpentry",
  },

  {
    asset_dbid: 156,
    asset_name: "Alternator",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Electrical",
  },

  {
    asset_dbid: 157,
    asset_name: "Compressor",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Electrical",
  },
  {
    asset_dbid: 158,
    asset_name: "Invertor",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Electrical",
  },
  {
    asset_dbid: 159,
    asset_name: "Less Cooling",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Electrical",
  },
  {
    asset_dbid: 160,
    asset_name: "Pully",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Electrical",
  },
  {
    asset_dbid: 161,
    asset_name: "Tension Rod",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Electrical",
  },
  {
    asset_dbid: 162,
    asset_name: "V Belt",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Electrical",
  },
  {
    asset_dbid: 163,
    asset_name: "WRA",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Electrical",
  },
  {
    asset_dbid: 164,
    asset_name: "FAC Upgradation",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Miscellaneous",
  },

  {
    asset_dbid: 165,
    asset_name: "Gas Leakage",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Miscellaneous",
  },
  {
    asset_dbid: 166,
    asset_name: "Mechanical Work",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Miscellaneous",
  },
  {
    asset_dbid: 167,
    asset_name: "OMRS",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Miscellaneous",
  },
  {
    asset_dbid: 168,
    asset_name: "Tiles",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Miscellaneous",
  },
  {
    asset_dbid: 169,
    asset_name: "Utkrisht",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Miscellaneous",
  },
  {
    asset_dbid: 170,
    asset_name: "Fumigation",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Pest and Rodent",
  },

  {
    asset_dbid: 172,
    asset_name: "Commode",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Plumbing",
  },
  {
    asset_dbid: 16,
    asset_name: "Pipeline",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Plumbing",
  },
  {
    asset_dbid: 173,
    asset_name: "Water Tank",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Plumbing",
  },
  {
    asset_dbid: 174,
    asset_name: "Air Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },
  {
    asset_dbid: 175,
    asset_name: "Fiba",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },
  {
    asset_dbid: 176,
    asset_name: "Primary Inner Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },

  {
    asset_dbid: 177,
    asset_name: "Primary Outer Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },
  {
    asset_dbid: 178,
    asset_name: "Rubber Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },
  {
    asset_dbid: 179,
    asset_name: "Secondary Inner Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },
  {
    asset_dbid: 180,
    asset_name: "Secondary Outer Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },
  {
    asset_dbid: 181,
    asset_name: "Secondary Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },
  {
    asset_dbid: 182,
    asset_name: "Seats",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Suspension",
  },
  {
    asset_dbid: 44,
    asset_name: "Anchor Link",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },

  {
    asset_dbid: 183,
    asset_name: "Anti Roll Bar",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 184,
    asset_name: "Buffer",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 185,
    asset_name: "CBC",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 186,
    asset_name: "Connecting Link",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 187,
    asset_name: "Control Arm",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 188,
    asset_name: "Dashpot",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 189,
    asset_name: "Equalising Stay",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },

  {
    asset_dbid: 190,
    asset_name: "Hand Brake",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 191,
    asset_name: "Safety Strap",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 192,
    asset_name: "Screw Coupling",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 193,
    asset_name: "Side Bearer",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 194,
    asset_name: "Traction Center",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 195,
    asset_name: "Trolley Frame",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 196,
    asset_name: "Unusual Sound",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 197,
    asset_name: "Vestibule",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Under Gear",
  },
  {
    asset_dbid: 198,
    asset_name: "Axle Box",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },

  {
    asset_dbid: 199,
    asset_name: "CTRB",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 200,
    asset_name: "Deep Flange",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 201,
    asset_name: "Flat Tyre",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 202,
    asset_name: "Grazing",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 203,
    asset_name: "Grease Oozing",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 204,
    asset_name: "Grooved Wheel",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 205,
    asset_name: "Sharp Flange",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },

  {
    asset_dbid: 206,
    asset_name: "Shelling",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 207,
    asset_name: "Spring",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 208,
    asset_name: "Thermal Crack",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
  {
    asset_dbid: 171,
    asset_name: "Spread Rim",
    responseType: "switch2",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    main_head: "Wheel Set",
  },
];
