import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { axiosInstance } from "../../../axios";
import "../ioh.css";
import ComplaintModal from "./viewComplaint";
import AddComplaintModal from "./addComplaint";
import UploadComplaints from "./uploadComplaints";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "@themesberg/react-bootstrap";
import ComplaintTable from "./complaintTable";
import { Pagination, Spinner } from "react-bootstrap";
import FilterComplaints from "./filterComplaints";
import { GetTrainListAPI } from "../../../api/TrainsApi";

const Complaints = (props) => {
  const [complaintsList, setComplaintsList] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedCompalint, setSelectedComplaint] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [trainNoList, setTrainNoList] = useState(null);

  //filter
  const [filterUrl, setFilterUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //pagination
  const [totalPages, updateTotalPages] = useState(null);
  const [activePage, setactivePage] = useState(1);
  const { size = "md", withIcons = false, disablePrev = false } = props;

  useEffect(() => {
    getTrainListF();
  }, []);
  const getTrainListF = async () => {
    try {
      const res = await GetTrainListAPI();
      setTrainNoList(res.data);
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const GetListOfComplaints = async (parameters, page) => {
    setIsLoading(true);

    const res = await axiosInstance
      .get(`assets/complaints/?${parameters}`, {})
      .then((res) => {
        const data = res.data;
        // console.log(data);
        setIsLoading(false);
        setComplaintsList(data.results);
        setDataCount(data.count);
        updateTotalPages(data.lastPage);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleViewModalClose = () => {
    setShowViewModal(false);
    setSelectedComplaint(null);
  };
  const handleAddModalClose = () => {
    setShowAddModal(false);
  };
  const handleUploadModalClose = () => {
    setShowUploadModal(false);
  };
  const handleComplaintUpdatedData = (updatedComplaintData) => {
    console.log(updatedComplaintData);
    const index = complaintsList.findIndex(
      (item) => item.complaint_dbid === updatedComplaintData.complaint_dbid
    );
    // const index = complaintsData.indexOf(item);
    // console.log(index)
    if (index > -1) {
      const tempData = complaintsList;
      tempData.splice(index, 1, updatedComplaintData); // 2nd parameter means remove one item only
      // updateComplaintsData(tempData)
      // console.log(tempData)
    }
  };
  const handleComplaintAddition = (data) => {
    let tempComplaintsData = complaintsList;
    if (data) {
      tempComplaintsData.unshift(data);
      setComplaintsList(tempComplaintsData);
    }
  };
  const handleComplaintUpload = (data) => {
    setFilterUrl(null);
    setactivePage(1);
    GetListOfComplaints("");
  };

  const handleFilter = (filterData) => {
    setFilterUrl(filterData);
    setactivePage(1);
    GetListOfComplaints(filterData);
  };

  useEffect(() => {
    const newUrl = `${filterUrl}&page=${activePage}`;
    GetListOfComplaints(newUrl);
  }, [activePage]);

  const onPrevItem = () => {
    const prevactivePage = activePage === 1 ? activePage : activePage - 1;
    setactivePage(prevactivePage);
  };

  const onNextItem = (totalPages) => {
    const nextactivePage =
      activePage === totalPages ? activePage : activePage + 1;
    setactivePage(nextactivePage);
  };

  const items = [];
  let startingPage = 1;
  if ((activePage > 6) & (totalPages > 10)) {
    startingPage = activePage - 5;
  }
  let endPage = totalPages;
  if (totalPages > 10) {
    endPage = 10;
  }
  if (activePage > 5) {
    endPage = activePage + 5;
  }
  if (totalPages - activePage < 5) {
    endPage = totalPages;
  }
  for (let number = startingPage; number <= endPage; number++) {
    const isItemActive = activePage === number;

    const handlePaginationChange = () => {
      setactivePage(number);
    };

    items.push(
      <Pagination.Item
        aria-controls={`example-collapse-text-${number}`}
        aria-expanded={activePage}
        active={isItemActive}
        key={number}
        onClick={handlePaginationChange}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>Complaints Management</h6>
          {isLoading && <Spinner animation="border" variant="info" />}

          <div>
            <Button
              variant="outline-gray"
              className="mx-2"
              onClick={(e) => {
                setShowUploadModal(true);
              }}
            >
              Upload Sheet
            </Button>
            {trainNoList && (
              <Button
                variant="outline-gray"
                className="mx-2"
                onClick={(e) => {
                  setShowAddModal(true);
                }}
              >
                Add Complaint
              </Button>
            )}
          </div>
        </Card.Header>
      </Card>

      <FilterComplaints handleFilter={handleFilter} dataCount={dataCount} />
      {complaintsList && (
        <Card>
          <Card.Body>
            <ComplaintTable
              complaintsDataOnTable={complaintsList}
              totalPages={totalPages}
              activePage={activePage}
              setShowViewModal={setShowViewModal}
              setSelectedComplaint={setSelectedComplaint}
            />
          </Card.Body>
          <Card.Footer>
            {totalPages && (
              <Col xl={12}>
                <Pagination size={size} className="mt-3">
                  <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
                    {withIcons ? (
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    ) : (
                      "Previous"
                    )}
                  </Pagination.Prev>
                  {items}
                  <Pagination.Next onClick={() => onNextItem(totalPages)}>
                    {withIcons ? (
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    ) : (
                      "Next"
                    )}
                  </Pagination.Next>
                </Pagination>
              </Col>
            )}
          </Card.Footer>
        </Card>
      )}
      {showViewModal && selectedCompalint && (
        <ComplaintModal
          complaint={selectedCompalint}
          handleModalClose={handleViewModalClose}
          handleComplaintUpdatedData={handleComplaintUpdatedData}
        />
      )}
      {showAddModal && (
        <AddComplaintModal
          handleModalClose={handleAddModalClose}
          handleComplaintAddition={handleComplaintAddition}
          trainNoList={trainNoList}
        />
      )}
      {showUploadModal && (
        <UploadComplaints
          handleModalClose={handleUploadModalClose}
          handleComplaintUpload={handleComplaintUpload}
        />
      )}
    </div>
  );
};

export default Complaints;
