export const ElectricalMainHead = [
  "Air Conditioner",
  "Charging Points",
  "Fans",
  "Lights",
  "Others",
];
export const ElectricalSubHead = [
  "Alternator failure",
  "Alter failure",
  "Blower motor failure",
  "Blower runner damaged",
  "Battery failure",
  "Complaint between 23 hrs to 05 hrs",
  "Compressor failure",
  "CD motor failure",
  "CD runner damaged",
  "DA set failure",
  "Fan failure",
  "Fan blade broken",
  "Fuse blown",
  "Gas leakage",
  "HOG failure",
  "Holder defective",
  "Inverter failure",
  "LED failure",
  "Low suction pressure",
  "Microprocessor failure",
  "MCB tripped",
  "Others",
  "Pump defective",
  "Rotary switch defect",
  "RCBO tripped/defective",
  "RBC/EBC failure",
  "RMPU system choked up",
  "RRU failure",
  "Socket damaged",
  "Switch defective",
  "Thermostat failure",
  "V-Belts broken",
  "Wiring issue",
  "5 KVA transformer failure",
];
export const coachTypesData = [
  { "coach_type": "ACCN", "isAC": "AC" },
  { "coach_type": "ACCW", "isAC": "AC" },
  { "coach_type": "ACCZ", "isAC": "AC" },
  { "coach_type": "CN", "isAC": "Non AC" },
  { "coach_type": "CZ", "isAC": "Non AC" },
  { "coach_type": "EC", "isAC": "Non AC" },
  { "coach_type": "FAC", "isAC": "Non AC" },
  { "coach_type": "GS", "isAC": "Non AC" },
  { "coach_type": "LWCZ", "isAC": "Non AC" },
  { "coach_type": "SL", "isAC": "Non AC" },
  { "coach_type": "VISTADOM", "isAC": "AC" },
  { "coach_type": "NIL", "isAC": "NA" },
  { "coach_type": "NA", "isAC": "NA" },
];
