import React, { Component, useState, useEffect } from "react";
import { Table, Button, Card, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../../../axios";
import ViewRakes from "./ViewRakes";
import EditTrain from "./EditTrain";
import { useNavigate } from "react-router";
import AddTrain from "./AddTrain";
import "../../ioh.css";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "@themesberg/react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

const Trains = () => {
  let navigate = useNavigate();

  const [trainsList, setTrainsList] = useState(null);
  const [trainsOnTable, updateTrainsOnTable] = useState(null);
  const [options, updateOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");

  const [showAddTrain, setShowAddTrain] = useState(false);
  const [selectedTrainData, setSelectedTrainData] = useState(null);
  const [editTrain, setEditTrain] = useState(false);
  const [viewRakes, setViewRakes] = useState(false);

  useEffect(() => {
    getTrainsList();
  }, []);

  // api call get
  const getTrainsList = async () => {
    try {
      const res = await axiosInstance.get(`assets/trains/`, {});
      // console.log(res.data);
      const responseData = res.data;
      setTrainsList(responseData);
      updateTrainsOnTable(responseData);
      const tempData = [];
      Object.values(responseData).forEach((item) => {
        // console.group(item.coach_id);
        if (item.train_number && !tempData.includes(item.train_number)) {
          tempData.push(item.train_number);
        }
      });
      // console.log(tempComplaintData);
      updateOptions(tempData);
      //   console.log(trainsList);
    } catch (error) {
      console.log(error);
    }
  };

  //Start- opening modal of add button

  const handleAddTrainShow = () => {
    // navigate('/trc-management/rake')
    setShowAddTrain(true);
  };
  const handleAddTrainClose = () => setShowAddTrain(false);
  //End- opening modal of add button
  const handleAddTrain = (data) => {
    let tempTrains = trainsList;
    tempTrains.unshift(data);
    setTrainsList(tempTrains);
    updateTrainsOnTable(tempTrains);
    setShowAddTrain(false);
    setSelectedTrainData(null);
  };

  //Start- opening modal of edit button and veiw rakes

  // const onClickEditTrainShow = () => {
  //     setEditTrain(true);

  // }
  const onClickEditTrainClose = () => {
    setEditTrain(false);
    setSelectedTrainData(null);
  };
  const handleTrainEdit = (data) => {
    let tempTrains = trainsList;
    tempTrains = tempTrains.filter(
      (item) => item.train_number !== selectedTrainData.train_number
    );
    tempTrains.unshift(data);
    console.log(tempTrains);
    setTrainsList(tempTrains);
    updateTrainsOnTable(tempTrains);
    setEditTrain(false);
    setSelectedTrainData(null);
  };

  const onViewRakesShow = () => {
    setViewRakes(true);
  };
  const onViewRakesClose = () => {
    setViewRakes(false);
    setSelectedTrainData(null);
  };
  //end- opening modal of edit button and veiw rakes

  const handleChange = (e) => {
    setSelected();
    setTypeAheadValue(e[0]);
  };

  const handleOptionSubmitButton = (e) => {
    e.preventDefault();
    // console.log(typeAheadValue);
    handleOptionSubmit(typeAheadValue);
  };
  const handleOptionSubmit = (value) => {
    if (value) {
      let filteredData = trainsList.filter(
        (item) => item.train_number === value
      );

      // console.log(filteredData);
      updateTrainsOnTable(filteredData);
    } else {
      updateTrainsOnTable(trainsList);
    }
  };

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>Trains</h6>
          {trainsList===null && <Spinner animation="border" variant="info" />}

          <Button
            variant="outline-success"
            className="mx-2"
            onClick={handleAddTrainShow}
          >
            Add New Train
          </Button>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col sm="4">
                <Form.Group className="">
                  <InputGroup name="coach_id">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Typeahead
                      id="basic-example"
                      onChange={handleChange}
                      options={options}
                      placeholder="Enter to filter..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleOptionSubmit(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm="2">
                <Button
                  variant="outline-gray"
                  className=""
                  onClick={handleOptionSubmitButton}
                >
                  Enter
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Table
            striped
            bordered
            hover
            responsive
            className="text-center align-middle"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Train No.</th>
                <th>Train Name</th>
                <th>Starting Station</th>
                <th>Destination Station</th>
                <th>No. of Rakes</th>
                <th>Depot</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {trainsOnTable &&
                trainsOnTable.map((train, index) => {
                  return (
                    <TrainRow
                      trainData={train}
                      index={index}
                      setSelectedTrainData={setSelectedTrainData}
                      setEditTrain={setEditTrain}
                      setViewRakes={setViewRakes}
                      key={train.train_dbid}
                    />
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {editTrain === true && (
        <EditTrain
          onClickEditTrainClose={onClickEditTrainClose}
          selectedTrainData={selectedTrainData}
          handleTrainEdit={handleTrainEdit}
        />
      )}
      {viewRakes === true && (
        <ViewRakes
          onViewRakesShow={onViewRakesShow}
          viewRakes={viewRakes}
          onViewRakesClose={onViewRakesClose}
          selectedTrainData={selectedTrainData}
        />
      )}
      {showAddTrain === true && (
        <AddTrain
          showAddTrain={showAddTrain}
          handleAddTrainClose={handleAddTrainClose}
          handleAddTrain={handleAddTrain}
        />
      )}
    </div>
  );
};

const TrainRow = (props) => {
  const { trainData, index, setViewRakes, setEditTrain, setSelectedTrainData } =
    props;

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{trainData.train_number}</td>
      <td>{trainData.alias && trainData.alias["train_name"]}</td>
      <td>{trainData.starting_station}</td>
      <td>{trainData.destination_station}</td>
      <td>{trainData.number_of_rakes}</td>
      <td>{trainData.depot}</td>

      <td>
        <Button
          className="mx-2"
          variant="outline-gray"
          onClick={() => {
            setEditTrain(true);
            setSelectedTrainData(trainData);
          }}
        >
          Edit
        </Button>
        <Button
          className="mx-2"
          variant="outline-gray"
          onClick={() => {
            setViewRakes(true);
            setSelectedTrainData(trainData);
          }}
        >
          View Rakes
        </Button>
      </td>
    </tr>
  );
};

export default Trains;
