import React, { useEffect, useState } from "react";
import {
  Col,
  Button,
  Form,
  Row,
  Table,
  Card,
  Modal,
  Accordion,
} from "react-bootstrap";
import { axiosInstance } from "../../../../axios";
import {
  FormatSimpleDate,
  FormatStringDate,
} from "../../../../components/dateformat";
import {
  coachTypeList,
  coachTypeListUtkrisht,
  CoachStatus,
  IOHStatus,
  DetachmentReasons,
} from "../trc_manifest";
import { useNavigate } from "react-router";
import { URLs } from "../../../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import CertificateModal from "./CertificateModal";

const CoachDetails = (props) => {
  const { coachID } = props;
  const [coachData, setCoachData] = useState(null);
  const [mode, setMode] = useState("READ");
  const [patchData, updatePatchData] = useState({});
  const [ifUtkrisht, updateIfUtkrisht] = useState(false);
  const [coachType, updateCoachType] = useState(null);
  const [pohCycleOptions, updatePohCycleOptions] = useState(null);
  const [pohCycle, updatePohCycle] = useState(null);
  const [iohDueDate, updateIohDueDate] = useState(null);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [iD4Certificate, setID4Certificate] = useState(null);
  const StationList = JSON.parse(localStorage.getItem("StationList"));

  let navigate = useNavigate();

  let user_groups = [];
  try {
    user_groups = JSON.parse(localStorage.getItem("user_group"));
  } catch {
    console.log("Subscribed Products list empty");
  }

  useEffect(() => {
    axiosInstance
      .get(`assets/coaches/${coachID}`, {})
      .then((res) => {
        const data = res.data;
        console.log(data);
        setCoachData(data);
        updateIfUtkrisht(data.coach_properties?.coach_level === "Utkrishta");
        updateCoachType(
          `${data.coach_properties?.coach_make} ${data.coach_properties?.coach_type}`
        );
        updatePohCycle(data.poh_cycle);
        updateIohDueDate(data.ioh_due_date);
        if (data.coach_properties?.coach_make === "ICF") {
          updatePohCycleOptions(["18", "24"]);
        } else if (data.coach_properties?.coach_make === "LHB") {
          updatePohCycleOptions(["36"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [coachID]);

  const handleEditClick = () => {
    setMode("EDIT");
  };
  const handleCancelEditClick = () => {
    setMode("READ");
    updatePatchData({});
  };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    console.log(patchData);

    try {
      if (patchData) {
        const todayDate = FormatStringDate(new Date());
        const submittedCoachData = {
          ...patchData,
          ["last_data_update_date"]: todayDate,
        };

        const res = await axiosInstance.patch(
          `assets/coaches/${coachID}/`,
          submittedCoachData
        );
        const data = res.data;
        setCoachData(data);
        setMode("READ");
        updatePatchData({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(coachData);
  const handleDateChanges = (e) => {
    let value = e.target.value;
    // handle null values in date
    if (value === "") {
      if (
        e.target.name === "p_date" ||
        e.target.name === "ioh_planned_date" ||
        e.target.name === "ioh_execution_date"
      ) {
        console.log("skipping updating null value");
      } else {
        updatePatchData({ ...patchData, [e.target.name]: null });
      }
    } else {
      // to handle non null values
      // special case - IOH execution date cant be greater than today date
      if (e.target.name === "ioh_execution_date") {
        const todayDate = new Date();
        const givenDate = new Date(value);

        if (todayDate.getTime() < givenDate.getTime()) {
          console.log(todayDate.getTime() - givenDate.getTime());
          // console.log("User cannot set the Future Execution Date");
          updatePatchData({
            ...patchData,
            [e.target.name]: coachData.ioh_execution_date,
          });
        } else {
          updatePatchData({ ...patchData, [e.target.name]: e.target.value });
        }
      } else {
        // handling general values other than ioh execution date
        updatePatchData({ ...patchData, [e.target.name]: e.target.value });
        // special case - trigger due date change
        if (e.target.name === "p_date") {
          console.log("p_date_changed");
          updateDueDate(value);
        }
      }
    }
  };

  const handleChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    if (e.target.name === "coach_depot") {
      updatePatchData({ ...patchData, coach_depot_id: e.target.value });
      console.log({ ...patchData, coach_depot_id: e.target.value });
    } else {
      updatePatchData({ ...patchData, [e.target.name]: e.target.value });
    }
  };

  const onChangeUtkrisht = (e) => {
    console.log(e.target.name, e.target.value);
    if (ifUtkrisht === false) {
      updateIfUtkrisht(true);
      updatePatchData({
        ...patchData,
        coach_type_id: coachTypeListUtkrisht[coachType],
      });
      // console.log(coachTypeListUtkrisht[coachType]);
    } else {
      updateIfUtkrisht(false);
      updatePatchData({
        ...patchData,
        coach_type_id: coachTypeList[coachType],
      });
      // console.log(coachTypeList[coachType]);
    }
  };

  // certificate modal
  const handleCloseCertificateModal = () => setShowCertificateModal(false);
  const handleShowCertificateModal = (coachData) => {
    setID4Certificate(coachData);
    setShowCertificateModal(true);
  };
  const refreshCoachData4NewCertificate = (resCoachData) => {
    setCoachData(resCoachData);
    handleCloseCertificateModal();
  };

  const onChangeType = (e) => {
    updateCoachType(e.target.value);
    if (e.target.value.includes("ICF")) {
      updatePohCycleOptions(["18", "24"]);
      onChangePohCycle("18");
    } else {
      updatePohCycleOptions(["36"]);
      onChangePohCycle("36");
    }
    if (ifUtkrisht === false) {
      updatePatchData({
        ...patchData,
        coach_type_id: coachTypeList[e.target.value],
      });
      // console.log(coachTypeList[e.target.value]);
    } else {
      updatePatchData({
        ...patchData,
        coach_type_id: coachTypeListUtkrisht[e.target.value],
      });
      // console.log(coachTypeListUtkrisht[e.target.value]);
    }
  };

  const onChangePohCycle = (sent_poh_cycle) => {
    updatePohCycle(sent_poh_cycle);
    updatePatchData({
      ...patchData,
      poh_cycle: sent_poh_cycle,
    });
    if (Object.keys(patchData).includes("p_date")) {
      updateDueDate(patchData.p_date, sent_poh_cycle);
    } else {
      updateDueDate(coachData.p_date, sent_poh_cycle);
    }
  };

  const onChangeDueDate = (e) => {
    updateIohDueDate(FormatStringDate(e.target.value));
  };

  const updateDueDate = (p_date, given_poh_cycle = pohCycle) => {
    const date = new Date(p_date);
    date.setMonth(date.getMonth() + given_poh_cycle / 2);
    console.log(FormatStringDate(date));
    updateIohDueDate(FormatStringDate(date));
  };

  useEffect(() => {
    updatePatchData({
      ...patchData,
      ioh_due_date: iohDueDate,
    });
  }, [iohDueDate]);

  return (
    <>
      {coachData && (
        <Row>
          <Col sm="12">
            <Card>
              <Form onSubmit={handleSaveClick}>
                <Card.Body>
                  <Table
                    bordered
                    hover
                    responsive
                    className="table-centered table-nowrap rounded mb-0"
                  >
                    <tbody>
                      <ControlForm
                        label="Coach No"
                        mode={mode}
                        name="coach_id"
                        defaultValue={coachData.coach_id}
                        type="text"
                        handleChange={handleChange}
                      />
                      <SelectForm
                        label="Coach Type"
                        mode={mode}
                        name="coach_type"
                        defaultValue={`${coachData.coach_properties?.coach_make} ${coachData.coach_properties?.coach_type}`}
                        defaultUtkrishtaValue={`${
                          coachData.coach_properties?.coach_level ===
                          "Utkrishta"
                            ? "Utkrisht"
                            : ""
                        }`}
                        options={coachTypeList}
                        handleChange={onChangeType}
                      />
                      <SelectForm
                        label="Coach Status"
                        mode={
                          mode === "READ"
                            ? "READ"
                            : user_groups?.includes("Super Admin")
                            ? "EDIT"
                            : "READ"
                        }
                        name="coach_status"
                        defaultValue={coachData.coach_status}
                        options={CoachStatus}
                        handleChange={handleChange}
                      />
                      <SelectForm
                        label="IOH Status"
                        mode="READ"
                        name="ioh_status"
                        defaultValue={coachData.ioh_status}
                        options={IOHStatus}
                        // handleChange={handleChange}
                      />

                      <SelectForm
                        label="Detachment Reason"
                        mode={mode}
                        name="detachment_reason"
                        defaultValue={coachData.detachment_reason}
                        options={DetachmentReasons}
                        handleChange={handleChange}
                      />

                      {/* <ControlForm
                        label="Number of Compartments"
                        mode={mode}
                        name="number_of_compartments"
                        defaultValue={coachData.number_of_compartments}
                        type="number"
                        handleChange={handleChange}
                      /> */}
                      <ControlForm
                        label="O Rly"
                        mode={mode}
                        name="o_rly"
                        defaultValue={coachData.o_rly}
                        //   type="text"
                        handleChange={handleChange}
                      />
                      <tr>
                        <th>Rake/Train</th>
                        <td className="mx-0">
                          {coachData.rake_details?.map((item, index) => {
                            return (
                              <span key={`rake_details-${index}`}>
                                <Button
                                  variant="outline-gray"
                                  className="mr-2"
                                  size="sm"
                                  onClick={(e) => {
                                    navigate(URLs.RakeManagement.path, {
                                      state: {
                                        rake_name: item.rake_name,
                                        rake_dbid: item.rake_dbid,
                                        selected_coach_dbid:
                                          coachData.coach_dbid,
                                      },
                                    });
                                  }}
                                >
                                  {item.rake_name}
                                </Button>
                              </span>
                            );
                          })}
                        </td>
                      </tr>
                      <ControlForm
                        label="POH Date"
                        mode={mode}
                        name="p_date"
                        defaultValue={coachData.p_date}
                        type="date"
                        handleChange={handleDateChanges}
                      />

                      <tr>
                        <th>POH Cycle</th>
                        {mode === "READ" && <td>{coachData.poh_cycle}</td>}
                        {mode === "EDIT" && (
                          <td>
                            <Form.Select
                              aria-label="Default select"
                              onChange={(e) => {
                                onChangePohCycle(e.target.value);
                              }}
                              name="poh_cycle"
                              required
                            >
                              {pohCycleOptions.map((p_cycle, index) => {
                                return (
                                  <option
                                    value={p_cycle}
                                    key={`p_cycle_${index}`}
                                    selected={p_cycle == pohCycle}
                                  >
                                    {p_cycle}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </td>
                        )}
                      </tr>
                      <ControlForm
                        label="POH Station"
                        mode={mode}
                        name="p_shop"
                        defaultValue={coachData.p_shop}
                        type="text"
                        handleChange={handleChange}
                      />
                      <ControlForm
                        label="Return Date"
                        mode={mode}
                        name="r_date"
                        defaultValue={coachData.r_date}
                        type="date"
                        handleChange={handleDateChanges}
                      />

                      <ControlForm
                        label="Reduced Return Date"
                        mode={mode}
                        name="reduced_r_date"
                        defaultValue={coachData.reduced_r_date}
                        type="date"
                        handleChange={handleDateChanges}
                      />

                      <ControlForm
                        label="Extended Return Date"
                        mode={mode}
                        name="extended_r_date"
                        defaultValue={coachData.extended_r_date}
                        type="date"
                        handleChange={handleDateChanges}
                      />
                      <tr>
                        <th>IOH Due Date</th>
                        {mode === "READ" && (
                          <td>{FormatSimpleDate(iohDueDate)}</td>
                        )}
                        {mode === "EDIT" && (
                          <td>
                            <Form.Control
                              onChange={onChangeDueDate}
                              name="ioh_due_date"
                              value={iohDueDate}
                              type="date"
                            />
                          </td>
                        )}
                      </tr>
                      <ControlForm
                        label="Sick Marking Date"
                        mode="READ"
                        name="sick_marking_date"
                        defaultValue={coachData.sick_marking_date}
                        type="date"
                        // handleChange={handleChange}
                      />

                      <ControlForm
                        label="IOH Planned Date"
                        mode={mode}
                        name="ioh_planned_date"
                        defaultValue={coachData.ioh_planned_date}
                        type="date"
                        handleChange={handleDateChanges}
                      />
                      <ControlForm
                        label="IOH Execution Date"
                        mode={mode}
                        name="ioh_execution_date"
                        defaultValue={coachData.ioh_execution_date}
                        type="date"
                        handleChange={handleDateChanges}
                      />
                      {/* <ControlForm
                        label="Upload Part B Certification"
                        mode={mode}
                        name="part_b_certification"
                        // defaultValue={coachData.ioh_execution_date}
                        type="file"
                        handleChange={handleChange}
                      /> */}

                      <tr>
                        <th>Utkrisht Modification</th>
                        {mode === "READ" && (
                          <td>
                            {coachData.coach_properties.coach_level ===
                            "Utkrishta"
                              ? "Yes"
                              : "No"}
                          </td>
                        )}
                        {mode === "EDIT" && (
                          <td>
                            <Form.Check
                              onChange={onChangeUtkrisht}
                              name="utkrisht"
                              checked={ifUtkrisht}
                              type="checkbox"
                            />
                          </td>
                        )}
                      </tr>
                      {coachData.coach_status !== "Running" && (
                        <ControlForm
                          label="Location"
                          mode={mode}
                          name="location"
                          defaultValue={coachData.location}
                          type="text"
                          handleChange={handleChange}
                        />
                      )}

                      <SelectForm
                        label="Coach Depot"
                        mode={mode}
                        name="coach_depot"
                        defaultValue={coachData.coach_depot}
                        options={StationList}
                        handleChange={handleChange}
                      />

                      {/* <tr>
                        <th>Coach Depot</th>
                        <td>
                          {coachData.coach_depot}
                        </td>
                      </tr> */}
                      <tr>
                        <th>Date Updated On</th>
                        <td>
                          {FormatSimpleDate(coachData.last_data_update_date)}
                        </td>
                      </tr>
                      {coachData.coach_properties.coach_type === "WCB" && (
                        <tr>
                          <th>Part A / Part B Certificate</th>
                          <td>
                            {coachData.certificate && (
                              <a
                                href={`${process.env.REACT_APP_MEDIA_URL}${coachData.certificate}`}
                                target="__blank"
                              >
                                <Button size="sm">View Certificate</Button>
                              </a>
                            )}
                            <Button
                              onClick={() => {
                                handleShowCertificateModal(coachData);
                              }}
                              size="sm"
                              className="mx-2"
                            >
                              <FontAwesomeIcon icon={faPen} className="me-2" />{" "}
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                  {mode === "READ" && (
                    <Button
                      onClick={handleEditClick}
                      variant="gray"
                      className="m-1"
                    >
                      Edit
                    </Button>
                  )}
                  {mode === "EDIT" && (
                    <>
                      <Button
                        onClick={handleCancelEditClick}
                        variant="gray"
                        className="m-1"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSaveClick}
                        variant="success"
                        className="m-1"
                        type="submit"
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Card.Footer>
              </Form>
            </Card>

            {coachData.coach_history?.length > 0 && (
              <Card>
                <Card.Body>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // marginTop: "10px",
                    }}
                  >
                    <h4>History</h4>
                  </div>

                  {coachData.coach_history.map((item, index) => {
                    return (
                      <Accordion key={index}>
                        <Accordion.Item className="no__background" eventKey="0">
                          <Accordion.Button className="product__button">
                            {index + 1}. {item.timestamp}
                          </Accordion.Button>
                          <Accordion.Body>
                            <Table
                              bordered
                              hover
                              responsive
                              className="table-centered table-nowrap rounded mb-0"
                            >
                              <tbody>
                                <tr>
                                  <th>Coach No</th>
                                  <td className="mx-0">{item.coach_id}</td>
                                </tr>
                                <tr>
                                  <th>Coach Type</th>
                                  <td className="mx-0">
                                    {item.coach_properties?.coach_make}{" "}
                                    {item.coach_properties?.coach_type}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Coach Status</th>
                                  <td className="mx-0">{item.coach_status}</td>
                                </tr>
                                <tr>
                                  <th>IOH Status</th>
                                  <td className="mx-0">{item.ioh_status}</td>
                                </tr>
                                <tr>
                                  <th>Detachment Reason</th>
                                  <td className="mx-0">
                                    {item.detachment_reason}
                                  </td>
                                </tr>
                                <tr>
                                  <th>O Rly</th>
                                  <td className="mx-0">{item.o_rly}</td>
                                </tr>
                                {/* <tr>
                                  <th>Rake/Train</th>
                                  <td className="mx-0"></td>
                                </tr> */}
                                <tr>
                                  <th>POH Date</th>
                                  <td className="mx-0">{item.p_date}</td>
                                </tr>
                                <tr>
                                  <th>POH Cycle</th>
                                  <td className="mx-0">{item.poh_cycle}</td>
                                </tr>
                                <tr>
                                  <th>POH Station</th>
                                  <td className="mx-0">{item.p_shop}</td>
                                </tr>
                                <tr>
                                  <th>Return Date</th>
                                  <td className="mx-0">{item.r_date}</td>
                                </tr>
                                <tr>
                                  <th>Reduced Return Date</th>
                                  <td className="mx-0">
                                    {item.reduced_r_date}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Extended Return Date</th>
                                  <td className="mx-0">
                                    {item.extended_r_date}
                                  </td>
                                </tr>
                                <tr>
                                  <th>IOH Due Date</th>
                                  <td className="mx-0">{item.ioh_due_date}</td>
                                </tr>
                                <tr>
                                  <th>Sick Marking Date</th>
                                  <td className="mx-0">
                                    {item.sick_marking_date}
                                  </td>
                                </tr>
                                <tr>
                                  <th>IOH Planned Date</th>
                                  <td className="mx-0">
                                    {item.ioh_planned_date}
                                  </td>
                                </tr>
                                <tr>
                                  <th>IOH Execution Date</th>
                                  <td className="mx-0">
                                    {item.ioh_execution_date}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Utkrisht Modification</th>
                                  <td className="mx-0">
                                    {" "}
                                    {item.coach_properties?.coach_level ===
                                    "Utkrishta"
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Location</th>
                                  <td className="mx-0">{item.location}</td>
                                </tr>
                                {item.coach_properties?.coach_type === "WCB" &&
                                  item.certificate && (
                                    <tr>
                                      <th>Part A / Part B Certificate</th>
                                      <td className="mx-0">
                                        <a
                                          href={`${process.env.REACT_APP_MEDIA_URL}/${item.certificate}`}
                                          target="__blank"
                                        >
                                          <Button size="sm">
                                            View Certificate
                                          </Button>
                                        </a>
                                      </td>
                                    </tr>
                                  )}
                                <tr>
                                  <th>Timestamp</th>
                                  <td className="mx-0">{item.timestamp}</td>
                                </tr>
                                {/* {[1, 2, 3].map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <th>{item}</th>
                                      <td className="mx-0">{item}</td>
                                    </tr>
                                  );
                                })} */}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    );
                  })}
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      )}
      {/* {showEditCoach && (
        <EditCoachCard
          coachID={coachID}
          showEditCoach={showEditCoach}
          coachData={coachData}
        />
      )}
      {coachID && <DeleteCoachCard coachID={coachID} />}
      <Button onClick={handleEdit} variant="secondary" className="m-1">
        Edit
      </Button> */}

      {showCertificateModal && (
        <CertificateModal
          handleCloseCertificateModal={handleCloseCertificateModal}
          refreshCoachData4NewCertificate={refreshCoachData4NewCertificate}
          iD4Certificate={iD4Certificate}
        />
      )}
    </>
  );
};

const ControlForm = (props) => {
  const { label, mode, name, defaultValue, type, handleChange } = props;
  return (
    <tr>
      <th>{label}</th>
      {mode === "READ" && (
        <td>
          {type === "date" ? FormatSimpleDate(defaultValue) : defaultValue}
        </td>
      )}
      {mode === "EDIT" && (
        <td>
          <Form.Control
            onChange={handleChange}
            name={name}
            defaultValue={defaultValue}
            type={type}
            accept=".pdf,.jpg,.jpeg,.png"
          />
        </td>
      )}
    </tr>
  );
};

const SelectForm = (props) => {
  const {
    label,
    mode,
    name,
    defaultValue,
    options,
    handleChange,
    defaultUtkrishtaValue,
  } = props;
  return (
    <tr>
      <th>{label}</th>
      {mode === "READ" && (
        <td>
          {defaultValue} {defaultUtkrishtaValue}
        </td>
      )}
      {mode === "EDIT" && (
        <td>
          <Form.Select onChange={handleChange} name={name}>
            {/* <option>Open this select menu</option> */}
            {(name === "ioh_status" ||
              name === "detachment_reason" ||
              name === "coach_depot") && (
              <option selected={defaultValue === null} value={null}></option>
            )}
            {Object.keys(options).map((option, index) => {
              return (
                <option
                  value={name === "coach_type" ? option : options[option]}
                  selected={option === defaultValue}
                  key={`coach_type_${index}`}
                >
                  {option}
                </option>
              );
            })}
          </Form.Select>
        </td>
      )}
    </tr>
  );
};

const DeleteCoachCard = (props) => {
  const { coachID } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteCoach = async () => {
    try {
      // const res = await axiosInstance.delete(`assets/coach/?coach_id=${coachID}`,{});
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Delete
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Danger Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>{coachID} Coach will be deleted</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDeleteCoach();
              handleClose();
            }}
          >
            Final Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoachDetails;
