import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../axios";

const EditUserModal = (props) => {
  const { showModal, handleClose, userToUpdate, handleUpdateUser } = props;

  const [userToUpdate2, setUserToUpdate2] = useState(userToUpdate);
  const { user_name, email, full_name, id, mobile_num } = userToUpdate2;

  const onValueChange = (e) => {
    console.log(e.target.value);
    setUserToUpdate2({ ...userToUpdate2, [e.target.name]: e.target.value });
  };

  const handleUserUpdate = async (e) => {
    try {
      e.preventDefault();
      const res = await axiosInstance.patch(
        `auth/user/?user_id=${id}`,
        userToUpdate2
      );
      console.log(res);
      handleUpdateUser(res.data);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleUserUpdate}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Update User Details</h4>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row className="my-3">
              <Col sm="3">
                <Form.Label>Username</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="user_name"
                  required
                  type="text"
                  placeholder="Username"
                  value={user_name}
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3">
                <Form.Label>Email</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  value={email}
                  onChange={onValueChange}
                  name="email"
                  type="email"
                  placeholder="Enter email"
                />
              </Col>
            </Row>
            {/* <Row className="my-3" >
                            <Col sm="3">
                                <Form.Label>Password</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Control onChange={onValueChange} name='password' required type="password" placeholder="New Password" />
                            </Col>
                        </Row> */}
            <Row className="my-3">
              <Col sm="3">
                <Form.Label>Full Name</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  value={full_name}
                  onChange={onValueChange}
                  name="full_name"
                  type="text"
                  placeholder="Full Name"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3">
                <Form.Label>Mobile</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  value={mobile_num}
                  onChange={onValueChange}
                  name="mobile_num"
                  type="number"
                  placeholder="Mobile"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditUserModal;
