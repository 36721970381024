import React, { useState } from 'react'
import { Button, Modal, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { PatchStaffAPI } from '../../../api/StaffApi';
import { axiosInstance } from '../../../axios';

const UpdateStaff = (props) => {
  const { handleCloseEditModal, handlePatchDataOnList, oneStaffData } = props;

  const [toggleEdit, setToggleEdit] = useState(false);
  const [updatedData, setUpdatedData] = useState(oneStaffData ? oneStaffData : {});

  const BtnToggleViewEdit = () => {
    setToggleEdit(true)
    console.log("toggle")
  }


  const onChangeUpdatedData = (e) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value })
  }
  const submitUpdatedData = () => {

    callPatchStaffAPI();
  }
  const callPatchStaffAPI = async () => {
    const staffID = updatedData.staff_dbid;
    console.log(updatedData)
    try {
      const res = await PatchStaffAPI(staffID, updatedData)
      console.log(res.data)
      setToggleEdit(false);
      handlePatchDataOnList(staffID, updatedData);
    } catch (error) {
      console.log(error)
    }
  }

  // split date and time
  const splitDateTimeF = (dt) => {
    // if date is null we set old date so website does not crash
    let answer = "1900-01-01";
    if (dt !== null && dt.length > 9) {
      answer = dt.slice(0, 10);
    }else if(dt == "Nill" || dt =="nill"){
      answer = null;
    }else{
      answer = null;
    }
    return answer;
  }

  return (
    <Modal show={true} size="xl" >
      <Modal.Header >
        <Modal.Title>View & Edit Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Full Name"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="full_name"
                type="text"
                defaultValue={updatedData.full_name}
                required
                disabled={toggleEdit ? false : true}
                placeholder="name" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Designation"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="designation"
                type="text"
                defaultValue={updatedData.designation}
                required
                disabled={toggleEdit ? false : true}
                placeholder="designation" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="DOB"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="dob"
                type="date"
                defaultValue={splitDateTimeF(updatedData.dob)}
                required
                disabled={toggleEdit ? false : true}
                placeholder="dob" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="DOA"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="doa"
                type="date"
                defaultValue={splitDateTimeF(updatedData.doa)}
                required
                disabled={toggleEdit ? false : true}
                placeholder="doa" />
            </FloatingLabel>
          </Col>

        </Row>
        <Row>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="MOJ"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="moj"
                type="text"
                defaultValue={updatedData.moj}
                required
                disabled={toggleEdit ? false : true}
                placeholder="moj" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="DOP"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="dop"
                type="date"
                defaultValue={splitDateTimeF(updatedData.dop)}
                required
                disabled={toggleEdit ? false : true}
                placeholder="dop" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Qualification"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="qualification"
                type="text"
                defaultValue={updatedData.qualification}
                required
                disabled={toggleEdit ? false : true}
                placeholder="qualification" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Present Posting"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="present_posting"
                type="text"
                defaultValue={updatedData.present_posting}
                required
                disabled={toggleEdit ? false : true}
                placeholder="presentposting" />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Specialization"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="specialization"
                type="text"
                defaultValue={updatedData.specialization}
                required
                disabled={toggleEdit ? false : true}
                placeholder="specialization" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Award & Year"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="award_details"
                type="text"
                defaultValue={updatedData.award_details}
                required
                disabled={toggleEdit ? false : true}
                placeholder="award" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Hobby"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="hobby"
                type="text"
                defaultValue={updatedData.hobby}
                required
                disabled={toggleEdit ? false : true}
                placeholder="hobby" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Tr. req."
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="tr_req"
                type="text"
                defaultValue={updatedData.tr_req}
                required
                disabled={toggleEdit ? false : true}
                placeholder="tr.req" />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Mobile No."
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeUpdatedData}
                name="mobile_num"
                type="number"
                defaultValue={updatedData.mobile_num}
                required
                disabled={toggleEdit ? false : true}
                placeholder="mobile" />
            </FloatingLabel>
          </Col>


        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => { setToggleEdit(false); handleCloseEditModal() }}>
          Close
        </Button>
        {!toggleEdit &&
          <Button variant="primary" onClick={BtnToggleViewEdit}>
            Edit
          </Button>
        }
        {toggleEdit && <>
          <Button variant="primary" onClick={submitUpdatedData}>
            Update Info
          </Button>
        </>
        }


      </Modal.Footer>
    </Modal>
  )
}

export default UpdateStaff