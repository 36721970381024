import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { axiosInstance } from "../../../axios";
import Alert from "react-bootstrap/Alert";

const Support = () => {
  const [supportData, setSupportData] = useState({
    customer: "Matrix Tech Systems",
    via_customer_portal: "1",
    project: "PROJ-0002",
  });

  const [okMsg, setOkMsg] = useState(false)

  let navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.name === "image") {
      // console.log(e.target.files);
      setSupportData({ ...supportData, [e.target.name]: e.target.files[0] });
    } else {
      // console.log(e.target.value);
      setSupportData({ ...supportData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit support");
    sendPostRequest();
  };

  const sendPostRequest = () => {
    console.log(supportData);

    axiosInstance
      .post(`assets/crossdomain/create_issue/`, supportData)
      .then((res) => {
        let data = res.data.data;
        console.log(data);

        if (supportData.image && data.name) {
          let image_form_data = new FormData();
          console.log("Attaching image");
          image_form_data.append(
            "file",
            supportData.image,
            supportData.image.name
          );

          image_form_data.append("doctype", "Issue");
          image_form_data.append("docname", data.name);
          axiosInstance
            .post(`assets/crossdomain/attach_file/`, image_form_data)
            .then((res) => console.log(res.data))
            .catch((err) => console.log(err));
        }
        setOkMsg(true);
      })
      .catch((err) => console.log(err));
  };

  const handleRedirectToSupport = () => {
    setOkMsg(false)
    navigate('/support')
  }

  return (
    <>

      <div className="page-container">
       <Card>
          <Card.Header className="bg-light d-flex justify-content-between align-items-center">
            <h6>Support</h6>
          </Card.Header>
          {!okMsg && (<Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  name="subject"
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter Subject"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter Name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter Description"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEma">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  name="image"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card.Body>)}
        </Card>
        {okMsg && <OKMessage handleRedirectToSupport={handleRedirectToSupport} />}
      </div>
    </>
  );
};

const OKMessage = (props) => {
  const { handleRedirectToSupport } = props;

  return (
    <Alert className="text-center" variant="success">
      <Alert.Heading>Help Request has been sent</Alert.Heading>
      <p>Support team will resolve your request. </p>
      <Button
        variant="outline-warning"
        className="my-3"
        onClick={() => { handleRedirectToSupport(); }}
      >
        Add New Help Request
      </Button>
    </Alert>
  );
};


export default Support;
