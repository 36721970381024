import React from "react";
import { axiosInstance } from "../../../axios";
import { Button, Table, Form, Modal } from "react-bootstrap";

const DeleteModal = (props) => {
  const { closeDeleteModal, userId2, handleDeleteUser, userNameToDelete } =
    props;

  const deleteUser = async (userId2) => {
    try {
      const res = await axiosInstance.delete(
        `auth/user/?user_id=${userId2}`,
        {}
      );
      console.log(`user deleted with id ${userId2}`, res);
      handleDeleteUser(userId2);
      closeDeleteModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={true}
      onHide={closeDeleteModal}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Delete User ${userNameToDelete}`} </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
      <Modal.Footer>
        <Button variant="secondary" onClick={closeDeleteModal}>
          Close
        </Button>
        <Button variant="danger" onClick={() => deleteUser(userId2)}>
          Confirm Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
