import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Alert,
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axios";

import { URLs } from "../../routes";
import BgImage from "../../assets/img/illustrations/depot-1.png";
import Footer from "../../components/Footer";

const SignIn = (props) => {
  // console.log(props);
  let referrer = undefined;
  let navigate = useNavigate();
  const initialFormData = Object.freeze({
    user_name: "",
    password: "",
  });
  const [formData, updateFormData] = useState(initialFormData);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (referrer === "/logout") {
      //when sign in component is loaded from logout component
      setShowAlert(true);
      setAlertMsg("Logout Sucessfull!");
      setAlertVariant("success");
    } else if (
      referrer === "/" &&
      props.location.state.msg === "not-validated"
    ) {
      //when sign in component is loaded from home component
      setShowAlert(true);
      setAlertMsg("Your ID is not validated yet!");
      setAlertVariant("danger");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      // localStorage.removeItem("user_email");
      // localStorage.removeItem("group");
      // localStorage.removeItem("subscribed_products");
    } else {
      //no need to show any message
      setAlertMsg("");
      setAlertVariant("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    // console.log(e);
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // console.log(formData);
    axiosInstance
      .post(`auth/login/`, {
        user_name: formData.user_name,
        password: formData.password,
      })
      .then((res) => {
        // console.log(res.data);
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        localStorage.setItem("user_name", res.data.user_name);
        localStorage.setItem("user_email", res.data.email);
        localStorage.setItem("user_group", JSON.stringify(res.data.groups));
        localStorage.setItem("user_dept", JSON.stringify(res.data.departments));
        // if (res.data.manifest) {
        //   localStorage.setItem("manifest", JSON.stringify(res.data.manifest));
        // }
        // if (res.data.subscribed_products) {
        //   localStorage.setItem(
        //     "subscribed_products",
        //     JSON.stringify(res.data.subscribed_products)
        //   );
        // }
        setShowSpinner(false);
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + localStorage.getItem("access_token");
        // console.log(res);
        navigate(URLs.Modules.path);
        // console.log(res.data);
      })
      .catch(function (error) {
        setShowSpinner(false);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 401) {
            setShowAlert(true);
            setAlertMsg("No active account found with the given credentials");
            setAlertVariant("danger");
          } else {
          }
        }
      });
  };
  return (
    <main>
      <section className="d-flex align-items-center">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={URLs.IOH.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
              homepage
            </Card.Link>
          </p> */}
          <Row className="justify-content-center">
            <h3 className="text-center">
              Welcome to Depot Management Solution
            </h3>
          </Row>
          <Row className="justify-content-center">
            <h4 className="text-center text-gray">Central Railway, Pune</h4>
          </Row>
          <Row
            className="justify-content-center form-bg-image"
            // style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xl={6}
              className="d-flex align-items-center justify-content-center mt-2"
            >
              <Card.Img variant="top" src={BgImage} />
            </Col>
            <Col
              xs={12}
              xl={6}
              className="d-flex align-items-center justify-content-center  mt-2"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-2 w-100 fmxw-500">
                {showAlert && (
                  <Alert
                    variant={alertVariant}
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    {alertMsg}
                  </Alert>
                )}
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in </h3>
                </div>
                <Form className="mt-4 px-3 pb-3" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Username</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        // type="email"
                        name="user_name"
                        placeholder="type your username..."
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    {/* <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Remember me
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link
                        className="small text-end"
                        as={Link}
                        to={URLs.ForgotPassword.path}
                      >
                        Lost password?
                      </Card.Link>
                    </div> */}
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                </Form>
                {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={URLs.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}

                {showSpinner && (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="grow" variant="info" size="md" />
                    <Spinner animation="grow" variant="success" />
                    <Spinner animation="grow" variant="warning" />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <h4>Matrix Welcomes | Pune Division</h4>
          </Row>

          <Footer />
        </Container>
      </section>
    </main>
  );
};

export default SignIn;
