import React, { useEffect, useState } from "react";

import {
  InputGroup,
  Col,
  Button,
  Form,
  Row,
  Card,
  Spinner,
} from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../../../axios";
import { Typeahead } from "react-bootstrap-typeahead";
import AddCoach from "./AddCoach";
import CoachDetails from "./ViewandEditCoach";
import CoachTable from "./CoachTable";

const Coaches = () => {
  // const [coachList, setCoachList] = useState(null);
  const [coachID, setCoachID] = useState(null);
  const [coachData, updateCoachData] = useState(null);
  const [coachDataOnTable, setCoachDataOnTable] = useState(null);
  const [coachOptions, updateCoachOptions] = useState([]);

  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");

  const [showAddCoach, setShowAddCoach] = useState(false);

  useEffect(() => {
    getCoachList();
  }, []);

  const getCoachList = async () => {
    try {
      const res = await axiosInstance.get(`assets/coaches/`, {});
      console.log(res.data);
      updateCoachData(res.data);
      const tempCoachData = [];
      res.data.forEach((item) => {
        // tempCoachData.push(item.coach_id);

        if (item.coach_id && !tempCoachData.includes(item.coach_id)) {
          tempCoachData.push(item.coach_id);
        }
        if (item.coach_type && !tempCoachData.includes(item.coach_type)) {
          tempCoachData.push(item.coach_type);
        }
        if (item.ioh_status && !tempCoachData.includes(item.ioh_status)) {
          tempCoachData.push(item.ioh_status);
        }
        if (item.coach_status && !tempCoachData.includes(item.coach_status)) {
          tempCoachData.push(item.coach_status);
        }
        if (item.coach_depot && !tempCoachData.includes(item.coach_depot)) {
          tempCoachData.push(item.coach_depot);
        }
        if (item.location && !tempCoachData.includes(item.location)) {
          tempCoachData.push(item.location);
        }
        if (
          item.detachment_reason &&
          !tempCoachData.includes(item.detachment_reason)
        ) {
          tempCoachData.push(item.detachment_reason);
        }
      });
      updateCoachOptions(tempCoachData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    // console.log(e);
    setSelected();
    setTypeAheadValue(e[0]);
    setCoachID(null);
    setCoachDataOnTable(null);
  };

  const handleCoachNumberSubmit = (term) => {
    // if (filteredCoachData) {
    //   setCoachDataOnTable(filteredCoachData)
    // }

    if (term) {
      let filteredCoachData = coachData.filter(
        (item) => item.coach_id === term
      );
      if (filteredCoachData.length === 0) {
        filteredCoachData = coachData.filter(
          (item) => item.coach_status === term
        );
      }
      if (filteredCoachData.length === 0) {
        filteredCoachData = coachData.filter(
          (item) => item.coach_type === term
        );
      }
      if (filteredCoachData.length === 0) {
        filteredCoachData = coachData.filter(
          (item) => item.ioh_status === term
        );
      }
      if (filteredCoachData.length === 0) {
        filteredCoachData = coachData.filter(
          (item) => item.detachment_reason === term
        );
      }
      if (filteredCoachData.length === 0) {
        filteredCoachData = coachData.filter(
          (item) => item.coach_depot === term
        );
      }
      if (filteredCoachData.length === 0) {
        filteredCoachData = coachData.filter((item) => item.location === term);
      }
      setCoachID(null);
      setCoachDataOnTable(filteredCoachData);
    }
  };
  const handleFilterButtonSubmit = (e) => {
    e.preventDefault();
    // console.log(typeAheadValue);
    handleCoachNumberSubmit(typeAheadValue);
  };

  //Start- opening modal of add button

  const handleAddCoachShow = () => {
    setShowAddCoach(true);
  };
  const handleAddCoachClose = () => setShowAddCoach(false);
  //End- opening modal of add button
  const handleAddCoachSubmit = (submitted_coach_id) => {
    setCoachID(submitted_coach_id);
    setShowAddCoach(false);
    setCoachDataOnTable(null);
  };

  return (
    <>
      <div className="page-container">
        <Card>
          <Card.Header className="bg-light d-flex justify-content-between align-items-center">
            <h6>Coaches</h6>

            {coachData !== null && (
              <Button
                variant="outline-success"
                className="mx-2"
                onClick={handleAddCoachShow}
              >
                Add New Coach
              </Button>
            )}
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleFilterButtonSubmit}>
              <Row>
                <Col xs="8">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>

                    <Typeahead
                      id="basic-example"
                      onChange={handleChange}
                      options={coachOptions}
                      placeholder="Enter Coach Number..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCoachNumberSubmit(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Col>

                <Col xs="2">
                  <Button variant="outline-gray" className="" type="submit">
                    Lookup
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {coachData === null && (
          <Card.Body
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              style={{
                width: "100px",
                height: "100px",
              }}
              animation="border"
              role="status"
            ></Spinner>
          </Card.Body>
        )}
        {coachDataOnTable && <CoachTable coachData={coachDataOnTable} />}
        {coachID && <CoachDetails coachID={coachID} />}

        {showAddCoach === true && (
          <AddCoach
            handleAddCoachClose={handleAddCoachClose}
            handleAddCoachSubmit={handleAddCoachSubmit}
            coachData={coachData}
          />
        )}
      </div>
    </>
  );
};

export default Coaches;
