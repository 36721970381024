export function FormatDateTime(date) {
  let current_datetime = new Date(date);
  return (
    current_datetime.toDateString() +
    " " +
    current_datetime.toLocaleTimeString()
  );
}

export function FormatDate(date) {
  if (date) {
    let current_datetime = new Date(date);
    if (current_datetime == "Invalid Date") {
      return date;
    }
    return current_datetime.toDateString();
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function FormatSimpleDate(date) {
  if (date) {
    let current_datetime = new Date(date);
    if (current_datetime == "Invalid Date") {
      return date;
    }
    return [
      padTo2Digits(current_datetime.getDate()),
      padTo2Digits(current_datetime.getMonth() + 1),
      padTo2Digits(current_datetime.getFullYear()),
    ].join("/");
  }
}
export function FormatSimpleDate2(date) {
  if (date) {
    let current_datetime = new Date(date);
    if (current_datetime == "Invalid Date") {
      return date;
    }
    return [
      padTo2Digits(current_datetime.getFullYear()),
      padTo2Digits(current_datetime.getMonth() + 1),
      padTo2Digits(current_datetime.getDate()),
    ].join("-");
  }
}
export function FormatStringDate(date) {
  // for string format "yyyy-mm-dd"
  try {
    let returnDate = new Date(date);
    const offset = returnDate.getTimezoneOffset();
    returnDate = new Date(returnDate.getTime() - offset * 60 * 1000);
    returnDate = returnDate.toISOString().split("T")[0];
    return returnDate;
  } catch {
    return null;
  }
}

export function FormatSimpleMonthDate(date) {
  if (date) {
    let r_datetime = new Date(date);
    if (r_datetime == "Invalid Date") {
      return date;
    }
    return [
      padTo2Digits(r_datetime.getMonth() + 1),
      padTo2Digits(r_datetime.getFullYear()),
    ].join("/");
  }
}
