import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../axios";
// import { StationList } from "../trc_manifest";

const AddTrain = (props) => {
  const { showAddTrain, handleAddTrainClose, handleAddTrain } = props;
  const StationList = JSON.parse(localStorage.getItem("StationList"));
  const firstStationCode = Object.keys(StationList)[0];
  const initialData = {
    // train_number: "",
    alias: { down_no: "", train_name: "" },
    starting_station_id: StationList[firstStationCode],
    destination_station_id: StationList[firstStationCode],
    depot_id: StationList[firstStationCode],
  };
  const [newTrainData, setNewTrainData] = useState(initialData);

  const onValueChange = (e) => {
    if (e.target.name === "train_name" || e.target.name === "down_no") {
      let tempAliasData = newTrainData.alias;
      tempAliasData[e.target.name] = e.target.value;
      setNewTrainData({ ...newTrainData, ["alias"]: tempAliasData });
    } else {
      setNewTrainData({
        ...newTrainData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(addTrainData);
  };

  const submitNewTrain = (e) => {
    e.preventDefault();
    console.log(newTrainData);
    if (newTrainData) {
      axiosInstance
        .post(`assets/trains/`, newTrainData)
        .then((res) => {
          const data = res.data;
          console.log(data);
          handleAddTrain(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal
        show={showAddTrain}
        onHide={handleAddTrainClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
      >
        <Form onSubmit={submitNewTrain}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Train</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Train No.</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Train No."
                  onChange={onValueChange}
                  name="train_number"
                  required
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Down No.</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Down No"
                  onChange={onValueChange}
                  required
                  name="down_no"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Train Name</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="train_name"
                  type="text"
                  required
                  placeholder="Train Name"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Starting Station</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="starting_station_id"
                  required
                >
                  {Object.keys(StationList).map((station, index) => {
                    return (
                      <option
                        value={StationList[station]}
                        key={`train_type_${index}`}
                      >
                        {station}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>

            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Destination Station</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="destination_station_id"
                  required
                >
                  {Object.keys(StationList).map((station, index) => {
                    return (
                      <option
                        value={StationList[station]}
                        key={`train_type_${index}`}
                      >
                        {station}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Depot</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select onChange={onValueChange} name="depot_id" required>
                  {Object.keys(StationList).map((station, index) => {
                    return (
                      <option
                        value={StationList[station]}
                        key={`train_depot_${index}`}
                      >
                        {station}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Running hours (1 way trip)</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="running_hrs"
                  type="number"
                  placeholder="Running hours"
                  max="999.99"
                  min="0"
                  step="0.01"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>No. of Monthly trips</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  onChange={onValueChange}
                  name="monthly_trips"
                  type="number"
                  placeholder="Monthly trips"
                />
              </Col>
            </Row>
            {/* <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Train type</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="train_type"
                  required
                >
                  {["","ICF", "LHB"].map((typ, index) => {
                    return (
                      <option value={typ} key={`${typ}_${index}`}>
                        {typ}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Up / Down</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="way_up_down"
                  required
                >
                  {["","UP", "DOWN"].map((way, index) => {
                    return (
                      <option value={way} key={`${way}_${index}`}>
                        {way}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>
                  Opposite start day - Days After Departure from pune
                </Form.Label>
              </Col>
              <Col sm="9">
                <Form.Select
                  onChange={onValueChange}
                  name="opposite_start_day_days_after_departure_from_pune"
                  required
                >
                  {["",0, 1, 2, 3, 4, 5].map((day, index) => {
                    return (
                      <option value={day} key={`${day}_${index}`}>
                        {day}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="my-3">
              <Col sm="3" className="my-2">
                <Form.Label>Opposite station departure time</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control
                  placeholder="Opposite station departure time"
                  name="opposite_station_departure_time"
                  onChange={onValueChange}
                  type="time"
                  required
                />
              </Col>
            </Row> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddTrain;
