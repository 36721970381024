export const checklistDataL1 = [
  {
    asset_dbid: 1,
    asset_name: "Entrance Hand Rail",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },

  {
    asset_dbid: 2,
    asset_name: "Coach Body",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 3,
    asset_name: "Main Entrance Door",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 4,
    asset_name: "Passenger Info",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 5,
    asset_name: "Foot Step",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 6,
    asset_name: "Window Glass Rubber Profile",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 7,
    asset_name: "Berth",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 8,
    asset_name: "Window",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 9,
    asset_name: "Coach Body",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 10,
    asset_name: "Passenger Ammenities",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 11,
    asset_name: "Door",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 12,
    asset_name: "Window",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 13,
    asset_name: "Passenger Ammenities",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 14,
    asset_name: "Wash Basin",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 15,
    asset_name: "Wall",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 16,
    asset_name: "Pipeline",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 17,
    asset_name: "Wash Basin",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 18,
    asset_name: "Single Information Poster",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 19,
    asset_name: "Rolling Shutter",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 20,
    asset_name: "Sliding Door",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 21,
    asset_name: "Hammer Box Cover",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 22,
    asset_name: "Hammer",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 23,
    asset_name: "PVC Flooring",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 24,
    asset_name: "Aluminium Chequered Sheets",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 25,
    asset_name: "LP Sheets",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 26,
    asset_name: "Saloon Door",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 27,
    asset_name: "Buffer /CBC Height",
    responseType: "data_with_integrated_fields",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      Before: {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
      },
      After: {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
      },
    },
  },
  {
    asset_dbid: 28,
    asset_name: "Buffer Length / Stroke",
    responseType: "data_with_integrated_fields",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      Before: {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
      },
      After: {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
      },
    },
  },
  {
    asset_dbid: 56,
    asset_name: "A' Clearance",
    responseType: "data_with_integrated_fields",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      Before: {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
        5: { type: "string" },
        6: { type: "string" },
        7: { type: "string" },
        8: { type: "string" },
      },
      After: {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
        5: { type: "string" },
        6: { type: "string" },
        7: { type: "string" },
        8: { type: "string" },
      },
    },
  },
  {
    asset_dbid: 57,
    asset_name: "B' Clearance",
    responseType: "data_with_integrated_fields",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      Before: {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
      },
      After: {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
      },
    },
  },
  {
    asset_dbid: 29,
    asset_name: "Wearing Piece & Plate",
    responseType: "data_with_integrated_fields",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "Wearing Piece": {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
      },
      "Wearing Plate": {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
      },
    },
  },
  {
    asset_dbid: 30,
    asset_name: "Wheels Dia & Gauge",
    responseType: "data_with_integrated_fields",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "Wheels Dia": {
        1: { type: "string" },
        2: { type: "string" },
        3: { type: "string" },
        4: { type: "string" },
        5: { type: "string" },
        6: { type: "string" },
        7: { type: "string" },
        8: { type: "string" },
      },
      "Wheels Gauge": {
        "1/2": { type: "string" },
        "3/4": { type: "string" },
        "5/6": { type: "string" },
        "7/8": { type: "string" },
      },
    },
  },
  {
    asset_dbid: 31,
    asset_name: "Old Trolley Particulars",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "KOP End": {
        "Trolley No.": { type: "string" },
        "Shop Name": { type: "string" },
        "Shop Date": { type: "month" },
      },
      "PA End": {
        "Trolley No.": { type: "string" },
        "Shop Name": { type: "string" },
        "Shop Date": { type: "month" },
      },
    },
  },
  {
    asset_dbid: 32,
    asset_name: "New Trolley Particulars",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "KOP End": {
        "Trolley No.": { type: "string" },
        "Shop Name": { type: "string" },
        "Shop Date": { type: "date" },
      },
      "PA End": {
        "Trolley No.": { type: "string" },
        "Shop Name": { type: "string" },
        "Shop Date": { type: "date" },
      },
    },
  },
  {
    asset_dbid: 33,
    asset_name: "BSS Hanger Block",
    responseType: "switch_then_textbox",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 34,
    asset_name: "BSS Hanger Pin",
    responseType: "switch_then_textbox",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 35,
    asset_name: "BSS Hanger",
    responseType: "switch_then_textbox",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 36,
    asset_name: "A/box Dashpot Spr.",
    responseType: "switch_then_textbox",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 37,
    asset_name: "Dashpot Spring Colour Code",
    responseType: "double_radio_response",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "KOP End Trolley": "Yellow",
      "PA End Trolley": "Yellow",
    },
    transition_key: null,
    form_fields: {
      "KOP End Trolley": ["Yellow", "Blue", "Green"],
      "PA End Trolley": ["Yellow", "Blue", "Green"],
    },
  },
  {
    asset_dbid: 38,
    asset_name: "Dashpot Oil",
    responseType: "text_field",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "Oil",
    hint: "(0.0 - 1.6) Ltr.",
  },
  {
    asset_dbid: 39,
    asset_name: "Side Bearer Oil",
    responseType: "text_field",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "Oil",
    hint: "(0.0 - 2.0) Ltr.",
  },
  {
    asset_dbid: 40,
    asset_name: "Bolster Spring",
    responseType: "switch_then_textbox",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 41,
    asset_name: "Bolster Spring Colour Code",
    responseType: "double_radio_response",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "KOP End Trolley": "Yellow",
      "PA End Trolley": "Yellow",
    },
    transition_key: null,
    form_fields: {
      "KOP End Trolley": ["Yellow", "Blue", "Green"],
      "PA End Trolley": ["Yellow", "Blue", "Green"],
    },
  },
  {
    asset_dbid: 42,
    asset_name: "Anchor Link",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "Ordinary",
    default_readings: null,
    transition_key: null,
    form_fields: {
      type: ["Ordinary", "Modified"],
      response: ["OK", "NOT OK"],
    },
    checkbox_options: [
      "Locking Plate",
      "Stud/Bolt",
      "Silent Block",
      "Physical Damage",
      "Other",
    ],
  },
  {
    asset_dbid: 43,
    asset_name: "Eq. Stay Rod",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "Ordinary",
    default_readings: null,
    transition_key: null,
    form_fields: {
      type: ["Ordinary", "Modified"],
      response: ["OK", "NOT OK"],
    },
    checkbox_options: [
      "Long Pin",
      "Long Pin Bush",
      "Short Pin",
      "Short Pin Bush",
      "Physical Damage",
      "Stay Rod Safety Bracket",
      "Other",
    ],
  },
  {
    asset_dbid: 44,
    asset_name: "Condition Of Z Lever",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "Ordinary",
    default_readings: null,
    transition_key: null,
    form_fields: {
      // type: ["Ordinary", "Modified"],
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Pin Worn Out", "Ovality Of Holes", "Bush", "Other"],
  },
  {
    asset_dbid: 45,
    asset_name: "Condition Of Floating Lever",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "Ordinary",
    default_readings: null,
    transition_key: null,
    form_fields: {
      // type: ["Ordinary", "Modified"],
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Pin Worn Out", "Ovality Of Holes", "Bush", "Other"],
  },
  {
    asset_dbid: 46,
    asset_name: "Center Pivot",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "Ordinary",
    default_readings: null,
    transition_key: null,
    form_fields: {
      // type: ["Ordinary", "Modified"],
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Pin Worn Out", "Ovality Of Holes", "Bush", "Other"],
  },
  {
    asset_dbid: 47,
    asset_name: "Shock Absorber",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "Ordinary",
    default_readings: null,
    transition_key: null,
    form_fields: {
      type: ["Ordinary", "Modified"],
      response: ["OK", "NOT OK"],
    },
    checkbox_options: [
      "Leakage",
      "Flange Broken",
      "Missing Nut-Bolts",
      "Other",
    ],
  },
  {
    asset_dbid: 48,
    asset_name: "Axle Box Crown Bolt",
    responseType: "switch",
    options: ["AVAILABLE", "NOT AVAILABLE"],
    default_response: "Available",
    default_type: "",
    default_readings: null,
    transition_key: null,
  },
  {
    asset_dbid: 49,
    asset_name: "Wheel Stamping Particulars",
    responseType: "switch_with_textbox",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "A/Box No. - (5 Numbers - 4 Char. - MMYY)",
    checkbox_options: [
      "Shelled Tread",
      "Sharp Flange",
      "Thin Flange",
      "Grease Oozing",
      "Deep Flange",
      "Flat Tyre",
      "Hollow Tyre",
      "Spread Rim",
      "Axle Box Damage",
      "Root Radius",
      "Other",
    ],
  },
  {
    asset_dbid: 50,
    asset_name: "Primary Suspension",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  // {
  //   asset_dbid: 51,
  //   asset_name: "Draw Gear & Screw Coupling",
  //   responseType: "double_dropdown_response",
  //   options: [],
  //   default_response: "OK",
  //   default_type: "",
  //   default_readings: null,
  //   transition_key: null,
  //   form_fields: {
  //     response: ["OK", "NOT OK"],
  //   },
  //   checkbox_options: [
  //     "VISUAL EXAMINATION CHECK FOR CRACK or BROKEN DRAW BAR HOOK, DRAW BAR, DRAFT KEY or ANY OTHER PART OF ITS ASSEMBLY WITH SCREW COUPLING",
  //     "PROJECTION OF DRAW BAR HOOK (NOT MORE THAN 32mm",
  //     "CHECK FOR WORN OUT/ DAMAGE/ PREISHED RUBBER PAD & REPLACE IF NECESSARY",
  //     "PROPER BENDING OF DRAFT COTTER",
  //     "CHECK FOR FITTING OF DRAW BAR NUT & COTTER",
  //     "CHECK FOR DRAW BAR WEARING PLATE WITH LUG BROKEN/DEFICIENT",
  //     "VISUAL EXAMINATION FOR SCREW COUPLING FOR WEAR",
  //     "LUBRICATE SCREW COUPLING",
  //   ],
  // },
  {
    asset_dbid: 51,
    asset_name: "Draw Gear & Screw Coupling",
    responseType: "singledropdown",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 52,
    asset_name: "Brake Pipe Leakage Rate",
    responseType: "text_field",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "",
    hint: "0.2 Kg/cm2 /Min (Max)",
  },
  {
    asset_dbid: 53,
    asset_name: "Feed Pipe Leakage Rate",
    responseType: "text_field",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "",
    hint: "0.2 Kg/cm2 /Min (Max)",
  },
  {
    asset_dbid: 54,
    asset_name: "Brake Cylinder Filling time",
    responseType: "text_field",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "",
    hint: "3 to 5 Seconds",
  },
  {
    asset_dbid: 55,
    asset_name: "Max BC Pressure",
    responseType: "text_field",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "",
    hint: "3.8 +/- 0.1 Kg/cm2",
  },

  {
    asset_dbid: 58,
    asset_name: "Brake Cylinder release time",
    responseType: "text_field",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "",
    hint: "15 to 20 Seconds (for Conventional) Less than 20 Sec (For Bogie Mounted)",
  },
  {
    asset_dbid: 59,
    asset_name: "Sensitively Test Brake application",
    responseType: "text_field",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    extra_label: "",
    hint: "Break Should Apply",
  },
  {
    asset_dbid: 60,
    asset_name: "Insensitively Test Brake application",
    responseType: "text_field",
    options: [],
    default_response: "",
    transition_key: null,
    extra_label: "",
    hint: "Break Should Not Apply",
  },
  {
    asset_dbid: 63,
    asset_name: "End Panel Door",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 64,
    asset_name: "End Fall Plate",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 65,
    asset_name: "Flooring",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "Wooden",
    default_readings: null,
    transition_key: null,
    form_fields: {
      type: ["Wooden", "Aluminium Chequered Sheets"],
      response: ["OK", "Damaged", "Missing"],
    },
    checkbox_options: [],
  },
  {
    asset_dbid: 78,
    asset_name: "Break Van Equipment",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 79,
    asset_name: "Dog Box",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 80,
    asset_name: "Guard Seat",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 87,
    asset_name: "Luggage Rake",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 102,
    asset_name: "Wheel Details",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "Wheel Diameter": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "KOP END 3": { type: "string" },
        "KOP END 4": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
        "PA END 3": { type: "string" },
        "PA END 4": { type: "string" },
      },
      "Wheel Barel No": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
      },
    },
  },
  {
    asset_dbid: 103,
    asset_name: "Clearance Between Rail Level with respect to Bogie",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "Clearance Between Break Disk Bottom to Rail Level": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
      },
      "Secondary Suspension": {
        "Coil Spring Height 1": { type: "string" },
        "Coil Spring Height 2": { type: "string" },
        "Coil Spring Height 3": { type: "string" },
        "Coil Spring Height 4": { type: "string" },
        "Air Spring Height 1": { type: "string" },
        "Air Spring Height 2": { type: "string" },
        "Air Spring Height 3": { type: "string" },
        "Air Spring Height 4": { type: "string" },
      },
      "Height of Bogie from Top from Rail Level": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "KOP END 3": { type: "string" },
        "KOP END 4": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
        "PA END 3": { type: "string" },
        "PA END 4": { type: "string" },
      },
      // "Height of Bogie Bolster Base Plate": {
      //   "KOP END 1": { type: "string" },
      //   "KOP END 2": { type: "string" },
      //   "PA END 1": { type: "string" },
      //   "PA END 2": { type: "string" },
      // },
      "Clearance Between Secondary Vertical Bump Stop Gap in case of coil spring":
        {
          "KOP END 1": { type: "string" },
          "KOP END 2": { type: "string" },
          "PA END 1": { type: "string" },
          "PA END 2": { type: "string" },
        },
    },
  },
  {
    asset_dbid: 106,
    asset_name: "Axle Box to Bogie Frame",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "Primary Spring Height": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "KOP END 3": { type: "string" },
        "KOP END 4": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
        "PA END 3": { type: "string" },
        "PA END 4": { type: "string" },
      },
    },
  },
  {
    asset_dbid: 109,
    asset_name: "Clearance Between Rail Level wrt Bogie",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "Clearance Between Safety Pin Top": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "KOP END 3": { type: "string" },
        "KOP END 4": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
        "PA END 3": { type: "string" },
        "PA END 4": { type: "string" },
      },
      "Clearance Between Lug of Control Arm Bottom": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "KOP END 3": { type: "string" },
        "KOP END 4": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
        "PA END 3": { type: "string" },
        "PA END 4": { type: "string" },
      },
      "Clearance Between Lateral Bump Stop and Traction Centre": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
      },
      "Clearance Between Longitudinal Bump Stop and Traction Centre": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
      },
      "Clearance Between Primary Vertical Bump Stop Gap": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
      },
    },
  },
  {
    asset_dbid: 110,
    asset_name: "Heights",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "Max No of Shims at Vertical Bump Stop": {
        "KOP END 1 : Metal Shims": { type: "string" },
        "KOP END 2 : Metal Shims": { type: "string" },
        "KOP END 1 : Synthetic": { type: "string" },
        "KOP END 2 : Synthetic": { type: "string" },
        "KOP END 1 : Shims": { type: "string" },
        "KOP END 2 : Shims": { type: "string" },
        "PA END 1 : Metal Shims": { type: "string" },
        "PA END 2 : Metal Shims": { type: "string" },
        "PA END 1 : Synthetic": { type: "string" },
        "PA END 2 : Synthetic": { type: "string" },
        "PA END 1 : Shims": { type: "string" },
        "PA END 2 : Shims": { type: "string" },
      },
      "CBC Height": {
        "KOP END 1": { type: "string" },
        "PA END 1": { type: "string" },
      },
      "Max No of Shims at Body Bogie Connection": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
      },
      "Rail Level to the Control arm Bracket (Vary Upon wheel Dia)": {
        "KOP END 1": { type: "string" },
        "KOP END 2": { type: "string" },
        "KOP END 3": { type: "string" },
        "KOP END 4": { type: "string" },
        "PA END 1": { type: "string" },
        "PA END 2": { type: "string" },
        "PA END 3": { type: "string" },
        "PA END 4": { type: "string" },
      },
      // "Height from Top Plate of Side Frame to upper face of spring Seat": {
      //   "KOP END 1": { type: "string" },
      //   "KOP END 2": { type: "string" },
      //   "PA END 1": { type: "string" },
      //   "PA END 2": { type: "string" },
      // },
    },
  },
  {
    asset_dbid: 111,
    asset_name: "Safety",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Earthing Lead Status": {
        "KOP END 1": "Provided",
        "KOP END 2": "Provided",
        "PA END 1": "Provided",
        "PA END 2": "Provided",
      },
      "Security Rope": {
        "KOP END 1": "New",
        "KOP END 2": "New",
        "PA END 1": "New",
        "PA END 2": "New",
      },
    },
    transition_key: null,
    form_fields: {
      "Earthing Lead Status": {
        "KOP END 1": { type: "select", options: ["Provided", "Not Provided"] },
        "KOP END 2": { type: "select", options: ["Provided", "Not Provided"] },
        "PA END 1": { type: "select", options: ["Provided", "Not Provided"] },
        "PA END 2": { type: "select", options: ["Provided", "Not Provided"] },
      },
      "Security Rope": {
        "KOP END 1": { type: "select", options: ["New", "Serviceable"] },
        "KOP END 2": { type: "select", options: ["New", "Serviceable"] },
        "PA END 1": { type: "select", options: ["New", "Serviceable"] },
        "PA END 2": { type: "select", options: ["New", "Serviceable"] },
      },
    },
  },
  {
    asset_dbid: 140,
    asset_name: "Cash Table",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 139,
    asset_name: "Cupboard",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 135,
    asset_name: "Cylinder Securing Chain",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 122,
    asset_name: "Deep Frezzer",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 117,
    asset_name: "Dosa Tava",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 115,
    asset_name: "Double Burner",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 123,
    asset_name: "Fire Extinguisher",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 119,
    asset_name: "Fire Supperssion System",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 134,
    asset_name: "Gas Pipe and Regulator",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 127,
    asset_name: "Ice Box",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 120,
    asset_name: "Managers Counter",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 142,
    asset_name: "Mirror",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 130,
    asset_name: "Rack",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 121,
    asset_name: "Refrigerator",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 116,
    asset_name: "Single Burner",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 128,
    asset_name: "Sliding Shutter",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 112,
    asset_name: "Washing Area",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 220,
    asset_name: "Chlorinator Unit",
    responseType: "singledropdown",
    options: ["OK", "Damaged", "Missing", "Tilt", "Lug Loose", "Cock Missing"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 215,
    asset_name: "Mounting Arrangements of Bio Tank",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 218,
    asset_name: "Rubber Connector",
    responseType: "singledropdown",
    options: [
      "OK",
      "Damaged",
      "Missing",
      "Torn",
      "Clip Loose",
      "Clip Missing",
      "Shifting",
    ],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 219,
    asset_name: "Rubber Packing",
    responseType: "singledropdown",
    options: ["OK", "Missing", "Torn"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 217,
    asset_name: "S Trap Mounting Bracket and Clamp",
    responseType: "singledropdown",
    options: ["OK", "Loose", "Shifted"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 216,
    asset_name: "Visual Inspection of Tank",
    responseType: "singledropdown",
    options: ["OK", "Dent", "Leakage"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 221,
    asset_name: "Oven",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 222,
    asset_name: "Electric Coils",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 61,
    asset_name: "Cabin Berth",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 62,
    asset_name: "Coupe Berth",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 225,
    asset_name: "Metal Cupboard",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 224,
    asset_name: "Wooden Cupboard",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 226,
    asset_name: "Air Brake System",
    responseType: "data_with_integrated_fields_col_12",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      Escorts: {
        value: { type: "string" },
      },
      Foively: {
        value: { type: "string" },
      },
      Knorr: {
        value: { type: "string" },
      },
    },
  },
  {
    asset_dbid: 227,
    asset_name: "DV Particulars",
    responseType: "text_field",
    options: [],
    default_response: "",
    transition_key: null,
    extra_label: "",
    hint: "",
  },
  {
    asset_dbid: 228,
    asset_name: "Brake Cylinder Pressure",
    responseType: "text_field",
    options: [],
    default_response: "",
    transition_key: null,
    extra_label: "",
    hint: "Unit- Kg/cm^2",
  },

  {
    asset_dbid: 229,
    asset_name: "Condition of Flexible Pipe and Nylon Washers",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 230,
    asset_name: "WSP Status (Fault Code)",
    responseType: "text_field",
    options: [],
    default_response: "",
    transition_key: null,
    extra_label: "",
    hint: "",
  },

  {
    asset_dbid: 231,
    asset_name: "Condition of Brake Caliper Assembly",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  {
    asset_dbid: 232,
    asset_name: "WSP Modification Done or Not",
    responseType: "singledropdown",
    options: ["Done", "Not Done"],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: "",
  },
  // {
  //   asset_dbid: 233,
  //   asset_name: "Condition of Damper",
  //   responseType: "data_with_integrated_fields_col_12",
  //   options: [],
  //   default_response: "",
  //   default_type: "",
  //   default_readings: null,
  //   transition_key: null,
  //   form_fields: {
  //     Primary: {
  //       "KOP END 1": { type: "string" },
  //       "KOP END 2": { type: "string" },
  //       "KOP END 3": { type: "string" },
  //       "KOP END 4": { type: "string" },
  //       "PA END 1": { type: "string" },
  //       "PA END 2": { type: "string" },
  //       "PA END 3": { type: "string" },
  //       "PA END 4": { type: "string" },
  //     },
  //     Secondary: {
  //       "KOP END 1": { type: "string" },
  //       "KOP END 2": { type: "string" },
  //       "KOP END 3": { type: "string" },
  //       "KOP END 4": { type: "string" },
  //       "PA END 1": { type: "string" },
  //       "PA END 2": { type: "string" },
  //       "PA END 3": { type: "string" },
  //       "PA END 4": { type: "string" },
  //     },
  //   },
  // },
  {
    asset_dbid: 234,
    asset_name: "Reservoir Charging",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Charging Time of AR (0-4.8kg/cm^2)": "",
      "Charging Time of CR (6 liter) (0-4.8kg/cm^2)": "",
      "BP Pressure": "",
      "CR Pressure": "",
      "FP Pressure": "",
    },
    transition_key: null,
    form_fields: {
      "Charging Time of AR (0-4.8kg/cm^2)": {
        type: "string",
        hint: "175 +- 30sec",
      },
      "Charging Time of CR (6 liter) (0-4.8kg/cm^2)": {
        type: "string",
        hint: "165 +- 20sec",
      },
      "BP Pressure": {
        type: "string",
        hint: "5.0 +- 0.1 kg/cm^2 ",
      },
      "CR Pressure": {
        type: "string",
        hint: "5.0 +- 0.1 kg/cm^2 ",
      },
      "FP Pressure": {
        type: "string",
        hint: "5.0 +- 0.1 kg/cm^2 ",
      },
    },
  },
  {
    asset_dbid: 235,
    asset_name: "Sealing Test",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "BP (Less than 0.1 kg/cm^2 in 5 minutes)": "",
      "FP (Less than 0.1 kg/cm^2 in 5 minutes)": "",
    },
    transition_key: null,
    form_fields: {
      "BP (Less than 0.1 kg/cm^2 in 5 minutes)": {
        type: "string",
        hint: "BP < 0.2 kg/cm^2/minute ",
      },
      "FP (Less than 0.1 kg/cm^2 in 5 minutes)": {
        type: "string",
        hint: "FP < 0.2 kg/cm^2/minute ",
      },
    },
  },
  {
    asset_dbid: 236,
    asset_name: "Full Brake Application",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Reduce BP from 5.0 to 3.4 kg/cm^2": "",
      "Brake Accelerator should not respond": "Responded",
      "Max BC Pressure": "",
      "Leakage BC Pressure within 5 minutes": "",
      "All Brake Cylinder are Applied": "Applied",
      "Both Side Brake Indicators should show Red": "OK",
    },
    transition_key: null,
    form_fields: {
      "Reduce BP from 5.0 to 3.4 kg/cm^2": {
        type: "string",
        hint: "3-5 sec",
      },
      "Brake Accelerator should not respond": {
        type: "dropdown",
        options: ["Responded", "Not Responded"],
        defaultValue: "Responded",
      },
      "Max BC Pressure": {
        type: "string",
        hint: "3.0 +- 0.1 kg/cm^2",
      },
      "Leakage BC Pressure within 5 minutes": {
        type: "string",
        hint: "<0.1 kg/cm^2",
      },
      "All Brake Cylinder are Applied": {
        type: "dropdown",
        options: ["Applied", "Not Applied"],
        defaultValue: "Applied",
      },
      "Both Side Brake Indicators should show Red": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 237,
    asset_name: "Release Full Brake Application",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Charge BP (upto 5.0 kg/cm^2)": "",
      "All Brakes are Released": "Released",
      "Both Side Brake Indicators should show Green": "OK",
    },
    transition_key: null,
    form_fields: {
      "Charge BP (upto 5.0 kg/cm^2)": {
        type: "string",
        hint: "5.0 +- 0.1 kg/cm^2",
      },
      "All Brakes are Released": {
        type: "dropdown",
        options: ["Released", "Not Released"],
        defaultValue: "Released",
      },
      "Both Side Brake Indicators should show Green": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 238,
    asset_name: "Over Charge Protection",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Check the overcharging CR, it should not be over charged more than 0.1 kg/cm^2 in 10 sec":
        "",
    },
    transition_key: null,
    form_fields: {
      "Check the overcharging CR, it should not be over charged more than 0.1 kg/cm^2 in 10 sec":
        {
          type: "string",
          hint: "Less than 0.1 kg/cm^2 in 10 sec",
        },
    },
  },
  {
    asset_dbid: 239,
    asset_name: "Emergency Application",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Reduce BP to 0 kg/cm^2": "",
      "Brake Accelerator should respond": "Responded",
      "Charging Time of Brake Cylinder (0 to 3.6 kg/cm^2)": "",
      "Max Brake Cylinder Pressure": "",
      "All Brake Cylinders Applied": "Applied",
      "Both Side Brake Indicators should show Red": "OK",
    },
    transition_key: null,
    form_fields: {
      "Reduce BP to 0 kg/cm^2": {
        type: "string",
        hint: "0 kg/cm^2 ",
      },
      "Brake Accelerator should respond": {
        type: "dropdown",
        options: ["Responded", "Not Responded"],
        defaultValue: "Responded",
      },
      "Charging Time of Brake Cylinder (0 to 3.6 kg/cm^2)": {
        type: "string",
        hint: "3 - 5 sec",
      },
      "Max Brake Cylinder Pressure": {
        type: "string",
        hint: "3 +- 0.1 kg/cm^2",
      },
      "All Brake Cylinders Applied": {
        type: "dropdown",
        options: ["Applied", "Not Applied"],
        defaultValue: "Applied",
      },
      "Both Side Brake Indicators should show Red": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 240,
    asset_name: "Release Emergency Brake Application",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "BC release time (Max to 0.4 kg/cm^2)": "",
      "All Brake Cylinder Released": "Released",
      "Both Side Brake Indicators should show Green": "OK",
    },
    transition_key: null,
    form_fields: {
      "BC release time (Max to 0.4 kg/cm^2)": {
        type: "string",
        hint: "0 kg/cm^2 ",
      },
      "All Brake Cylinder Released": {
        type: "dropdown",
        options: ["Released", "Not Released"],
        defaultValue: "Released",
      },
      "Both Side Brake Indicators should show Green": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 241,
    asset_name: "Graduated Brake Application and Release",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: { "Minimum 7 steps": "" },
    transition_key: null,
    form_fields: {
      "Minimum 7 steps": {
        type: "string",
        hint: "Brake should apply and release corresponding to decrese and increase of BP Pressure ",
      },
    },
  },
  {
    asset_dbid: 242,
    asset_name: "Test for Pressure Switch for anti skid device",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Charged the FP/BP * pressure": "OK",
      "Anti-Skid device get Power Supply at 1.8 +- 0.2 kg/cm^2": "OK",
      "Anti-Skid device get Power Supply at 1.8 +- 0.2 kg/cm^2": "OK",
    },
    transition_key: null,
    form_fields: {
      "Charged the FP/BP * pressure": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "Anti-Skid device get Power Supply at 1.8 +- 0.2 kg/cm^2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "Anti-Skid device get Power Supply at 1.8 +- 0.2 kg/cm^2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 243,
    asset_name: "Isolation Test",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Close the isolation cocks of bogie 1 & 2": "",
      "Reduce BP pressure to full brake application (brake should not apply)":
        "Applied",
      "Both side Brake indicators shows green, Open both isolating cock (brake should apply corresponding to opening of isolation cock for bogie)":
        "OK",
      "Both side Brake indicators shows red": "OK",
      "Again close isolation cock of bogie 1 & 2 one by one": "Released",
      "Both side Brake indicators of bogie 1 & 2 shows green one by one": "OK",
    },
    transition_key: null,
    form_fields: {
      "Close the isolation cocks of bogie 1 & 2": {
        type: "string",
        hint: "",
      },
      "Reduce BP pressure to full brake application (brake should not apply)": {
        type: "dropdown",
        options: ["Applied", "Not Applied"],
        defaultValue: "Applied",
      },
      "Both side Brake indicators shows green, Open both isolating cock (brake should apply corresponding to opening of isolation cock for bogie)":
        {
          type: "dropdown",
          options: ["OK", "NOT OK"],
          defaultValue: "OK",
        },
      "Both side Brake indicators shows red": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "Again close isolation cock of bogie 1 & 2 one by one": {
        type: "dropdown",
        options: ["Released", "Not Released"],
        defaultValue: "Released",
      },
      "Both side Brake indicators of bogie 1 & 2 shows green one by one": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 244,
    asset_name: "Sensitivity Test",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Reduce BP pressure at the rate of 0.6 kg/cm^2 in 6 second (Brake should be applied within 6 sec) ":
        "Passed",
    },
    transition_key: null,
    form_fields: {
      "Reduce BP pressure at the rate of 0.6 kg/cm^2 in 6 second (Brake should be applied within 6 sec) ":
        {
          type: "dropdown",
          options: ["Passed", "Failed"],
          defaultValue: "Passed",
        },
    },
  },
  {
    asset_dbid: 245,
    asset_name: "Insensitivity Test",
    responseType: "multiple_level_form",
    options: [],
    default_response: {
      "Exhaust BP pressure at the rate of 0.3 kg/cm^2 per minute (Brake should not applied) ":
        "Passed",
    },
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      "Exhaust BP pressure at the rate of 0.3 kg/cm^2 per minute (Brake should not applied) ":
        {
          type: "dropdown",
          options: ["Passed", "Failed"],
          defaultValue: "Passed",
        },
    },
  },
  {
    asset_dbid: 246,
    asset_name: "Passenger Emergency Pull Box Testing",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Pull the Emergency pull box handle and check (BP pressure should remain 2.0 +- 0.2 kg/cm^2) ":
        "Done",
      "Brake Accelarator does respond (Should respond) ": "Responded",
      "BP pressure exhaust from emergency brake valve (Should Yes) ": "Yes",
      "Indicator lamp on outside coach glowing ": "Yes",
      "Both side brake indicators shows red after resetting ": "OK",
      "Exhaust from emergency brake valve is stopped (Should Stop) ": "Stopped",
      "Both side brake indicators shows green ": "OK",
    },
    transition_key: null,
    form_fields: {
      "Pull the Emergency pull box handle and check (BP pressure should remain 2.0 +- 0.2 kg/cm^2) ":
        {
          type: "dropdown",
          options: ["Done", "Not Done"],
          defaultValue: "Done",
        },
      "Brake Accelarator does respond (Should respond) ": {
        type: "dropdown",
        options: ["Responded", "Not Responded"],
        defaultValue: "Responded",
      },
      "BP pressure exhaust from emergency brake valve (Should Yes) ": {
        type: "dropdown",
        options: ["Yes", "No"],
        defaultValue: "Yes",
      },
      "Indicator lamp on outside coach glowing ": {
        type: "dropdown",
        options: ["Yes", "No"],
        defaultValue: "Yes",
      },
      "Both side brake indicators shows red after resetting ": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "Exhaust from emergency brake valve is stopped (Should Stop) ": {
        type: "dropdown",
        options: ["Stopped", "Not Stopped"],
        defaultValue: "Stopped",
      },
      "Both side brake indicators shows green ": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 247,
    asset_name: "Hand Brake Test (Power car only)",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Apply Hand Brake by means of wheel": "OK",
      "Both side Hand Brake indicators shows red ": "OK",
      "Brake Cylinders provided with hand brake lever are applied": "Applied",
      "Movement of flex ball cable is proper ": "Yes",
      "Released Hand Brake by means of wheel brake should released ":
        "Released",
      "Both side Hand Brake indicators shows green ": "OK",
    },
    transition_key: null,
    form_fields: {
      "Apply Hand Brake by means of wheel": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "Both side Hand Brake indicators shows red ": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "Brake Cylinders provided with hand brake lever are applied": {
        type: "dropdown",
        options: ["Applied", "Not Applied"],
        defaultValue: "Applied",
      },
      "Movement of flex ball cable is proper ": {
        type: "dropdown",
        options: ["Yes", "No"],
        defaultValue: "Yes",
      },
      "Released Hand Brake by means of wheel brake should released ": {
        type: "dropdown",
        options: ["Released", "Not Released"],
        defaultValue: "Released",
      },
      "Both side Hand Brake indicators shows green ": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 248,
    asset_name: "Emergency brake by guard van valve(Power car only)",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Drop BP pressure by means of guard valve (Brake should apply)":
        "Applied",
      "Brake Accelerator should respond": "Responded",
      "Both side indicators shows red & found brake indicators shows green":
        "OK",

      "Reset guard van valve (Brake should released)": "Released",
    },
    transition_key: null,
    form_fields: {
      "Drop BP pressure by means of guard valve (Brake should apply)": {
        type: "dropdown",
        options: ["Applied", "Not Applied"],
        defaultValue: "Applied",
      },
      "Brake Accelerator should respond": {
        type: "dropdown",
        options: ["Responded", "Not Responded"],
        defaultValue: "Responded",
      },
      "Both side indicators shows red & found brake indicators shows green": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "Reset guard van valve (Brake should released)": {
        type: "dropdown",
        options: ["Released", "Not Released"],
        defaultValue: "Released",
      },
    },
  },
  {
    asset_dbid: 249,
    asset_name: "Manual Release Test",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Apply full brake application and pull manual release wire of DV. It should be released in one brief pull of manual release valve (CR drops to zero, brake release)":
        "OK",
    },
    transition_key: null,
    form_fields: {
      "Apply full brake application and pull manual release wire of DV. It should be released in one brief pull of manual release valve (CR drops to zero, brake release)":
        {
          type: "dropdown",
          options: ["OK", "NOT OK"],
          defaultValue: "OK",
        },
    },
  },
  {
    asset_dbid: 253,
    asset_name: "WSP Test",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Check all speedair gap between sensor and phonic wheel by means of teeler gauge":
        "",
      "Charge the BP/FP pressure at full specified value": "Done",
      "Check the WSP Micro processor Activated": "Activated",
      "Check the WSP Micro processor showing code 99": "Yes",
      "Check the Dump valve venting by test mode": "OK",
    },
    transition_key: null,
    form_fields: {
      "Check all speedair gap between sensor and phonic wheel by means of teeler gauge":
        {
          type: "string",
          hint: "0.9 to 1.4 mm",
        },
      "Charge the BP/FP pressure at full specified value": {
        type: "dropdown",
        options: ["Done", "Not Done"],
        defaultValue: "Done",
      },
      "Check the WSP Micro processor Activated": {
        type: "dropdown",
        options: ["Activated", "Not Activated"],
        defaultValue: "Activated",
      },
      "Check the WSP Micro processor showing code 99": {
        type: "dropdown",
        options: ["Yes", "No"],
        defaultValue: "Yes",
      },
      "Check the Dump valve venting by test mode": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "Ok",
      },
    },
  },
  {
    asset_dbid: 254,
    asset_name: "Clearance between Brake disc & Brake pad",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: { "Clearance between Brake disc & Brake pad": "" },
    transition_key: null,
    form_fields: {
      "Clearance between Brake disc & Brake pad": {
        type: "string",
        hint: "1.5 mm",
      },
    },
  },
  {
    asset_dbid: 255,
    asset_name: "Visual Examination of coupler head and body",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: [
      "Coupler Head",
      "Coupler Body",
      "Knuckle",
      "Knuckle pin",
      "Lock piece",
      "Knuckle Thrower",
      "Lock Lifter Assembly",
      "Wear Plate",
      "Silent Block",
      "Split pin for knuckle",
      "Support pin for knuckle",
      "Split pin for support pin replaced",
    ],
  },
  {
    asset_dbid: 256,
    asset_name: "Visual Examination of draft gear",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: { "Visual Examination of draft gear": "" },
    transition_key: null,
    form_fields: {
      "Visual Examination of draft gear": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 257,
    asset_name: "Visual Examination of Locking handle",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Locking handle", "Locking device"],
  },
  {
    asset_dbid: 258,
    asset_name: "Gauging of CBC",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Aligning wing limit gauge": "Passed",
      "Vertical height aligning wing pocket and guard arm gauge (go gauge)":
        "Passed",
      "Vertical height condemning limit aligning wing pocket and guard arm guage (no go gauge)":
        "Passed",
      "Contour maintenance gauge": "Passed",
      "Knuckle nose wear and stretch limit gauge": "Passed",
      "Contour condemning limit gauge": "Passed",
    },
    transition_key: null,
    form_fields: {
      "Aligning wing limit gauge": {
        type: "dropdown",
        options: ["Passed", "Failed"],
        defaultValue: "Passed",
      },
      "Vertical height aligning wing pocket and guard arm gauge (go gauge)": {
        type: "dropdown",
        options: ["Passed", "Failed"],
        defaultValue: "Passed",
      },
      "Vertical height condemning limit aligning wing pocket and guard arm guage (no go gauge)":
        {
          type: "dropdown",
          options: ["Passed", "Failed"],
          defaultValue: "Passed",
        },
      "Contour maintenance gauge": {
        type: "dropdown",
        options: ["Passed", "Failed"],
        defaultValue: "Passed",
      },
      "Knuckle nose wear and stretch limit gauge": {
        type: "dropdown",
        options: ["Passed", "Failed"],
        defaultValue: "Passed",
      },
      "Contour condemning limit gauge": {
        type: "dropdown",
        options: ["Passed", "Failed"],
        defaultValue: "Passed",
      },
    },
  },
  {
    asset_dbid: 259,
    asset_name: "Anti Creep Test",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "Passed",
    default_type: "",
    default_readings: null,
    transition_key: "",
    form_fields: {
      response: ["Passed", "Failed"],
      // response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Lock piece", "Knuckle thrower", "Lock Lifter Assembly"],
  },
  {
    asset_dbid: 260,
    asset_name: "Connecting Link",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["2 Holes", "3 Holes"],
  },
  {
    asset_dbid: 261,
    asset_name: "Brake Beam Assembly - Hanger",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Pin", "Bush", "Oblonged Hole"],
  },
  {
    asset_dbid: 262,
    asset_name: "Brake Beam Assembly - Truss Bar",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Bend", "Broken", "Oblonged"],
  },
  {
    asset_dbid: 263,
    asset_name: "Brake Beam Assembly - Brake Shoe",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Collar Bush ", "Slack Adjuster Pin", "Hole Oblonged"],
  },
  {
    asset_dbid: 264,
    asset_name: "Brake Beam Assembly - Brake Block",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Replace", "Hole Oblonged"],
  },
  {
    asset_dbid: 265,
    asset_name: "Isolating Cock",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "Trolly 1": "OK",
      "Trolly 2": "OK",
      AR: "OK",
      BP: "OK",
      ACP: "OK",
    },
    transition_key: null,
    form_fields: {
      "Trolly 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "Trolly 2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      AR: {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      BP: {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      ACP: {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 266,
    asset_name: "Hand Brake",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: { "Hand Brake": "Not Applicable" },
    transition_key: null,
    form_fields: {
      "Hand Brake": {
        type: "dropdown",
        options: ["Not Applicable", "OK", "NOT OK"],
        defaultValue: "Not Applicable",
      },
    },
  },
  {
    asset_dbid: 267,
    asset_name: "PEAV",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Leakage"],
  },
  {
    asset_dbid: 268,
    asset_name: "PEASD",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: { PEASD: "OK" },
    transition_key: null,
    form_fields: {
      PEASD: {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 269,
    asset_name: "Alarm Chain Pulling Test",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: ["Wire Rope Damage"],
  },
  {
    asset_dbid: 270,
    asset_name: "D V Particulars",
    responseType: "double_dropdown_response",
    options: [],
    default_response: "OK",
    default_type: "",
    default_readings: null,
    transition_key: null,
    form_fields: {
      response: ["OK", "NOT OK"],
    },
    checkbox_options: [
      "Release Time Beyond Limit",
      "Leakage - Diaphagm",
      "Leakage - QRV",
      "Leakage - Relay Valve",
      "Leakage - Not Charging",
      "Leakage - Over Charging",
    ],
  },
  {
    asset_dbid: 271,
    asset_name: "Condition of Primary Damper",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "KOP END 1": "OK",
      "KOP END 2": "OK",
      "KOP END 3": "OK",
      "KOP END 4": "OK",
      "PA END 1": "OK",
      "PA END 2": "OK",
      "PA END 3": "OK",
      "PA END 4": "OK",
    },
    transition_key: null,
    form_fields: {
      "KOP END 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "KOP END 2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "KOP END 3": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "KOP END 4": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 3": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 4": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 272,
    asset_name: "Condition of Secondary Damper",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "KOP END 1": "OK",
      "KOP END 2": "OK",
      "PA END 1": "OK",
      "PA END 2": "OK",
    },
    transition_key: null,
    form_fields: {
      "KOP END 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "KOP END 2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 273,
    asset_name: "Condition of Yaw Damper",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: {
      "KOP END 1": "OK",
      "KOP END 2": "OK",
      "PA END 1": "OK",
      "PA END 2": "OK",
    },
    transition_key: null,
    form_fields: {
      "KOP END 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "KOP END 2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 2": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
  {
    asset_dbid: 274,
    asset_name: "Condition of Lateral Damper",
    responseType: "multiple_level_form",
    options: [],
    default_response: "",
    default_type: "",
    default_readings: { "KOP END 1": "OK", "PA END 1": "OK" },
    transition_key: null,
    form_fields: {
      "KOP END 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
      "PA END 1": {
        type: "dropdown",
        options: ["OK", "NOT OK"],
        defaultValue: "OK",
      },
    },
  },
];

export const checklistDataL2 = [
  {
    asset_dbid: 59,
    asset_name: "Vinyl Stickers",
    responseType: "radio",
    label: "Rubber Molding",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 60,
    asset_name: "Paint",
    responseType: "radio",
    label: "Rubber Molding",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 16,
    asset_name: "Rubber Molding",
    responseType: "radio",
    label: "Rubber Molding",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 17,
    asset_name: "Door Stopper",
    responseType: "radio",
    label: "Door Stopper",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 18,
    asset_name: "Door Handle",
    responseType: "radio",
    label: "Door Handle",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 19,
    asset_name: "Latch Catch",
    responseType: "radio",
    label: "Latch Catch",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 20,
    asset_name: "Door Pivot",
    responseType: "radio",
    label: "Door Pivot",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 21,
    asset_name: "Glass Transparency",
    responseType: "radio",
    label: "Glass Transparency",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 23,
    asset_name: "Rubber Beading(AC)",
    responseType: "radio",
    label: "Rubber Beading(AC)",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 24,
    asset_name: "Glass Condition",
    responseType: "radio",
    label: "Glass Condition",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 25,
    asset_name: "Window Sealant(AC)",
    responseType: "radio",
    label: "Window Sealant(AC)",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 26,
    asset_name: "Glass Movement",
    responseType: "radio",
    label: "Glass Movement",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 27,
    asset_name: "Shutter Condition",
    responseType: "radio",
    label: "Shutter Condition",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 28,
    asset_name: "Shutter Movement",
    responseType: "radio",
    label: "Shutter Movement",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 29,
    asset_name: "Locking of Glass",
    responseType: "radio",
    label: "Locking of Glass",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 30,
    asset_name: "Window Sill and Frame",
    responseType: "radio",
    label: "Window Sill and Frame",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 31,
    asset_name: "Window Sill Putty",
    responseType: "radio",
    label: "Window Sill Putty",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 61,
    asset_name: "Destination Board",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 62,
    asset_name: "Coach Indication Board",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 64,
    asset_name: "Foot Step Fastners",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 63,
    asset_name: "Foot Step Body",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 32,
    asset_name: "Cushion",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 33,
    asset_name: "Rexine",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 34,
    asset_name: "PLY",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 35,
    asset_name: "Hinges",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 36,
    asset_name: "Chain",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 37,
    asset_name: "Chain Cover",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 38,
    asset_name: "Berth Indicator",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 39,
    asset_name: "Turn over latch",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 40,
    asset_name: "Ladder",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 41,
    asset_name: "Braile Berth indication Stickers",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 42,
    asset_name: "Emergency Window metal grill",
    responseType: "radio",
    label: "Emergency Window metal grill",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 43,
    asset_name: "Emergency Window Locking Mechanism",
    responseType: "radio",
    label: "Emergency Window Locking Mechanism",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 44,
    asset_name: "PVC flooring",
    responseType: "radio",
    label: "PVC flooring",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 45,
    asset_name: "LP sheets",
    responseType: "radio",
    label: "LP sheets",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 46,
    asset_name: "Moulding patti",
    responseType: "radio",
    label: "Moulding patti",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 47,
    asset_name: "Internal Painting",
    responseType: "radio",
    label: "Internal Painting",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 48,
    asset_name: "Limpet sheet",
    responseType: "radio",
    label: "Limpet sheet",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 49,
    asset_name: "Vinyl Stickers (Internal)",
    responseType: "radio",
    label: "Vinyl Stickers (Internal)",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 50,
    asset_name: "Coat Hook",
    responseType: "radio",
    label: "Coat Hook",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 51,
    asset_name: "Bottle holder",
    responseType: "radio",
    label: "Bottle holder",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 52,
    asset_name: "Mirror",
    responseType: "radio",
    label: "Mirror",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 53,
    asset_name: "Snack Table",
    responseType: "radio",
    label: "Snack Table",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 54,
    asset_name: "Luggage rake",
    responseType: "radio",
    label: "Luggage rake",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 55,
    asset_name: "Window curtain",
    responseType: "radio",
    label: "Window curtain",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 56,
    asset_name: "Side curtain",
    responseType: "radio",
    label: "Side curtain",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 57,
    asset_name: "Mobile Holder",
    responseType: "radio",
    label: "Mobile Holder",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 58,
    asset_name: "Magzine Pocket",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },

  {
    asset_dbid: 73,
    asset_name: "Thrower Latch",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 74,
    asset_name: "Panel",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 75,
    asset_name: "Lock Latch",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 70,
    asset_name: "Door Closure",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 76,
    asset_name: "Frosted Glass",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 65,
    asset_name: "Sink",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 66,
    asset_name: "Drainage pipe",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 67,
    asset_name: "Tap",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 68,
    asset_name: "Soap Dispenser",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 69,
    asset_name: "Dustbin",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 77,
    asset_name: "Vinyl",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 78,
    asset_name: "Tiles",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 79,
    asset_name: "ACP Sheets",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 80,
    asset_name: "Epoxy Flooring",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 71,
    asset_name: "Door Glass Condition",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 72,
    asset_name: "Door Glass Transparency",
    responseType: "radio",
    label: "Magzine Pocket",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 81,
    asset_name: "Guide Bush",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 82,
    asset_name: "Dust Shield No",
    asset_l2_quantity: 16,
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 83,
    asset_name: "Oil Cup No",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 84,
    asset_name: "Protection Tube",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 85,
    asset_name: "Upper Hydral Washer",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    transition_key: "NOT OK",
  },
  {
    asset_dbid: 86,
    asset_name: "Lower Hydral Washer",
    responseType: "switch",
    options: ["OK", "NOT OK"],
    default_response: "OK",
    transition_key: "NOT OK",
  },

  {
    asset_dbid: 71,
    asset_name: "Liquid Soap Dispensor",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 72,
    asset_name: "Toilet Paper Roll Bracket",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 73,
    asset_name: "Odour Control Container",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 74,
    asset_name: "Mug With Chain",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 75,
    asset_name: "Health Faucet",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 76,
    asset_name: "Braile Stickers",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 77,
    asset_name: "Holding Rod",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },

  {
    asset_dbid: 78,
    asset_name: "Reclining Arrangement",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 79,
    asset_name: "Arm Rest Molding",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 80,
    asset_name: "Head Rest",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 81,
    asset_name: "Foot Rest",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },

  {
    asset_dbid: 82,
    asset_name: "General Pipe Line",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 83,
    asset_name: "Water Tank",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 84,
    asset_name: "Pan (Indian)",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 85,
    asset_name: "Commode (Western)",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 86,
    asset_name: "Flush Valve",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 87,
    asset_name: "Commode Cover",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 88,
    asset_name: "Wall Protector",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 89,
    asset_name: "Fish Tail",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 90,
    asset_name: "Shower",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 91,
    asset_name: "Water Column",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 92,
    asset_name: "Rubber Beading",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 93,
    asset_name: "Window Sealant",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 94,
    asset_name: "BP Pipe",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 96,
    asset_name: "By pass",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 95,
    asset_name: "FP Pipe",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 98,
    asset_name: "Fire Extinguisher",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 97,
    asset_name: "Wooden Wadges",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 99,
    asset_name: "Box Door",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 100,
    asset_name: "Latch",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 106,
    asset_name: "Flooring",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 105,
    asset_name: "Roof Ceiling",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 103,
    asset_name: "Handle",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 104,
    asset_name: "Runner",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 101,
    asset_name: "Guard Table",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 102,
    asset_name: "Wooden Box",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 107,
    asset_name: "Washing Tub",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 109,
    asset_name: "Mounting Bracket",
    responseType: "radio",
    label: "",
    options: ["Crack", "Corroded", "Bend"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 108,
    asset_name: "Nut Bolt",
    responseType: "radio",
    label: "",
    options: ["Damaged", "Missing", "Loose"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 112,
    asset_name: "Safety Strap/ Wire Rope Locking Bolt",
    responseType: "radio",
    label: "",
    options: ["Thread Worn-Out", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 111,
    asset_name: "Safety Strap/ Wire Rope Locking Plate",
    responseType: "radio",
    label: "",
    options: ["Thread Worn-Out", "Missing"],
    default_response: "",
    transition_key: null,
  },
  {
    asset_dbid: 110,
    asset_name: "Safety Wire Rope/ Strap",
    responseType: "radio",
    label: "",
    options: ["Loose", "Crack"],
    default_response: "",
    transition_key: null,
  },
];
