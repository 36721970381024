import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Accordion } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { InputGroup } from "@themesberg/react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { axiosInstance } from "../../../../axios";
import {
  faArrowRotateLeft,
  faCheck,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "../../ioh.css";
import TrainInformation from "../../../../components/TrainInformation";
import { FormatSimpleDate } from "../../../../components/dateformat";
import { Spinner } from "react-bootstrap";

export const IOHPlanner = (props) => {
  const [plannerData, setPlannerData] = useState(null);
  const [plannerDataOnTable, updatePlannerDataOnTable] = useState(null);
  const [coachOptions, updateCoachOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");

  function sortByDueDate(a, b) {
    if (a.ioh_due_date > b.ioh_due_date) return 1;
    if (a.ioh_due_date == b.ioh_due_date) return 0;
    if (a.ioh_due_date < b.ioh_due_date) return -1;
  }

  useEffect(() => {
    //Loads data for first page
    axiosInstance
      .get(`assets/coaches/?keyword=planner`, {})
      .then((res) => {
        const data = res.data;
        setPlannerData(data);
        updatePlannerData(data);

        const tempCoachData = [];
        Object.values(data).forEach((item) => {
          if (item.coach_id && !tempCoachData.includes(item.coach_id)) {
            tempCoachData.push(item.coach_id);
          }
          if (item.coach_type && !tempCoachData.includes(item.coach_type)) {
            tempCoachData.push(item.coach_type);
          }
          if (item.ioh_status && !tempCoachData.includes(item.ioh_status)) {
            tempCoachData.push(item.ioh_status);
          }
          if (item.coach_status && !tempCoachData.includes(item.coach_status)) {
            tempCoachData.push(item.coach_status);
          }
          if (
            item.detachment_reason &&
            !tempCoachData.includes(item.detachment_reason)
          ) {
            tempCoachData.push(item.detachment_reason);
          }
        });
        updateCoachOptions(tempCoachData);
        // console.log(tempCoachData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updatePlannerData = (data) => {
    if (data) {
      const nsmCoaches = [];
      let overDueCoaches = [];
      const riskOverDueCoaches = [];
      const dueCoaches = [];
      const dataWithNoDueDate = [];
      let cleanData = {};
      const today = new Date();
      data.forEach((item) => {
        //removing data without ioh_due_date, so as to sort properly
        if (!item.ioh_due_date) {
          const index = data.indexOf(item);
          if (index > -1) {
            data.splice(index, 1); // 2nd parameter means remove one item only
          }
          // data.pop(item);
          dataWithNoDueDate.push(item);
        } else {
          const dueDate = new Date(item.ioh_due_date);
          const overDueDate = new Date(item.ioh_overdue_date);
          // console.log(dueDate.getMonth(), today.getMonth());
          // cleanData.push(item);
          if (item.detachment_reason === "IOH-NSM") {
            nsmCoaches.push(item);
          } else if (
            dueDate > today &&
            dueDate.getMonth() === today.getMonth() &&
            dueDate.getFullYear() === today.getFullYear()
          ) {
            dueCoaches.push(item);
          } else if (today < overDueDate) {
            riskOverDueCoaches.push(item);
          } else {
            overDueCoaches.push(item);
          }
        }
      });
      nsmCoaches.sort(sortByDueDate);
      dueCoaches.sort(sortByDueDate);
      riskOverDueCoaches.sort(sortByDueDate);
      overDueCoaches.sort(sortByDueDate);
      overDueCoaches = [...overDueCoaches, ...dataWithNoDueDate];
      // dataWithNoDueDate.forEach((item) => {
      //   //adding data with no due date back to data, after sorting
      //   cleanData.push(item);
      // });
      cleanData["nsmCoaches"] = nsmCoaches;
      cleanData["overDueCoaches"] = overDueCoaches;
      cleanData["riskOverDueCoaches"] = riskOverDueCoaches;
      cleanData["dueCoaches"] = dueCoaches;
      console.log(cleanData);
      updatePlannerDataOnTable(cleanData);
    }
  };
  const handleChange = (e) => {
    // console.log(e);
    setSelected();
    setTypeAheadValue(e[0]);
  };
  const handleFilterButtonSubmit = (e) => {
    e.preventDefault();
    console.log(typeAheadValue);
    handleCoachNumberSubmit(typeAheadValue);
  };

  const handleCoachNumberSubmit = (coachID) => {
    // console.log(plannerData);
    if (coachID) {
      let filteredData = plannerData.filter(
        (item) => item.coach_id === coachID
      );
      if (filteredData.length === 0) {
        // console.log("not coach");
        filteredData = plannerData.filter(
          (item) => item.coach_status === coachID
        );
      }
      if (filteredData.length === 0) {
        // console.log("not coach");
        filteredData = plannerData.filter(
          (item) => item.coach_type === coachID
        );
      }
      if (filteredData.length === 0) {
        // console.log("not coach");
        filteredData = plannerData.filter(
          (item) => item.ioh_status === coachID
        );
      }
      if (filteredData.length === 0) {
        // console.log("not coach not status");
        filteredData = plannerData.filter(
          (item) => item.detachment_reason === coachID
        );
      }

      // console.log(filteredData);
      updatePlannerData(filteredData);
    } else {
      updatePlannerData(plannerData);
    }
  };

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>IOH Planner</h6>
          {plannerData === null && (
            <Spinner animation="border" variant="info" />
          )}
        </Card.Header>{" "}
        <Card.Body>
          <Form onSubmit={handleFilterButtonSubmit}>
            <Row>
              <Col sm="4">
                <Form.Group className="">
                  <InputGroup name="coach_id">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Typeahead
                      id="basic-example"
                      onChange={handleChange}
                      options={coachOptions}
                      placeholder="Enter Coach Number..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCoachNumberSubmit(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm="2">
                <Button variant="outline-gray" className="" type="submit">
                  Lookup
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card className="my-2">
        {plannerDataOnTable && (
          <>
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="no__background">
                <Accordion.Button
                  variant="link"
                  className="w-100 d-flex justify-content-between product__button"
                  bg="light"
                >
                  <div className="d-flex justify-content-between">
                    <span className="h6 mb-0 fw-bold">Non Schedule</span>
                  </div>
                </Accordion.Button>
                <Accordion.Body>
                  {plannerDataOnTable.nsmCoaches &&
                    plannerDataOnTable.nsmCoaches.length > 0 && (
                      <TypeAccordion data={plannerDataOnTable.nsmCoaches} />
                    )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey={["1"]} alwaysOpen>
              <Accordion.Item eventKey="1" className="no__background">
                <Accordion.Button
                  variant="link"
                  className="w-100 d-flex justify-content-between product__button"
                  bg="light"
                >
                  <div className="d-flex justify-content-between">
                    <span className="h6 mb-0 fw-bold">Running beyond 10/19 month</span>
                  </div>
                </Accordion.Button>
                <Accordion.Body>
                  {plannerDataOnTable.overDueCoaches &&
                    plannerDataOnTable.overDueCoaches.length > 0 && (
                      <TypeAccordion data={plannerDataOnTable.overDueCoaches} />
                    )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey={["2"]} alwaysOpen>
              <Accordion.Item eventKey="2" className="no__background">
                <Accordion.Button
                  variant="link"
                  className="w-100 d-flex justify-content-between product__button"
                  bg="light"
                >
                  <div className="d-flex justify-content-between">
                    <span className="h6 mb-0 fw-bold">Running in 10/19 month</span>
                  </div>
                </Accordion.Button>
                <Accordion.Body>
                  {plannerDataOnTable.riskOverDueCoaches &&
                    plannerDataOnTable.riskOverDueCoaches.length > 0 && (
                      <TypeAccordion
                        data={plannerDataOnTable.riskOverDueCoaches}
                      />
                    )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey={["3"]} alwaysOpen>
              <Accordion.Item eventKey="3" className="no__background">
                <Accordion.Button
                  variant="link"
                  className="w-100 d-flex justify-content-between product__button"
                  bg="light"
                >
                  <div className="d-flex justify-content-between">
                    <span className="h6 mb-0 fw-bold">Running in 9/18 month</span>
                  </div>
                </Accordion.Button>
                <Accordion.Body>
                  {plannerDataOnTable.dueCoaches &&
                    plannerDataOnTable.dueCoaches.length > 0 && (
                      <TypeAccordion data={plannerDataOnTable.dueCoaches} />
                    )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        )}
      </Card>
    </div>
  );
};

const TypeAccordion = (props) => {
  const { data } = props;
  return (
    <Table
      // striped
      bordered
      hover
      responsive
      className="table-centered table-nowrap rounded mb-0"
    >
      <thead className="text-center">
        <tr>
          <th>#</th>
          <th>Coach Number</th>
          <th>Coach Type</th>
          <th>OverDue/Buffer Date</th>
          <th>IOH/SS1 Due Date</th>
          <th>Train Number</th>
          {/* <th>Coach Returning Date</th> */}
          {/* <th>Plan for IOH</th> */}
          <th>Coach Status</th>
          <th>IOH Status</th>
          <th>Detachment Reason</th>
          <th>Planned IOH Date</th>
          <th>POH Date</th>
          <th>Return Date</th>
          <th>Reduced Date</th>
          <th>Extended Date</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {Object.values(data).map((coach, index) => {
          return (
            <PlanTable key={`issue_${index}`} coachData={coach} index={index} />
          );
        })}
      </tbody>
    </Table>
  );
};

const PlanTable = (props) => {
  const { coachData, index } = props;
  const [currentCoachData, updateCurrentCoachdata] = useState(coachData);
  const [showChecked, updateShowChecked] = useState(
    coachData.ioh_status === "Planned / Under Diagnosis"
  );
  const [date, updateDate] = useState(coachData.ioh_planned_date);
  // console.log(currentCoachData);

  useEffect(() => {
    //Loads data for first page
    updateCurrentCoachdata(coachData);
    updateShowChecked(coachData.ioh_status === "Planned / Under Diagnosis");
    updateDate(coachData.ioh_planned_date);
  }, [coachData]);

  const handleScheduleSubmit = (e) => {
    if (date) {
      axiosInstance
        .patch(`assets/coaches/${coachData.coach_dbid}/`, {
          ioh_planned_date: date,
          planned_for_ioh_flag: true,
          ioh_status: "Planned / Under Diagnosis",
          // detachment_reason: "IOH",
        })
        .then((res) => {
          const data = res.data;
          // console.log(data);
          updateShowChecked(true);
          updateCurrentCoachdata({
            ...currentCoachData,
            ["ioh_status"]: "Planned / Under Diagnosis",
          });
          // updateDate(null);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRevertSchedule = (e) => {
    axiosInstance
      .patch(`assets/coaches/${coachData.coach_dbid}/`, {
        ioh_planned_date: null,
        planned_for_ioh_flag: false,
        ioh_status: null,
        // detachment_reason: null,
      })
      .then((res) => {
        const data = res.data;
        console.log(data);
        updateShowChecked(false);
        updateCurrentCoachdata({
          ...currentCoachData,
          ["ioh_status"]: null,
        });
        // updateDate(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResolveNSM = (e) => {
    axiosInstance
      .patch(`assets/coaches/${coachData.coach_dbid}/`, {
        ioh_planned_date: null,
        planned_for_ioh_flag: false,
        ioh_status: "Issues Registered",
        // detachment_reason: null,
      })
      .then((res) => {
        const data = res.data;
        // console.log(data);
        updateShowChecked(false);
        updateCurrentCoachdata({
          ...currentCoachData,
          ["ioh_status"]: "Issues Registered",
        });
        // updateDate(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {currentCoachData && (
        <tr>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{currentCoachData.coach_id}</td>
          <td className="text-center">{currentCoachData.coach_type}</td>
          <td className="text-center">
            {FormatSimpleDate(currentCoachData.ioh_overdue_date)}
          </td>
          <td className="text-center">
            {FormatSimpleDate(currentCoachData.ioh_due_date)}
          </td>
          <td className="text-center">
            <TrainInformation coachDataSchedule={currentCoachData.schedule} />
          </td>
          {/* <td className="text-center">
        {datesToShow &&
          datesToShow.map((givenDate) => {
            return (
              <span className="mx-1" key={givenDate}>
                {givenDate}
              </span>
            );
          })}
      </td> */}
          {/* <td className="text-center">
        <Form.Select name="12" required>
          <option key={`plan-option-yes`} value="Yes">
            Yes
          </option>
          <option key={`plan-option-no`} value="No">
            No
          </option>
        </Form.Select>
      </td> */}
          <td className="text-center">{currentCoachData.coach_status}</td>
          <td className="text-center">{currentCoachData.ioh_status}</td>
          <td className="text-center">
            {(currentCoachData.coach_status === "Sick" ||
              currentCoachData.ioh_status === "Planned / Under Diagnosis") &&
              currentCoachData.detachment_reason}
          </td>
          <td className="text-center">
            <FormControl
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              defaultValue={currentCoachData.ioh_planned_date}
              name=""
              type="date"
              disabled={
                currentCoachData.ioh_status === "Issues Registered" ||
                currentCoachData.ioh_status === "Unfit"
              }
              onChange={(e) => updateDate(e.target.value)}
            />
          </td>
          <td className="text-center">{currentCoachData.p_date}</td>
          <td className="text-center">{currentCoachData.r_date}</td>
          <td className="text-center">{currentCoachData.reduced_r_date}</td>
          <td className="text-center">{currentCoachData.extended_r_date}</td>
          <td>
            {currentCoachData.coach_status === "Sick" &&
              currentCoachData.detachment_reason === "IOH-NSM" && (
                <Button
                  variant="gray"
                  onClick={handleResolveNSM}
                  disabled={showChecked}
                  size="sm"
                  className="mx-2"
                >
                  Resolve NSM
                </Button>
              )}
            <Button
              variant="gray"
              onClick={handleScheduleSubmit}
              disabled={
                showChecked ||
                currentCoachData.ioh_status === "Issues Registered" ||
                currentCoachData.ioh_status === "Unfit"
              }
              size="sm"
              className="mx-2"
            >
              Schedule
            </Button>
            {showChecked && (
              <>
                <Badge className="mx-2" bg="success">
                  <FontAwesomeIcon icon={faCheck} />
                </Badge>
                <Button
                  className="mx-2"
                  size="sm"
                  variant="white"
                  onClick={handleRevertSchedule}
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                </Button>
              </>
            )}
          </td>
        </tr>
      )}
    </>
  );
};
