import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table, Form } from "react-bootstrap";
import { axiosInstance } from "../../../../axios";
import DragCoach from "./coachMovement";
const ViewRakes = (props) => {
  const { rakeID, highlightedCoach } = props;
  const [rakeData, updateRakeData] = useState(null);
  const [rakeCoaches, updateRakeCoaches] = useState(null);
  const [patchData, updatePatchData] = useState({});
  const [mode, setMode] = useState("READ");
  const [trainOptions, updateTrainOptions] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`assets/trains/`, {})
      .then((res) => {
        const data = res.data;
        updateTrainOptions(data);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
    axiosInstance
      .get(`assets/rakes/${rakeID}`, {})
      .then((res) => {
        const data = res.data;
        updateRakeData(data);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleEditClick = () => {
    setMode("EDIT");
  };
  const handleCancelEditClick = () => {
    setMode("READ");
    // updatePatchData({});
  };

  function sortByPositionInRake(a, b) {
    if (a.position_in_rake > b.position_in_rake) return 1;
    if (a.position_in_rake == b.position_in_rake) return 0;
    if (a.position_in_rake < b.position_in_rake) return -1;
  }

  useEffect(() => {
    if (rakeData) {
      const tempCoachData = rakeData?.coaches;
      tempCoachData.sort(sortByPositionInRake);
      updateRakeCoaches(tempCoachData);
    }
  }, [rakeData]);

  const handleChange = (e) => {
    updatePatchData({ ...patchData, [e.target.name]: e.target.value });
  };
  const handleCoachInRakes = (data) => {
    updatePatchData({ ...patchData, ["coaches"]: data });
  };

  const handleRakeEdit = (e) => {
    e.preventDefault();
    // console.log(rakeData);
    if (patchData) {
      // console.log(patchData);
      axiosInstance
        .patch(`assets/rakes/${rakeData.rake_dbid}/`, patchData)
        .then((res) => {
          const data = res.data;
          updateRakeData(data);
          handleCancelEditClick();
          // console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      {rakeData && (
        <Card>
          <Row>
            <Col sm="12">
              <Form onSubmit={handleRakeEdit}>
                <Card.Body>
                  <div className="my-2 d-flex justify-content-end">
                    {mode === "READ" && (
                      <Button
                        onClick={handleEditClick}
                        variant="gray"
                        className="m-1"
                      >
                        Edit
                      </Button>
                    )}
                    {mode === "EDIT" && (
                      <>
                        <Button
                          onClick={handleCancelEditClick}
                          variant="gray"
                          className="m-1"
                        >
                          Cancel
                        </Button>
                        <Button variant="success" className="m-1" type="submit">
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                  <Table
                    bordered
                    responsive
                    className="table-centered table-nowrap rounded mb-0"
                  >
                    <tbody>
                      <tr>
                        <th>Rake Name</th>
                        {mode === "READ" && <td>{rakeData.rake_name}</td>}
                        {mode === "EDIT" && (
                          <td>
                            <Form.Control
                              onChange={handleChange}
                              name="rake_name"
                              defaultValue={rakeData.rake_name}
                              type="text"
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <th>Train</th>
                        {mode === "READ" && <td>{rakeData.train_number}</td>}
                        {mode === "EDIT" && (
                          <td>
                            <Form.Select
                              onChange={handleChange}
                              name="train_number"
                            >
                              {/* <option>Open this select menu</option> */}
                              {Object.values(trainOptions).map(
                                (train, index) => {
                                  return (
                                    <option
                                      value={train.train_dbid}
                                      selected={
                                        train.train_number ===
                                        rakeData.train_number
                                      }
                                      key={`coach_type_${index}`}
                                    >
                                      {train.train_number}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </Table>
                  <Card className="my-2">
                    {mode === "READ" && rakeCoaches && (
                      <ViewCoachTable
                        coaches={rakeCoaches}
                        highlightedCoach={highlightedCoach}
                      />
                    )}
                    {mode === "EDIT" && rakeCoaches && (
                      <DragCoach
                        rakeID={rakeData.rake_dbid}
                        coaches={rakeCoaches}
                        handleCoachInRakes={handleCoachInRakes}
                        highlightedCoach={highlightedCoach}
                      />
                    )}
                  </Card>
                </Card.Body>
              </Form>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

const ViewCoachTable = (props) => {
  const { coaches, highlightedCoach } = props;
  return (
    <>
      {coaches && (
        <Table bordered className="table-centered rounded mb-0 align-middle">
          <thead className="text-center">
            <tr>
              <th>Coach ID</th>
              <th>Type</th>
              <th>Position</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {coaches.map((coach, index) => {
              return (
                <tr
                  className={
                    highlightedCoach === coach.coach_dbid ? "bg-light" : ""
                  }
                >
                  <td
                    className="text-info fw-bold"
                    // onClick={(e) => console.log(coach.coach_id)}
                  >
                    {coach.coach_id}
                  </td>
                  <td>{coach.coach_type}</td>
                  <td>{coach.position_in_rake}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ViewRakes;
