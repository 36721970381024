import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { FloatingLabel, Form, FormControl, Row } from "react-bootstrap";
import { axiosInstance } from "../../../axios";
import {
  FormatSimpleDate,
  FormatSimpleDate2,
} from "../../../components/dateformat";
import { useEffect } from "react";
import { ElectricalMainHead, ElectricalSubHead } from "./ComplaintsData";

const ComplaintModal = (props) => {
  const { complaint, handleModalClose, handleComplaintUpdatedData } = props;
  const DeptList = JSON.parse(localStorage.getItem("DeptList"));
  const userdeptList = JSON.parse(localStorage.getItem("user_dept"));

  // console.log(complaint);
  // console.log(DeptList);
  const [updatedComplaintData, setUpdatedComplaintData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [enableBtnST, setEnableBtnST] = useState(false);
  const [userAccessLevel, setUserAccessLevel] = useState(false);
  const [deptDependentHeads, setDeptDependentHeads] = useState(
    complaint ? complaint : null
  );

  useEffect(() => {
    if (complaint !== null) {
      let userName = localStorage.getItem("user_name");
      let userGroup = localStorage.getItem("user_group");
      // console.log(userName);
      // console.log(complaint.created_by);

      const userGroupArray = JSON.parse(userGroup);
      const valuesToCheck = ["Super Admin", "CDO", "SRDME"];
      const exists = valuesToCheck.some((value) =>
        userGroupArray.includes(value)
      );
      if (exists === true) {
        setEnableBtnST(false);
      }
      if (exists === false) {
        if (userName !== complaint.created_by) {
          setEnableBtnST(true);
          console.log("differnt - user");
        }
      }
    }
  }, []);

  const handleSubmitEditedData = async () => {
    try {
      console.log("edited data", updatedComplaintData);
      // console.log("2data", deptDependentHeads);

      const res = await axiosInstance.patch(
        `assets/complaints/${complaint.complaint_dbid}/`,
        updatedComplaintData
      );
      const data = res.data;
      console.log(data);
      handleComplaintUpdatedData(data);
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickEditMode = () => {
    setEditMode(1);
  };
  const onClickCloseInEditMode = () => {
    setEditMode(0);
  };
  const onClickSaveInEditMode = () => {
    handleSubmitEditedData();
    setEditMode(0);
    setEnableBtnST(false);
  };
  const handleOnChange = (e) => {
    console.log(e.target.value);

    if (e.target.name === "department") {
      setDeptDependentHeads({
        ...deptDependentHeads,
        [e.target.name]: e.target.value,
        main_heading: "",
        sub_heading: "",
      });
      setUpdatedComplaintData({
        ...updatedComplaintData,
        [e.target.name]: e.target.value,
        main_heading: "",
        sub_heading: "",
      });
    } else if (e.target.name === "main_heading") {
      setDeptDependentHeads({
        ...deptDependentHeads,
        [e.target.name]: e.target.value,
      });
      setUpdatedComplaintData({
        ...updatedComplaintData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "sub_heading") {
      setDeptDependentHeads({
        ...deptDependentHeads,
        [e.target.name]: e.target.value,
      });
      setUpdatedComplaintData({
        ...updatedComplaintData,
        [e.target.name]: e.target.value,
      });
    } else {
      setUpdatedComplaintData({
        ...updatedComplaintData,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Complaint No. {complaint.complaint_number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* editing form data */}
          {
            <Table
              responsive
              className="table-centered rounded mb-0 align-middle"
              bordered
              hover
            >
              <tbody>
                <tr>
                  <th>Complaint No.</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.complaint_number}
                      name="complaint_number"
                      handleOnChange={handleOnChange}
                      wetherEditMode={false}
                    />
                  </td>
                  {/* <td>{complaint.complaint_number}</td> */}
                </tr>
                <tr>
                  <th>Train No.</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.train_number}
                      name="train_number"
                      handleOnChange={handleOnChange}
                      wetherEditMode={editMode}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Train Name</th>

                  {complaint.train_details &&
                    complaint.train_details.train_name != null &&
                    complaint.train_details.train_name.trim() !== "" && (
                      <td>{complaint.train_details.train_name}</td>
                    )}
                </tr>
                <tr>
                  <th>Department</th>
                  {editMode === false ? (
                    <td>{complaint.department_name || ""}</td>
                  ) : userdeptList.length === 1 ? (
                    <td>{userdeptList[0].dept_name}</td>
                  ) : (
                    <td>
                      {" "}
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Department"
                        className="mb-3"
                      >
                        <Form.Select
                          onChange={handleOnChange}
                          name="department"
                          required
                          defaultValue={complaint.department}
                        >
                          {/* <option value="">All</option> */}
                          {DeptList.map((dept, index) => {
                            return (
                              <option
                                value={dept.dept_dbid}
                                key={`dept_${index}`}
                              >
                                {dept.dept_name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </FloatingLabel>
                    </td>
                  )}
                </tr>
                <tr>
                  <th>Receiving DateTime</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.receiving_datetime}
                      name="receiving_datetime"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Closing DateTime</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.closing_datetime}
                      name="closing_datetime"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Time Taken for Closing</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.time_taken_for_closing}
                      name="time_taken_for_closing"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Source</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.source}
                        name="source"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Railway</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.railway}
                        name="railway"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Owning Division</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.owning_division}
                        name="owning_division"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {userdeptList.length > 1 && (
                  <tr>
                    <th>Main Heading</th>
                    {/* <td> {complaint.main_heading}</td> */}
                    {editMode === false ? (
                      <td> {deptDependentHeads.main_heading}</td>
                    ) : (
                      <td>
                        {deptDependentHeads.department == "1" ? (
                          // <div>mechadata</div>
                          <EditComponent
                            defaultValue={deptDependentHeads.main_heading}
                            name="main_heading"
                            wetherEditMode={editMode}
                            handleOnChange={handleOnChange}
                          />
                        ) : deptDependentHeads.department == "2" ? (
                          // <div>electdata</div>
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={handleOnChange}
                              name="main_heading"
                              defaultValue={deptDependentHeads.main_heading}
                            >
                              <option value="">-</option>
                              {ElectricalMainHead.map((opt, index) => {
                                return (
                                  <option
                                    value={opt}
                                    key={`${opt}_${index}`}
                                    data-dept={opt}
                                  >
                                    {opt}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        ) : (
                          <div>main_heading</div>
                        )}
                      </td>
                    )}
                  </tr>
                )}
                {userdeptList.length > 1 && (
                  <tr>
                    <th>Sub Heading</th>
                    {/* <td> {complaint.sub_heading}</td> */}
                    {editMode === false ? (
                      <td> {deptDependentHeads.sub_heading}</td>
                    ) : (
                      <td>
                        {deptDependentHeads.department == "1" ? (
                          // <div>mechadata</div>
                          <EditComponent
                            defaultValue={deptDependentHeads.sub_heading}
                            name="sub_heading"
                            wetherEditMode={editMode}
                            handleOnChange={handleOnChange}
                          />
                        ) : deptDependentHeads.department == "2" ? (
                          // <div>electdata</div>
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={handleOnChange}
                              name="sub_heading"
                              defaultValue={deptDependentHeads.sub_heading}
                            >
                              <option value="">-</option>
                              {ElectricalSubHead.map((opt, index) => {
                                return (
                                  <option
                                    value={opt}
                                    key={`${opt}_${index}`}
                                    data-dept={opt}
                                  >
                                    {opt}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        ) : (
                          <div>sub_heading</div>
                        )}
                      </td>
                    )}
                  </tr>
                )}
                {userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical" && (
                    <tr>
                      <th>Main Heading</th>
                      <td>
                        <EditComponent
                          defaultValue={complaint.main_heading}
                          name="main_heading"
                          wetherEditMode={editMode}
                          handleOnChange={handleOnChange}
                        />
                      </td>
                    </tr>
                  )}{" "}
                {userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical" && (
                    <tr>
                      <th>Sub Heading</th>
                      <td>
                        <EditComponent
                          defaultValue={complaint.sub_heading}
                          name="sub_heading"
                          wetherEditMode={editMode}
                          handleOnChange={handleOnChange}
                        />
                      </td>
                    </tr>
                  )}
                {userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Electrical" && (
                    <tr>
                      <th>Elect. Main heading</th>
                      {editMode === false ? (
                        <td> {complaint.main_heading}</td>
                      ) : (
                        <td>
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={handleOnChange}
                              name="main_heading"
                              defaultValue={complaint.main_heading}
                            >
                              <option value="">-</option>
                              {ElectricalMainHead.map((opt, index) => {
                                return (
                                  <option
                                    value={opt}
                                    key={`${opt}_${index}`}
                                    data-dept={opt}
                                  >
                                    {opt}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </td>
                      )}
                    </tr>
                  )}
                {userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Electrical" && (
                    <tr>
                      <th>Elect. Sub heading</th>
                      {editMode === false ? (
                        <td> {complaint.sub_heading}</td>
                      ) : (
                        <td>
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={handleOnChange}
                              name="sub_heading"
                              defaultValue={complaint.sub_heading}
                            >
                              <option value="">-</option>
                              {ElectricalSubHead.map((opt, index) => {
                                return (
                                  <option
                                    value={opt}
                                    key={`${opt}_${index}`}
                                    data-dept={opt}
                                  >
                                    {opt}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </td>
                      )}
                    </tr>
                  )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>SLA1/SLA2</th>
                    {editMode === false ? (
                      <td> {complaint.sla_type}</td>
                    ) : (
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => handleOnChange(e)}
                          name="sla_type"
                          defaultValue={complaint.sla_type}
                        >
                          <option value="">-</option>
                          <option value="SLA 1">SLA 1</option>
                          <option value="SLA 2">SLA 2</option>
                        </Form.Select>
                      </td>
                    )}
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Electrical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Channel</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.channel}
                        name="channel"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Territory</th>

                    {editMode === false ? (
                      <td>{complaint.territory}</td>
                    ) : (
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => handleOnChange(e)}
                          name="territory"
                          defaultValue={complaint.territory}
                        >
                          <option value="">-</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Form.Select>
                      </td>
                    )}
                  </tr>
                )}
                <tr>
                  <th>Passenger Feedback</th>

                  {editMode === false ? (
                    <td>{complaint.passenger_feedback}</td>
                  ) : (
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => handleOnChange(e)}
                        name="passenger_feedback"
                        defaultValue={complaint.passenger_feedback}
                      >
                        <option value="">-</option>
                        <option value="Excellent">Excellent</option>
                        <option value="Satisfactory">Satisfactory</option>
                        <option value="Unsatisfactory">Unsatisfactory</option>
                        <option value="Nil">Nil</option>
                      </Form.Select>
                    </td>
                  )}
                </tr>
                <tr>
                  <th>Description</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.description}
                      name="description"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Date of Departure</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.date_of_departure}
                        name="date_of_departure"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Interim Reply DateTime</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.interim_reply_datetime}
                        name="interim_reply_datetime"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Coach Number</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.coach_number}
                      name="coach_number"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>{" "}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Physical Coach No</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.physical_coach_num}
                        name="physical_coach_num"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Coach ID</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.coach_id}
                        name="coach_id"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Coach Type</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.coach_type}
                      name="coach_type"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Upcoming Station</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.upcoming_station}
                        name="upcoming_station"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Resolved Location</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.resolved_location}
                        name="resolved_location"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Responsibility</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.responsibility}
                        name="responsibility"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Responsible Contractor</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.responsible_contractor}
                        name="responsible_contractor"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Responsible Contractor Staff</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.responsible_contractor_staff}
                        name="responsible_contractor_staff"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Mechanical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>On Duty Staff</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.on_duty_staff}
                        name="on_duty_staff"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Depot</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.depot}
                      name="depot"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>{" "}
                {((userdeptList.length === 1 &&
                  userdeptList[0].dept_name === "Electrical") ||
                  userdeptList.length > 1) && (
                  <tr>
                    <th>Supervisor</th>
                    <td>
                      <EditComponent
                        defaultValue={complaint.supervisor}
                        name="supervisor"
                        wetherEditMode={editMode}
                        handleOnChange={handleOnChange}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Action Taken & Closing Remark</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.action_taken_and_closing_remark}
                      name="action_taken_and_closing_remark"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Comment</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.comment}
                      name="comment"
                      wetherEditMode={editMode}
                      handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Created by</th>
                  <td>
                    <EditComponent
                      defaultValue={complaint.created_by}
                      name="created_by"
                      wetherEditMode={false}
                      // handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Created at</th>
                  <td>
                    <EditComponent
                      defaultValue={FormatSimpleDate2(complaint.created_at)}
                      name="created_at"
                      wetherEditMode={false}
                      // handleOnChange={handleOnChange}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <th></th>
                  <td>Auto Calculated values</td>
                </tr>
                <tr>
                  <th>UP/DOWN</th>
                  <td></td>
                </tr>
                <tr>
                  <th>LHB/ICF</th>
                  <td></td>
                </tr>
                <tr>
                  <th>AC/Non AC</th>
                  <td></td>
                </tr>
                <tr>
                  <th>Departure Date from respective station</th>
                  <td></td>
                </tr>
                <tr>
                  <th>Departure Time from respective Staition</th>
                  <td></td>
                </tr>
                <tr>
                  <th>Departure Date and Time</th>
                  <td></td>
                </tr>
                <tr>
                  <th>Complaint Received Time after Train Departure</th>
                  <td></td>
                </tr> */}
                {/* <tr>
                <th>
                  <Button variant="gray" onClick={() => { onClickSaveInEditMode(); handleModalClose() }}>
                    Save
                  </Button></th>
                <td>
                  <Button variant="gray" onClick={() => { handleModalClose(); onClickCloseInEditMode() }}>
                    Close
                  </Button></td>
              </tr> */}
              </tbody>
            </Table>
          }
        </Modal.Body>
        <Modal.Footer>
          {!editMode && (
            <Button
              variant="gray"
              onClick={onClickEditMode}
              disabled={enableBtnST}
            >
              Edit
            </Button>
          )}
          {editMode && (
            <Button
              variant="success"
              onClick={() => {
                onClickSaveInEditMode();
              }}
            >
              Save
            </Button>
          )}
          <Button variant="gray" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const EditComponent = (props) => {
  const { defaultValue, name, handleOnChange, wetherEditMode } = props;

  return (
    <>
      {!wetherEditMode && <>{defaultValue}</>}
      {wetherEditMode && (
        <FormControl
          defaultValue={defaultValue}
          name={name}
          onChange={handleOnChange}
        />
      )}
    </>
  );
};

export default ComplaintModal;
