export const airBrakeItems = [
  {
    asset_l1_dbid: 148,
    asset_l1_name: "AR Branch Pipe",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 149,
    asset_l1_name: "BP Pipe",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 150,
    asset_l1_name: "Control Reservoir",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 151,
    asset_l1_name: "DV",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 152,
    asset_l1_name: "FP Pipe",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const CarpentryItems = [
  {
    asset_l1_dbid: 7,
    asset_l1_name: "Berth",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 11,
    asset_l1_name: "Door",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 153,
    asset_l1_name: "Flooring",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 154,
    asset_l1_name: "Luggage Door",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 155,
    asset_l1_name: "Shutter",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const ElectricalItems = [
  {
    asset_l1_dbid: 156,
    asset_l1_name: "Alternator",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 157,
    asset_l1_name: "Compressor",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 158,
    asset_l1_name: "Invertor",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 159,
    asset_l1_name: "Less Cooling",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 160,
    asset_l1_name: "Pully",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 161,
    asset_l1_name: "Tension Rod",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 162,
    asset_l1_name: "V Belt",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 163,
    asset_l1_name: "WRA",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const MiscellaneousItems = [
  {
    asset_l1_dbid: 164,
    asset_l1_name: "FAC Upgradation",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 165,
    asset_l1_name: "Gas Leakage",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 166,
    asset_l1_name: "Mechanical Work",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 167,
    asset_l1_name: "OMRS",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 168,
    asset_l1_name: "Tiles",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 169,
    asset_l1_name: "Utkrisht",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const PestAndRodentItems = [
  {
    asset_l1_dbid: 170,
    asset_l1_name: "Fumigation",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];
export const PlumbingItems = [
  {
    asset_l1_dbid: 172,
    asset_l1_name: "Commode",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 16,
    asset_l1_name: "Pipeline",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 173,
    asset_l1_name: "Water Tank",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const SuspensionItems = [
  {
    asset_l1_dbid: 174,
    asset_l1_name: "Air Spring",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 175,
    asset_l1_name: "Fiba",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 176,
    asset_l1_name: "Primary Inner Spring",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 177,
    asset_l1_name: "Primary Outer Spring",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 178,
    asset_l1_name: "Rubber Spring",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 179,
    asset_l1_name: "Secondary Inner Spring",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 180,
    asset_l1_name: "Secondary Outer Spring",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 181,
    asset_l1_name: "Secondary Spring",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const TrimmingItems = [
  {
    asset_l1_dbid: 182,
    asset_l1_name: "Seats",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const UnderGearItems = [
  {
    asset_l1_dbid: 44,
    asset_l1_name: "Anchor Link",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 183,
    asset_l1_name: "Anti Roll Bar",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 184,
    asset_l1_name: "Buffer",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 185,
    asset_l1_name: "CBC",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 186,
    asset_l1_name: "Connecting Link",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 187,
    asset_l1_name: "Control Arm",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 188,
    asset_l1_name: "Dashpot",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 189,
    asset_l1_name: "Equalising Stay",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 190,
    asset_l1_name: "Hand Brake",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 191,
    asset_l1_name: "Safety Strap",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 192,
    asset_l1_name: "Screw Coupling",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 193,
    asset_l1_name: "Side Bearer",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 194,
    asset_l1_name: "Traction Center",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 195,
    asset_l1_name: "Trolley Frame",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 196,
    asset_l1_name: "Unusual Sound",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 197,
    asset_l1_name: "Vestibule",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const WheelSetItems = [
  {
    asset_l1_dbid: 198,
    asset_l1_name: "Axle Box",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 199,
    asset_l1_name: "CTRB",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 200,
    asset_l1_name: "Deep Flange",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 201,
    asset_l1_name: "Flat Tyre",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 202,
    asset_l1_name: "Grazing",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 203,
    asset_l1_name: "Grease Oozing",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 204,
    asset_l1_name: "Grooved Wheel",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 205,
    asset_l1_name: "Sharp Flange",
    asset_l1_quantity: 1,
  },

  {
    asset_l1_dbid: 206,
    asset_l1_name: "Shelling",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 207,
    asset_l1_name: "Spring",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 208,
    asset_l1_name: "Thermal Crack",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 171,
    asset_l1_name: "Spread Rim",
    asset_l1_quantity: 1,
  },
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];

export const OtherItems = [
  {
    asset_l1_dbid: 209,
    asset_l1_name: "Other",
    asset_l1_quantity: 1,
  },
];
