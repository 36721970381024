import React from "react";
import { useState, useEffect } from "react";
import { FormGroup, Modal, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { InputGroup } from "@themesberg/react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  faArrowRotateLeft,
  faCheck,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../../../axios";
import { FormatSimpleMonthDate } from "../../../../components/dateformat";
import RakeInfo from "../RakeInfo";

const POHPlanner = () => {
  const [pohData, setPohData] = useState(null);

  const [dataOnTable, updateDataOnTable] = useState({});
  const [coachOptions, updateCoachOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");

  function sortByRDate(a, b) {
    let a_date_to_consider = a.r_date;
    let b_date_to_consider = b.r_date;
    if (a.reduced_r_date) {
      a_date_to_consider = a.reduced_r_date;
    } else if (a.extended_r_date) {
      a_date_to_consider = a.extended_r_date;
    }
    if (b.reduced_r_date) {
      b_date_to_consider = b.reduced_r_date;
    } else if (b.reduced_r_date) {
      b_date_to_consider = a.reduced_r_date;
    }
    if (a_date_to_consider > b_date_to_consider) return -1;
    if (a_date_to_consider == b_date_to_consider) return 0;
    if (a_date_to_consider < b_date_to_consider) return 1;
  }
  useEffect(() => {
    getPOHList();
  }, []);

  const getPOHList = async () => {
    const res = await axiosInstance.get(
      `assets/coaches/?keyword=poh_planner`,
      {}
    );
    const data = res.data;
    console.log(data);
    data.sort(sortByRDate);
    setPohData(data);

    updateDataOnTable(data);

    const tempData = [];
    Object.values(data).forEach((item) => {
      // console.group(item.coach_id);
      if (item.coach_id && !tempData.includes(item.coach_id)) {
        tempData.push(item.coach_id);
      }
      if (item.coach_type && !tempData.includes(item.coach_type)) {
        tempData.push(item.coach_type);
      }
      if (item.coach_status && !tempData.includes(item.coach_status)) {
        tempData.push(item.coach_status);
      }
      if (item.ioh_status && !tempData.includes(item.ioh_status)) {
        tempData.push(item.ioh_status);
      }
      if (
        item.detachment_reason &&
        !tempData.includes(item.detachment_reason)
      ) {
        tempData.push(item.detachment_reason);
      }
    });
    tempData.push("Sick");

    updateCoachOptions(tempData);
  };

  const handleChange = (e) => {
    console.log(e);
    setSelected();
    setTypeAheadValue(e[0]);
  };

  const handleCoachNumberSubmit = (value) => {
    if (value) {
      let filteredData = pohData.filter((item) => item.coach_id === value);
      if (filteredData.length === 0) {
        filteredData = pohData.filter((item) => item.coach_status === value);
      }
      if (filteredData.length === 0) {
        filteredData = pohData.filter((item) => item.ioh_status === value);
      }
      if (filteredData.length === 0) {
        filteredData = pohData.filter((item) => item.coach_type === value);
      }
      if (filteredData.length === 0) {
        filteredData = pohData.filter(
          (item) => item.detachment_reason === value
        );
      }
      updateDataOnTable(filteredData);
    } else {
      console.log(pohData);
      updateDataOnTable(pohData);
    }
  };

  const handleFilterButtonSubmit = (e) => {
    e.preventDefault();
    console.log(typeAheadValue);
    handleCoachNumberSubmit(typeAheadValue);
  };

  const handleUpdate = (updatedCoach) => {
    const tempPohData = pohData.filter(
      (item) => item.coach_dbid !== updatedCoach.coach_dbid
    );
    tempPohData.unshift(updatedCoach);
    setPohData(tempPohData);

    const tempDataonTable = dataOnTable.filter(
      (item) => item.coach_dbid !== updatedCoach.coach_dbid
    );
    tempDataonTable.unshift(updatedCoach);
    console.log(tempDataonTable);
    updateDataOnTable(tempDataonTable);
  };

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>POH Planner</h6>
          {pohData === null && <Spinner animation="border" variant="info" />}
        </Card.Header>{" "}
        <Card.Body>
          <Form onSubmit={handleFilterButtonSubmit}>
            <Row>
              <Col sm="4">
                <Form.Group className="">
                  <InputGroup name="coach_id">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Typeahead
                      id="basic-example"
                      onChange={handleChange}
                      options={coachOptions}
                      placeholder="Enter Coach Number..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCoachNumberSubmit(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm="2">
                <Button variant="outline-gray" className="" type="submit">
                  Lookup
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card className="my-2">
        {pohData && (
          <Table
            // striped
            bordered
            hover
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="text-center">
              <tr>
                <th>#</th>
                <th>Coach Number</th>
                <th>Coach Type</th>
                <th>Train No.</th>
                <th>Release Date</th>
                <th>Reduced Release Date</th>
                <th>Extended Release Date</th>
                <th>Status</th>
                <th> </th>
              </tr>
            </thead>

            <tbody>
              {Object.values(dataOnTable).map((POHcoach, index) => {
                return (
                  <PlanTable
                    key={`poh_${index}`}
                    POHcoach={POHcoach}
                    index={index}
                    handleUpdate={handleUpdate}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </Card>
    </div>
  );
};

const PlanTable = (props) => {
  const { POHcoach, index, handleUpdate } = props;

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <tr>
      <td className="text-center">{index + 1}</td>
      <td className="text-center">{POHcoach.coach_id}</td>
      <td className="text-center">{POHcoach.coach_type}</td>
      <td className="text-center">
        {POHcoach.schedule && <RakeInfo RakeInfoSchedule={POHcoach.schedule} />}
      </td>
      <td className="text-center">
        {POHcoach.r_date && FormatSimpleMonthDate(POHcoach.r_date)}
      </td>
      <td className="text-center">
        {POHcoach.reduced_r_date &&
          FormatSimpleMonthDate(POHcoach.reduced_r_date)}
      </td>
      <td className="text-center">
        {POHcoach.extended_r_date &&
          FormatSimpleMonthDate(POHcoach.extended_r_date)}
      </td>
      <td className="text-center">
        {POHcoach.coach_status === "Sick" && (
          <>
            {POHcoach.coach_status} {POHcoach.detachment_reason}
          </>
        )}
        {POHcoach.coach_status !== "Sick" && <>{POHcoach.coach_status}</>}
      </td>

      <td className="text-center">
        <Button
          variant="outline-gray"
          onClick={handleShow}
          size="sm"
          disabled={
            POHcoach.coach_status === "Marked for Return" ||
            POHcoach.coach_status === "Running"
          }
        >
          Take Action
        </Button>
        {show && (
          <ActionModal
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
            coachID={POHcoach.coach_dbid}
            handleUpdate={handleUpdate}
          />
        )}
      </td>
    </tr>
  );
};

const ActionModal = (props) => {
  const { handleClose, handleShow, show, coachID, handleUpdate } = props;

  const [attachingData, setAttachingData] = useState(null);

  const [trainList, setTrainList] = useState(null);
  const [trainOptions, setTrainOptions] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    getTrainList();
  }, []);

  const getTrainList = async () => {
    const res2 = await axiosInstance.get(`assets/trains/`, {});
    const data = res2.data;
    console.log(data);
    setTrainList(data);

    const tempTrainData = [];
    Object.values(data).forEach((item) => {
      // console.group(item.coach_id);
      tempTrainData.push(item.train_number);
    });

    setTrainOptions(tempTrainData);
  };
  const handleChange2 = (e) => {
    console.log(e);
    setSelected();
    const selectedTrain = trainList.find((item) => item.train_number === e[0]);
    console.log(selectedTrain);
    setAttachingData({
      ...attachingData,
      ["train_dbid"]: selectedTrain.train_dbid,
    });
  };

  const onValueChange3 = (e) => {
    setAttachingData({ ...attachingData, [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  const apiPOHData = async () => {
    if (attachingData) {
      try {
        const { coach_status, return_date, train_dbid } = attachingData;
        if (
          coach_status !== undefined &&
          return_date !== undefined &&
          train_dbid !== undefined
        ) {
          const res = await axiosInstance.patch(
            `assets/coaches/${coachID}/`,
            attachingData
          );

          console.log(res.data);
          handleClose();
          handleUpdate(res.data);
        }
      } catch (error) {
        console.log("data not filled");
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
      >
        <Modal.Header>
          <Modal.Title>Action</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row className="my-3">
              <Form.Group>
                <Col sm="12">
                  <InputGroup name="coach_id">
                    <InputGroup.Text>
                      {/* <FontAwesomeIcon icon={faSearch} /> */}
                      Train No.
                    </InputGroup.Text>
                    <Typeahead
                      id="basic-example"
                      onChange={(e) => {
                        handleChange2(e);
                      }}
                      options={trainOptions}
                      disabled={
                        attachingData?.train_dbid
                          ? attachingData.train_dbid !== null
                          : false
                      }
                      placeholder="Enter Train Number..."
                      selected={selected}
                    ></Typeahead>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Col></Col>
            </Row>

            <Row className="my-2">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Col sm="12">
                  <Form.Select
                    required
                    name="coach_status"
                    onChange={onValueChange3}
                  >
                    <option key={`plan-option-select`}>---Select---</option>
                    <option
                      key={`plan-option-return`}
                      value="Marked for Return"
                    >
                      Marked for Return
                    </option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <FormGroup>
                <Col sm="12">
                  <FormControl
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    name="return_date"
                    // value={returnDate}
                    type="date"
                    onChange={(e) => onValueChange3(e)}
                  />
                </Col>
              </FormGroup>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            // disabled={}
            variant="primary"
            onClick={() => {
              apiPOHData();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default POHPlanner;