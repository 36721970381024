import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import { PostFileStaffAPI } from '../../../api/StaffApi';

const UploadStaffFile = (props) => {

  const { handleCloseAddFile, handleNewRespData } = props;

  const [file, setFile] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleChange = (e) => {
    const document = e.target.files[0];

    if (document) {
      setFile(document);
      console.log(document);
    } else {
      setFile(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    callPostFileStaffApi();
  };

  const callPostFileStaffApi = async () => {
    try {
      if (file) {
        let newformData = new FormData();
        newformData.append("document", file, file.name);
        console.log(file.name);
        const res = await PostFileStaffAPI(newformData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
            timeout: 1 * 120000,
          });
        handleNewRespData(res.data)
        console.log(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Modal show={true} >
      <Modal.Header >

        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="file"
          accept=".xls,.xlsx"
          onChange={handleChange}
        />
        {/* {showSpinner && (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="grow" variant="info" size="md" />
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="warning" />
          </div>
        )} */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAddFile}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Upload File
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UploadStaffFile