import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { DeleteStaffAPI } from '../../../api/StaffApi';

const DeleteStaff = (props) => {
    const { handleCloseDeleteModal,handleDeleteDataOnList, delStaffData } = props;

    const [deleteData, setDeleteData] = useState(delStaffData ? delStaffData : null)
    const handleConfirmDelete = () => {
        callDeleteStaffAPI();
    }
    const callDeleteStaffAPI = async () => {
        const idStaff = deleteData.staff_dbid
        try {
            const res = await DeleteStaffAPI(idStaff);
            if(res.status===200){

                handleDeleteDataOnList(idStaff);
            }
        } catch (error) {

            console.log(error)
        }
    }
    return (
        <Modal show={true} >
            <Modal.Header >

                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Name : {deleteData.full_name}</h4>
                <h4>Designation : {deleteData.designation}</h4>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDeleteModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleConfirmDelete}>
                    Confirm Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteStaff