import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Table, Form } from "react-bootstrap";
import { axiosInstance } from "../../../../axios";
import { useNavigate } from "react-router";
import { URLs } from "../../../../routes";

const ViewRakes = (props) => {
  const { onViewRakesShow, onViewRakesClose, selectedTrainData } = props;
  // console.log("rake table");

  const [rakeData, setRakeData] = useState(null);
  useEffect(() => {
    getRakeData();
  }, [selectedTrainData]);
  const getRakeData = async () => {
    try {
      const res = await axiosInstance.get(
        `assets/trains/${selectedTrainData.train_dbid}/`,
        {}
      );
      // console.log(res.data);
      const responseData = res.data;
      setRakeData(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        show={onViewRakesShow}
        onHide={onViewRakesClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedTrainData.train_number} : Rakes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            striped
            bordered
            hover
            responsive
            className="text-center align-middle"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Rake Name </th>
                <th>Train No.</th>
                <th>Rake Type</th>
                <th>No. of Coaches</th>
              </tr>
            </thead>
            <tbody>
              {rakeData &&
                rakeData.rakes.map((rake, index) => {
                  return (
                    <RakeRow rake={rake} index={index} key={rake.rake_name} />
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="gray" onClick={onViewRakesClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const RakeRow = (props) => {
  const { index, rake } = props;
  let navigate = useNavigate();
  const handleViewRake = (e) => {
    // console.log(rake);
    navigate(URLs.RakeManagement.path, {
      state: {
        rake_name: rake.rake_name,
        rake_dbid: rake.rake_dbid,
        selected_coach_dbid: null,
      },
    });
  };

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{rake.rake_name}</td>
        <td>{rake.train_number}</td>
        <td>{rake.rake_config?.type}</td>
        <td>{rake.coaches.length}</td>
        <td>
          <Button variant="outline-gray" size="sm" onClick={handleViewRake}>
            View Rake
          </Button>
        </td>
      </tr>
    </>
  );
};

export default ViewRakes;
