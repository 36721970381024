import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../axios";

const AddRake = (props) => {
  const { handleRakeAdditionClose, handleRakeAdditionSubmit } = props;
  const [trainOptions, updateTrainOptions] = useState(null);
  const [rakeData, updateRakeData] = useState({});
  const handleChange = (e) => {
    console.log(e.target.name);
    updateRakeData({ ...rakeData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axiosInstance
      .get(`assets/trains/`, {})
      .then((res) => {
        const data = res.data;
        updateTrainOptions(data);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRakeSubmit = (e) => {
    e.preventDefault();
    if (rakeData?.train_number) {
      axiosInstance
        .post(`assets/rakes/`, rakeData)
        .then((res) => {
          const data = res.data;
          handleRakeAdditionSubmit(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleRakeAdditionClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
      >
        <Form onSubmit={handleRakeSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Rake</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm="4" className="my-2">
                <Form.Label>Rake Name</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Control
                  onChange={handleChange}
                  name="rake_name"
                  required
                />
              </Col>
              <Col sm="4" className="my-2">
                <Form.Label>Train</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Select onChange={handleChange} name="train_number">
                  <option>Select Train</option>
                  {trainOptions &&
                    Object.values(trainOptions).map((train, index) => {
                      return (
                        <option
                          value={train.train_dbid}
                          key={`coach_type_${index}`}
                        >
                          {train.train_number}
                        </option>
                      );
                    })}
                </Form.Select>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="gray" onClick={handleRakeAdditionClose}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Add Rake
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddRake;
