import React, { useState } from "react";
import {
  Accordion,
  Card,
  Form,
  Modal,
  Button,
  Row,
  Table,
  Col,
} from "react-bootstrap";

const ConfirmationModal = (props) => {
  const { markingData, handleClose, handleSubmit } = props;
  const [actionData, updateActionData] = useState(markingData);

  const handleSubmitModal = (e) => {
    e.preventDefault();
    console.log(actionData);
    if (actionData) {
      handleSubmit(actionData);
    }
  };

  const handleRowUpdate = (rowLabel, rowData) => {
    updateActionData({ ...actionData, [rowLabel]: rowData });
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      show={true}
      onHide={handleClose}
    >
      <Form onSubmit={handleSubmitModal}>
        <Modal.Header closeButton>
          <Modal.Title>Take Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Issue</th>
                  <th>Take Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(markingData).map((itemKey, index) => {
                  return (
                    <IssueRow
                      label={itemKey}
                      handleRowUpdate={handleRowUpdate}
                      itemData={markingData[itemKey]}
                      index={index}
                      key={index}
                    />
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ConfirmationModal;

const IssueRow = (props) => {
  const { itemData, label, index, handleRowUpdate } = props;
  const [rowData, updateRowData] = useState(itemData);
  const handleChange = (e) => {
    updateRowData({ ...rowData, [e.target.name]: e.target.value });
    handleRowUpdate(label, { ...rowData, [e.target.name]: e.target.value });
    console.log({ ...rowData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{label}</td>
        <td>
          <Form.Select
            name="response"
            onChange={handleChange}
            aria-label="Issue"
          >
            <option value="NOT OK">Not Ok</option>
            <option value="Damage">Damage</option>
            <option value="Missing">Missing</option>
          </Form.Select>
        </td>
        <td>
          <Form.Select
            name="action"
            onChange={handleChange}
            aria-label="Action"
            required
          >
            <option value=""></option>
            <option value="Replaced">Replaced</option>
            <option value="Repaired">Repaired</option>
          </Form.Select>
        </td>
      </tr>
    </>
  );
};
