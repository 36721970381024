import React from "react";
import { useState, useEffect } from "react";
import { Button, Table, Form, Modal, Spinner } from "react-bootstrap";
import UserModal from "./UserModal";
import { Card } from "react-bootstrap";
import { axiosInstance } from "../../../axios";
import DeleteModal from "./DeleteModal";
import EditUserModal from "./EditUserModal";
import {
  faCross,
  faEdit,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserManagement = () => {
  const [userList, setUserList] = useState(null);
  const [userId2, setUserId2] = useState(null);
  const [userNameToDelete, setNameToDelete] = useState(null);
  const [userToUpdate, setUserToUpdate] = useState(null);

  // post request
  const [showCreateForm, setShowCreateForm] = useState(false);
  const handleCreateFormClose = () => setShowCreateForm(false);
  const handleCreateFormShow = () => setShowCreateForm(true);

  // update request
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const updateFormClose = () => setShowUpdateForm(false);
  const updateFormShow = (u) => {
    setShowUpdateForm(true);
    setUserToUpdate(u);
    console.log(u);
  };

  // delete request
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const show2DeleteModal = (userId, userName) => {
    setShowDeleteModal(true);
    setUserId2(userId);
    setNameToDelete(userName);
  };

  const handleDeleteUser = (userId2) => {
    const updatedList = userList.filter((item) => item.id !== userId2);
    setUserList(updatedList);
  };

  const handleUpdateUser = (userData) => {
    // const updatedList3 = userList.filter((item)=>item.id=)
    const givenUser = userList.find((item) => item.id === userData.id);
    const index = userList.indexOf(givenUser);
    let tempList = userList;
    tempList.splice(index, 1, userData);
    console.log(tempList);
    setUserList(tempList);
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async () => {
    const res = await axiosInstance.get(`auth/user/`, {});
    console.log(res.data);
    setUserList(res.data);
  };

  const showAddedUserOnTop = (newData) => {
    let tempUsers = userList;
    tempUsers.unshift(newData);
    setUserList(tempUsers);
  };

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>User Management</h6>
          <Button onClick={handleCreateFormShow} variant="outline-tertiary">
            Create New User
          </Button>
        </Card.Header>
      </Card>
      <Card>
        {userList === null && (
          <Card.Body
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              style={{
                width: "100px",
                height: "100px",
              }}
              animation="border"
              role="status"
            ></Spinner>
          </Card.Body>
        )}
        <Card.Body>
          <Table className="text-center" striped bordered hover responsive>
            <thead>
              <tr>
                <th>User No.</th>
                <th>Username</th>
                <th>Full Name</th>
                {/* <th>Password</th> */}
                <th>Email</th>
                <th>Mobile</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userList &&
                userList.map((u, index) => {
                  return (
                    <tr key={u.id} className="my-3">
                      <th>{index + 1}</th>
                      <th>{u.user_name}</th>
                      <th>{u.full_name}</th>
                      {/* <th>Password</th> */}
                      <th>{u.email}</th>
                      <th>{u.mobile_num}</th>
                      <th>
                        <>
                          <Button
                            onClick={() => updateFormShow(u)}
                            variant="outline-gray"
                            className="mx-2"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>

                          <Button
                            onClick={() => show2DeleteModal(u.id, u.user_name)}
                            variant="outline-gray"
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </Button>
                        </>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {showUpdateForm === true && (
        <EditUserModal
          showModal={showUpdateForm}
          handleClose={updateFormClose}
          userToUpdate={userToUpdate}
          handleUpdateUser={handleUpdateUser}
        />
      )}

      {showCreateForm === true && (
        <UserModal
          title="Create New User"
          showModal={showCreateForm}
          handleClose={handleCreateFormClose}
          showAddedTrainOnTop={showAddedUserOnTop}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          closeDeleteModal={closeDeleteModal}
          userId2={userId2}
          handleDeleteUser={handleDeleteUser}
          userNameToDelete={userNameToDelete}
        />
      )}
    </div>
  );
};

export default UserManagement;
