import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  InputGroup,
  Col,
  Button,
  Form,
  Row,
  Card,
  Table,
  Modal,
  FormControl,
} from "react-bootstrap";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../../../axios";
import { Typeahead } from "react-bootstrap-typeahead";
import { FormatStringDate } from "../../../../components/dateformat";

const DragCoach = (props) => {
  const { rakeID, coaches, handleCoachInRakes, highlightedCoach } = props;
  const [coachList, updateCoachList] = useState(coaches);
  // maintains current list of coaches
  const [currentCoachesList, updateCurrentCoachesList] = useState({});
  //current coach list is sent to addCoach functio, to filter out, which coach is already ther in rake
  const [showAddCoach, setShowAddCoach] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedCoach, updateSelectedCoach] = useState(null);

  useEffect(() => {
    updateCoachList(coaches);
    handleUpdateCurrentCoachList(coaches);
  }, [coaches]);

  const handleAddCoachClose = () => setShowAddCoach(false);
  const handleCoachAddition = (newCoach) => {
    handleAddCoachClose();
    newCoach.position_in_rake = coachList.length + 1;
    newCoach.coach_status = "Running";
    newCoach.location = null;
    newCoach["connected_rake_id"] = rakeID;
    const tempCoaches = coachList;
    tempCoaches.push(newCoach);
    console.log(tempCoaches);
    updateCoachList(tempCoaches);
    handleUpdateCurrentCoachList(tempCoaches);
    handleCoachInRakes(tempCoaches);
  };

  const handleUpdateCurrentCoachList = (coachListData) => {
    const tempData = [];
    coachListData.forEach((item) => {
      tempData.push(item.coach_id);
    });
    updateCurrentCoachesList(tempData);
  };
  const handleRemoveCoachClick = (coach) => {
    console.log(coach);
    setShowRemoveModal(true);
    updateSelectedCoach(coach);
  };
  const handleRemoveCoachClose = () => setShowRemoveModal(false);
  const handleRemoveCoachSubmit = (coach_data, reason, location = null) => {
    let date = FormatStringDate(new Date());
    let patchData = {};
    console.log(coach_data, reason);
    handleRemoveCoachClose();
    if (reason === "rake_adjustment") {
      patchData["coach_status"] = "Spare";
      patchData["detachment_reason"] = "Rake Adjustments";
      // patchData["connected_rake_remove"] = true;
      patchData["connected_rake_remove_by_id"] = rakeID;
      patchData["location"] = location;
      patchData["coach_depot"] = "";
    } else if (reason === "sick_for_ioh") {
      patchData["sick_marking_date"] = date;
      patchData["connected_rake_remove"] = true;
      patchData["ioh_status"] =
        coach_data.ioh_status === "Planned / Under Diagnosis"
          ? "Planned / Under Diagnosis"
          : null;
      patchData["coach_status"] = "Sick";
      patchData["detachment_reason"] = "IOH";
      patchData["location"] = location;
      patchData["coach_depot"] = "";
    } else if (reason === "sick_for_poh") {
      patchData["sick_marking_date"] = date;
      patchData["connected_rake_remove"] = true;
      patchData["ioh_status"] =
        coach_data.ioh_status === "Planned / Under Diagnosis"
          ? "Planned / Under Diagnosis"
          : null;
      patchData["coach_status"] = "Sick";
      patchData["detachment_reason"] = "POH";
      patchData["location"] = location;
      patchData["coach_depot"] = "";
    } else if (reason === "enroute_detachment") {
      // patchData["sick_marking_date"] = date;
      patchData["connected_rake_remove"] = true;
      patchData["coach_status"] = "At Foreign Railway";
      patchData["detachment_reason"] = "Enroute Detachment";
      patchData["location"] = location;
      patchData["coach_depot"] = "";
    } else if (reason === "secondary_end_detachment") {
      // patchData["sick_marking_date"] = date;
      patchData["connected_rake_remove"] = true;
      patchData["coach_status"] = "At Foreign Railway";
      patchData["detachment_reason"] = "Enroute Detachment";
      patchData["location"] = location;
      patchData["coach_depot"] = "";
    }
    if (coach_data.coach_id) {
      axiosInstance
        .patch(`assets/coaches/${coach_data.coach_dbid}/`, patchData)
        .then((res) => {
          const data = res.data;
          let tempCoachList = coachList;
          tempCoachList = tempCoachList.filter(
            (coach) => coach.coach_id !== coach_data.coach_id
          );
          updateCoachList(tempCoachList);
          handleUpdateCurrentCoachList(tempCoachList);
          handleCoachInRakes(tempCoachList);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    console.log(result);
    const newItems = [...coachList];
    let [removed] = newItems.splice(result.source.index, 1);
    console.log(removed);
    // removed = { ...removed, position_in_rake: result.destination.index + 1 };
    newItems.splice(result.destination.index, 0, removed);
    console.log(newItems);
    newItems.forEach((item, index) => {
      item.position_in_rake = index + 1;
    });
    // console.log(newItems);
    updateCoachList(newItems);
    handleCoachInRakes(newItems);
  }

  return (
    <>
      <Table bordered className="table-centered rounded mb-0 align-middle">
        <thead className="text-center">
          <tr>
            <th>Coach ID</th>
            <th>Type</th>
            <th>Position</th>
            <th></th>
          </tr>
        </thead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <tbody
                className="text-center"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {coachList.map((coach, index) => (
                  <Draggable draggableId={`0${index}`} index={index}>
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={
                          highlightedCoach === coach.coach_dbid
                            ? "bg-light"
                            : ""
                        }
                      >
                        <CoachElement
                          coach={coach}
                          handleRemoveCoachClick={handleRemoveCoachClick}
                        />
                      </tr>
                    )}
                  </Draggable>
                ))}
                <tr>
                  <td>
                    <Button
                      variant="outline-gray"
                      onClick={(e) => setShowAddCoach(true)}
                    >
                      Add Coach
                    </Button>
                  </td>
                </tr>
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      {showAddCoach && (
        <AddCoachToRake
          currentCoaches={currentCoachesList}
          handleAddCoachClose={handleAddCoachClose}
          handleCoachAddition={handleCoachAddition}
        />
      )}
      {showRemoveModal && (
        <RemoveCoachFromRake
          handleRemoveCoachClose={handleRemoveCoachClose}
          selectedCoach={selectedCoach}
          handleRemoveCoachSubmit={handleRemoveCoachSubmit}
        />
      )}
    </>
  );
};

const CoachElement = (props) => {
  const { coach, handleRemoveCoachClick } = props;
  const handleClick = (e) => {
    console.log("click");
    handleRemoveCoachClick(coach);
  };
  return (
    <>
      <td className="text-info fw-bold">{coach.coach_id}</td>
      <td>{coach.coach_type}</td>
      <td>{coach.position_in_rake}</td>
      <td>
        <Button size="sm" variant="outline-gray" onClick={handleClick}>
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </td>
    </>
  );
};

const RemoveCoachFromRake = (props) => {
  const { handleRemoveCoachClose, selectedCoach, handleRemoveCoachSubmit } =
    props;
  // console.log(selectedCoach);
  const [removalAction, updateRemovalAction] = useState(null);
  const [removalLocation, updateRemovalLocation] = useState(null);

  const handleChange = (e) => {
    // console.log(e.target.value);
    updateRemovalAction(e.target.value);
  };
  const handleLocationChange = (e) => {
    // console.log(e.target.value);
    updateRemovalLocation(e.target.value.toUpperCase());
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (removalAction) {
      console.log(removalAction);
      handleRemoveCoachSubmit(selectedCoach, removalAction, removalLocation);
    }
  };
  return (
    <Modal
      show={true}
      onHide={handleRemoveCoachClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCoach.coach_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="my-3">
            <Col sm="3">
              <Form.Label>Action</Form.Label>
            </Col>
            <Col sm="9">
              <Form.Select
                aria-label="Default select example"
                onChange={handleChange}
                name="action"
                required
              >
                <option>Open this select menu</option>
                <option value="rake_adjustment">Rake Adjustment</option>
                <option value="sick_for_ioh">Sick for IOH</option>
                <option value="sick_for_poh">Sick for POH</option>
                <option value="enroute_detachment">Enroute Detachment</option>
                <option value="secondary_end_detachment">
                  Secondary End Detachment
                </option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm="3">
              <Form.Label>Current Location</Form.Label>
            </Col>
            <Col sm="9">
              <FormControl
                placeholder="Enter Location"
                aria-label="location"
                aria-describedby="basic-addon1"
                onChange={handleLocationChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gray" onClick={handleRemoveCoachClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleSubmit}>
            Detach Coach
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const AddCoachToRake = (props) => {
  const { currentCoaches, handleAddCoachClose, handleCoachAddition } = props;
  const [coachInfo, updateCoachInfo] = useState(null);
  const [coachID, setCoachID] = useState(null);
  const [coachOptions, updateCoachOptions] = useState([]);
  const [selectedCoachData, updateSelectedCoachData] = useState(null);

  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");
  // console.log(currentCoaches);

  useEffect(() => {
    getCoachList();
  }, []);

  const handleSubmit = () => {
    // console.log(selectedCoachData);
    handleCoachAddition(selectedCoachData);
  };

  const getCoachList = async () => {
    try {
      const res = await axiosInstance.get(`assets/coaches/`, {});
      // console.log(res.data);
      updateCoachInfo(res.data);
      // console.log(res.data);
      const tempCoachData = [];
      Object.values(res.data).forEach((item) => {
        // console.group(item.coach_id);
        if (!currentCoaches.includes(item.coach_id)) {
          if (
            item.coach_status === "Spare" ||
            item.coach_status === "Not Specified" ||
            item.coach_status === "Running"
          ) {
            tempCoachData.push(item.coach_id);
          }
        }
      });
      updateCoachOptions(tempCoachData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    // console.log(e);
    setSelected();
    setTypeAheadValue(e[0]);
    setCoachID(null);
  };
  const handleFilterButtonSubmit = (e) => {
    e.preventDefault();
    // console.log(typeAheadValue);
    handleCoachNumberSubmit(typeAheadValue);
  };
  const handleCoachNumberSubmit = (coachID) => {
    setCoachID(coachID);
    const tempSelectedCoachData = coachInfo.find(
      (item) => item.coach_id === coachID
    );
    updateSelectedCoachData(tempSelectedCoachData);
  };

  return (
    <Modal
      show={true}
      onHide={handleAddCoachClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>Add Coach to Rake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="8">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>

                <Typeahead
                  id="find-coach"
                  onChange={handleChange}
                  options={coachOptions}
                  placeholder="Enter Coach Number..."
                  selected={selected}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleCoachNumberSubmit(e.target.value);
                    }
                  }}
                ></Typeahead>
              </InputGroup>
            </Col>

            <Col xs="2">
              <Button
                variant="outline-gray"
                className=""
                onClick={handleFilterButtonSubmit}
              >
                View
              </Button>
            </Col>
          </Row>
          {selectedCoachData && (
            <Row>
              <Table responsive="sm">
                <tbody>
                  <tr>
                    <th>Coach ID</th>
                    <td>{selectedCoachData.coach_id}</td>
                  </tr>
                  <tr>
                    <th>Coach Type</th>
                    <td>{selectedCoachData.coach_type}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{selectedCoachData.coach_status}</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gray">Close</Button>
          {coachID && (
            <Button variant="success" onClick={handleSubmit}>
              Add
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DragCoach;
