import React from "react";
import { Ratio } from "react-bootstrap";
const RailMadadDashboard = () => {
  // console.log(process.env.REACT_APP_SUPERSET_URL);
  return (
    <>
      <Ratio aspectRatio={"1x1"} className="d-none d-sm-block">
        <iframe
          // allowfullscreen
          src={`${process.env.REACT_APP_DASHBOARD_RAIL_MADAD}`}
          height="100%"
          width="100%"
          title="Dashboard"
        ></iframe>
      </Ratio>

      {/* <Ratio
        className="d-block d-sm-none"
        style={{ height: "100vh", width: "99vw" }}
        aspectRatio={3 / 4}
      >
        <iframe
          src={`${process.env.REACT_APP_SUPERSET_URL_MOBILE}`}
          height="100%"
          width="100%"
          title="Dashboard"
        ></iframe>
      </Ratio> */}
    </>
  );
};

export default RailMadadDashboard;
