import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Row, Col } from 'react-bootstrap'
import { axiosInstance } from '../../../axios';

const UserModal = (props) => {
    const { title, showModal, handleClose, showAddedTrainOnTop } = props;

    const [newUser, setNewUser] = useState(null);

    const onValueChange = (e) => {
        console.log(e.target.value)
        setNewUser({ ...newUser, [e.target.name]: e.target.value })

    }

    const handleCreateUser = async (e) => {
        try {
            e.preventDefault();
            const res = await axiosInstance.post(`auth/register/`, newUser)
            showAddedTrainOnTop(res.data);
            handleClose();

        } catch (error) {
            console.log(error)
        }

    }



    return (
        <>
            <Modal show={true} onHide={handleClose} centered aria-labelledby="contained-modal-title-vcenter" >
                <Form onSubmit={handleCreateUser} >
                    <Modal.Header closeButton>
                        <Modal.Title><h4>{title}</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row className="my-3" >
                            <Col sm="3">
                                <Form.Label>Username</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Control onChange={onValueChange} name='user_name' required type="text" placeholder="Username" />
                            </Col>
                        </Row>
                        <Row className="my-3" >
                            <Col sm="3">
                                <Form.Label>Email</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Control onChange={onValueChange} name='email' type="email" placeholder="Enter email" />
                            </Col>
                        </Row>
                        <Row className="my-3" >
                            <Col sm="3">
                                <Form.Label>Password</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Control onChange={onValueChange} name='password' required type="password" placeholder="New Password" />
                            </Col>
                        </Row>
                        <Row className="my-3" >
                            <Col sm="3">
                                <Form.Label>Full Name</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Control onChange={onValueChange} name="full_name" type="text" placeholder="Full Name" />
                            </Col>
                        </Row>
                        <Row className="my-3" >
                            <Col sm="3">
                                <Form.Label>Mobile</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Control onChange={onValueChange} name='mobile_num' type="number" placeholder="Mobile" />
                            </Col>
                        </Row>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            {title}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default UserModal;