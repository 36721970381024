import "./css/styles.css";
import { useNavigate } from "react-router-dom";
import { URLs } from "../routes";
import logo from "./assets/img/logo.png";
import portfolio1 from "./assets/img/portfolio/1.jpg";
import portfolio2 from "./assets/img/portfolio/2.jpg";
import portfolio3 from "./assets/img/portfolio/3.jpg";
import portfolio4 from "./assets/img/portfolio/4.jpg";
import portfolio5 from "./assets/img/portfolio/5.jpg";
import portfolio6 from "./assets/img/portfolio/6.jpg";
import portfolio7 from "./assets/img/portfolio/7.jpg";
import portfolio8 from "./assets/img/portfolio/8.jpg";
import portfolio9 from "./assets/img/portfolio/9.jpg";
import team1 from "./assets/img/team/1.jpg";
import team2 from "./assets/img/team/2.jpg";
import team3 from "./assets/img/team/3.jpg";
import close_icon from "./assets/img/close-icon.svg";

import { Link } from "react-scroll";

export const WebPage = (props) => {
  let navigate = useNavigate();
  return (
    <div id="page-top">
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="mainNav"
      >
        <div className="container">
          {/* <a className="navbar-brand">
            <img src={logo} alt="..." />
          </a> */}
          <Link activeClass="active" to="top" spy={true}>
            <img src={logo} alt="..." />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            // data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars ms-1"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
              {/* <li className="nav-item">
                <a className="nav-link">Features</a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link">Initiatives</a>
              </li>
              <li className="nav-item">
                <a className="nav-link">Team</a>
              </li> */}

              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="features"
                  spy={true}
                  // smooth={true}
                >
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="initiatives"
                  spy={true}
                  // smooth={true}
                >
                  Initiatives
                </Link>
              </li>
              <li className="nav-item">
                <Link activeClass="active" to="teams" spy={true}>
                  Teams
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="loginFooter"
                  spy={true}
                  // smooth={true}
                >
                  Login
                </Link>
              </li>

              {/* <li className="nav-item">
                <a className="nav-link"
                  onClick={(event) => { navigate(URLs.Signin.path); }}>Login</a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <header id="top" className="masthead">
        <div className="container">
          <div className="masthead-subheading">Depot Management Solution</div>
          <div className="masthead-heading text-uppercase">
            Welcome To Rail Drishti
          </div>
          <a
            className="btn btn-primary btn-xl text-uppercase"
            onClick={(e) => {
              navigate(URLs.Signin.path);
            }}
          >
            Login
          </a>
        </div>
      </header>
      <section className="page-section" id="services">
        <div className="container">
          <div id="features" className="text-center">
            <h2 className="section-heading text-uppercase">Features</h2>
            <h3 className="section-subheading text-muted">
              The cutting edge technology platform to digitize the train and
              coach maintenance in Depots. The Mechanical Engineering wing at
              Pune Division of Central Railways (Central Railways) has embarked
              on digital transformation and automation journey. Under this
              initiative, various functions and processes at Coach Maintenance
              Complex at Pune, Miraj and Kolhapur are being automated.
            </h3>
          </div>
          <div className="row text-center">
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">DIGITIZATION</h4>
              <p className="text-muted">
                Reduce paper-based records and enable data analytics. The
                complete data records will be available in digitized format as
                against paper based entries today. The digital records enable to
                team to access data from any place and any time. The data can
                also be downloaded in various formats for further use and
                analysis. In the long run this data will enable analytics and
                preventive activity planning.
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-laptop fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">AUTOMATED MIS</h4>
              <p className="text-muted">
                Save reporting time and improve accuracy Various reports are
                PCDO which are used by Management team for gather information,
                activity tracking and corrective action planning will become
                automated, and these reports will have drill down facility to
                reach to the origin of action, defect or information.
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">REALTIME VISIBILITY</h4>
              <p className="text-muted">
                Operational parameters and throughput The digitization efforts
                made at PCDO will enable data availability on cloud server in an
                on-demand format. Various levels of management will be able to
                view, edit and analyze data in at any time of the day. Since the
                data is online on cloud server and the teams will be entering
                data into the system rather than on paper, the data will be
                updated and made available in real-time.
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">ACTIONABLE INSIGHTS</h4>
              <p className="text-muted">
                Make informed and timely decisions Automated report will get
                updated real-time and will also showcase exceptions, prompts and
                problems that are occurring frequently. With click of a button
                the data related to commonly occurring problems can be accessed
                and root cause analysis can be conducted.
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">DUPLICATED EFFORTS</h4>
              <p className="text-muted">
                Reduced rework and redeployment of free resources Various levels
                of staff members are involved right from data recording to
                reporting and analysis. For each reporting and analysis, the
                data format may change for analysis and this leads to multiple
                time data reporting and mapping. This creates issues like data
                sanctity, accuracy, human errors etc. Such errors and
                duplication of efforts will be avoided with this digitation
                initiative.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="page-section bg-light" id="portfolio">
        <div className="container">
          <div id="initiatives" className="text-center">
            <h2 className="section-heading text-uppercase">Initiatives</h2>
            <h3 className="section-subheading text-muted">
              Rail Drishti Activities
            </h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal1"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio1} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">
                    Rangoli Competition
                  </div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 01
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal2"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio2} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">
                    Rangoli Competition
                  </div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 02
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal3"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio3} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">
                    Rangoli Competition
                  </div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 03
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal4"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio4} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">Safety Audit</div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 01
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal5"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio5} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">Safety Audit</div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 02
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal6"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio6} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">Safety Audit</div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 03
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal6"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio7} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">Women's Day</div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 01
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal6"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio8} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">Women's Day</div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 02
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  //   href="#portfolioModal6"
                >
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div>
                  <img className="img-fluid" src={portfolio9} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">Women's Day</div>
                  {/* <div className="portfolio-caption-subheading text-muted">
                    Image 03
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section bg-light" id="team">
        <div id="teams" className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">Amazing Team</h2>
            <h3 className="section-subheading text-muted">
              Meet Our Rail Drishti Leadership Team
            </h3>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="team-member">
                <img className="mx-auto rounded-circle" src={team1} alt="..." />
                <h4>Vijay Dhadas</h4>
                <p className="text-muted">SRDME</p>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="team-member">
                <img className="mx-auto rounded-circle" src={team2} alt="..." />
                <h4>Rahul Garg</h4>
                <p className="text-muted">CDO</p>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="team-member">
                <img className="mx-auto rounded-circle" src={team3} alt="..." />
                <h4>Harsh Tripathi</h4>
                <p className="text-muted">ACDO</p>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-dark btn-social mx-2">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="contact">
        <div id="loginFooter" className="container">
          <div className="text-center">
            {/* <h2 className="section-heading text-uppercase">Login</h2> */}
            <a
              className="btn btn-primary btn-xl text-uppercase"
              onClick={(e) => {
                navigate(URLs.Signin.path);
              }}
            >
              Login
            </a>
            <h3 className="section-subheading text-muted">
              Pune, Miraj and Kolhapur are the pioneers to adapt this new
              platform
            </h3>
          </div>
        </div>
      </section>

      <footer className="footer py-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 text-lg-start">
              Copyright &copy; Your Website 2021
            </div>
            <div className="col-lg-4 my-3 my-lg-0">
              <a className="btn btn-dark btn-social mx-2">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-dark btn-social mx-2">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-dark btn-social mx-2">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            <div className="col-lg-4 text-lg-end">
              <a className="link-dark text-decoration-none me-3">
                Privacy Policy
              </a>
              <a className="link-dark text-decoration-none">Terms of Use</a>
            </div>
          </div>
        </div>
      </footer>

      <div
        className="portfolio-modal modal fade"
        id="portfolioModal1"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-bs-dismiss="modal">
              <img src={close_icon} alt="Close modal" />
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="modal-body">
                    <h2 className="text-uppercase">Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src={portfolio1}
                      alt="..."
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>
                        <strong>Client:</strong>
                        Threads
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Illustration
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary btn-xl text-uppercase"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      <i className="fas fa-times me-1"></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal2"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-bs-dismiss="modal">
              <img src={close_icon} alt="Close modal" />
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="modal-body">
                    <h2 className="text-uppercase">Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src={portfolio2}
                      alt="..."
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>
                        <strong>Client:</strong>
                        Explore
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Graphic Design
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary btn-xl text-uppercase"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      <i className="fas fa-times me-1"></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal3"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-bs-dismiss="modal">
              <img src={close_icon} alt="Close modal" />
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="modal-body">
                    <h2 className="text-uppercase">Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src={portfolio3}
                      alt="..."
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>
                        <strong>Client:</strong>
                        Finish
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Identity
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary btn-xl text-uppercase"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      <i className="fas fa-times me-1"></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal4"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-bs-dismiss="modal">
              <img src={close_icon} alt="Close modal" />
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="modal-body">
                    <h2 className="text-uppercase">Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src={portfolio4}
                      alt="..."
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>
                        <strong>Client:</strong>
                        Lines
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Branding
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary btn-xl text-uppercase"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      <i className="fas fa-times me-1"></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal5"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-bs-dismiss="modal">
              <img src={close_icon} alt="Close modal" />
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="modal-body">
                    <h2 className="text-uppercase">Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src={portfolio5}
                      alt="..."
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>
                        <strong>Client:</strong>
                        Southwest
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Website Design
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary btn-xl text-uppercase"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      <i className="fas fa-times me-1"></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal6"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-bs-dismiss="modal">
              <img src={close_icon} alt="Close modal" />
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="modal-body">
                    <h2 className="text-uppercase">Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src={portfolio6}
                      alt="..."
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>
                        <strong>Client:</strong>
                        Window
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Photography
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary btn-xl text-uppercase"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      <i className="fas fa-times me-1"></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

      <script src="js/scripts.js"></script>
      <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
    </div>
  );
};
