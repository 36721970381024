import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from "@themesberg/react-bootstrap";
const Footer = (props) => {

  const currentYear = moment().get("year");

  return (
    <div>
      <footer className="footer section py-5 main-footer">

        <Row>
          <Col xs={12} lg={6} className="mb-4 mb-lg-0">
            {/* <p className="mb-0 text-center text-xl-left">
              Powered by DRS v11.1
            </p> */}

          </Col>
          <Col xs={12} lg={6}>

            <ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
              <div>Powered by</div>
              <div>Matrix Syspro Pvt. Ltd.</div>
              {/* <span className="mx-2 mb-0 text-center text-xl-left">
                Copyright © {`${currentYear} `}
                <Card.Link
                  href="http://mannlowe.com/"
                  target="_blank"
                  className="text-blue text-decoration-none fw-normal"
                >
                  Mannlowe
                </Card.Link>
              </span> */}
              {/* <li className="list-inline-item px-0 px-sm-2">
                <Card.Link
                  href="http://mannlowe.com/overview.html"
                  target="_blank"
                >
                  About
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="http://mannlowe.com/" target="_blank">
                  Services
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="http://mannlowe.com/" target="_blank">
                  Solutions
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link
                  href="http://mannlowe.com/contact.php"
                  target="_blank"
                >
                  Contact
                </Card.Link>
              </li> */}
            </ul>
          </Col>
        </Row>
      </footer>
    </div>
  );
};

export default Footer;
