import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import { axiosInstance } from "../../../../axios";

const CertificateModal = (props) => {
    const { handleCloseCertificateModal, iD4Certificate,refreshCoachData4NewCertificate } = props;
    console.log(iD4Certificate)
    const [fileAB, setFileAB] = useState(null);
    const [coachDetails, setCoachDetails] = useState(iD4Certificate ? iD4Certificate : null);

    const handleChange = (e) => {
        const document = e.target.files[0];

        if (document) {
            setFileAB(document);
            console.log(document);
        } else {
            setFileAB(null);
        }
    };

    const handleSubmitCertificate = (e) => {
        e.preventDefault();
     
        callPostCertificateApi();
    }

    const callPostCertificateApi = async () => {
        try {
            if (fileAB) {
                let ID4coach = coachDetails.coach_dbid;
                let newformData = new FormData();
                newformData.append("certificate", fileAB, fileAB.name);
                console.log(fileAB.name);
                console.log(ID4coach)

                const res = await axiosInstance.patch(
                    `assets/coaches/${ID4coach}/`,
                    newformData
                // ,
                // {
                //     headers: {
                //         "content-type": "multipart/form-data",
                //     },
                //     timeout: 1 * 120000,
                // }
                  );
                console.log(res.data)
                refreshCoachData4NewCertificate(res.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal show={true} >
            <Form onSubmit={handleSubmitCertificate}>
                <Modal.Header >

                    <Modal.Title>Upload Part A/B Certificate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Coach Name: {coachDetails.coach_id}</h4>
                    <Form.Control
                        type="file"
                        accept=".pdf"
                        onChange={handleChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCertificateModal}>
                        Close
                    </Button>
                    <Button variant="primary" type='submit'>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default CertificateModal