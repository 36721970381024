import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputGroup, FormControl } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export const GenerateFormComponent = (props) => {
  const {
    asset,
    checklist,
    itemNum,
    callback,
    extraKey = "",
    whetherSingleItem = false,
  } = props;
  // console.log(checklist.asset_name, whetherSingleItem);

  return (
    <div>
      {checklist.responseType === "text_field" && (
        <SingleTextField
          label={`${checklist.asset_name}`}
          extraLabel={`${checklist.extra_label}`}
          hint={`${checklist.hint}`}
          callback={callback}
        />
      )}
      {checklist.responseType === "data_with_integrated_fields" && (
        <IntegratedTextData
          label={`${checklist.asset_name}`}
          callback={callback}
          formFields={checklist.form_fields}
          defaultReadings={checklist.default_readings}
        />
      )}
      {checklist.responseType === "multiple_level_form" && (
        <MultipleLevelForm
          label={`${checklist.asset_name}`}
          callback={callback}
          formFields={checklist.form_fields}
          defaultReadings={checklist.default_readings}
        />
      )}

      {checklist.responseType === "data_with_integrated_fields_col_12" && (
        <IntegratedTextData
          label={`${checklist.asset_name}`}
          callback={callback}
          formFields={checklist.form_fields}
          colSize={12}
          defaultReadings={checklist.default_readings}
        />
      )}

      {checklist.responseType === "switch" && (
        <SwitchForm
          label={
            whetherSingleItem
              ? `${checklist.asset_name}`
              : `${checklist.asset_name} ${itemNum + 1}`
          }
          default_response={checklist.default_response}
          options={checklist.options}
          callback={callback}
        />
      )}

      {checklist.responseType === "switch2" && (
        <SwitchForm2
          label={
            whetherSingleItem
              ? `${checklist.asset_name}`
              : `${checklist.asset_name} ${itemNum + 1}`
          }
          extraKey={`${checklist.main_head}`}
          default_response={checklist.default_response}
          options={checklist.options}
          callback={callback}
        />
      )}

      {checklist.responseType === "switch_with_textbox" && (
        <SwitchAndTextBox
          label={
            whetherSingleItem
              ? `${checklist.asset_name}`
              : `${checklist.asset_name} ${itemNum + 1}`
          }
          callback={callback}
          options={checklist.options}
          extraLabel={checklist.extra_label}
          checkboxOptions={checklist.checkbox_options}
        />
      )}

      {checklist.responseType === "switch_then_textbox" && (
        <SwitchFormThenTextBox
          label={
            whetherSingleItem
              ? `${checklist.asset_name}`
              : `${checklist.asset_name} ${itemNum + 1}`
          }
          callback={callback}
          options={checklist.options}
        />
      )}

      {checklist.responseType === "singledropdown" && (
        <SingleDropdown
          label={
            whetherSingleItem
              ? `${checklist.asset_name}`
              : `${checklist.asset_name} ${itemNum + 1}`
          }
          options={checklist.options}
          callback={callback}
        />
      )}

      {checklist.responseType === "double_dropdown_response" && (
        <DoubleDropdown
          label={
            whetherSingleItem
              ? `${checklist.asset_name}`
              : `${checklist.asset_name} ${itemNum + 1}`
          }
          formFields={checklist.form_fields}
          checkboxOptions={checklist.checkbox_options}
          callback={callback}
        />
      )}

      {checklist.responseType === "radio" && (
        <RadioForm
          label={
            whetherSingleItem
              ? `${checklist.asset_name}`
              : `${checklist.asset_name} ${itemNum + 1}`
          }
          extraKey={extraKey}
          options={checklist.options}
          callback={callback}
        />
      )}

      {checklist.responseType === "double_radio_response" && (
        <DoubleRadioForm
          label={`${checklist.asset_name}`}
          callback={callback}
          formFields={checklist.form_fields}
        />
      )}
    </div>
  );
};

const SingleTextField = (props) => {
  const { label, extraLabel, hint, callback } = props;
  // console.log(label);
  const handleChange = (e) => {
    let data = {};
    data["response"] = e.target.value;
    callback(data);
  };
  return (
    <Form.Group>
      <Row>
        <Col sm="6" className="my-2">
          <Form.Label>{label}</Form.Label>
        </Col>
        <Col sm="6">
          <InputGroup className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-default">
              {extraLabel}
            </InputGroup.Text>
            <FormControl
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              onChange={handleChange}
            />
          </InputGroup>
          <small id="passwordHelpInline" className="px-2 text-muted">
            {hint}
          </small>
        </Col>
      </Row>
    </Form.Group>
  );
};

const IntegratedTextData = (props) => {
  const { label, formFields, callback, colSize = 6, defaultReadings } = props;
  // console.log(label);
  // console.log(formFields);
  const [textData, updateTextData] = useState({});

  const handleChange = (e) => {
    const targetName = e.target.name.split(",");
    const firstKey = targetName[0];
    const secondKey = targetName[1];
    const tempData = textData[firstKey];
    tempData[secondKey] = e.target.value;
    // updateTextData(...textData,);
    console.log(textData);
    let data = {};
    data["response"] = "";
    data["readings"] = textData;
    console.log(data);
    callback(data);
  };

  useEffect(() => {
    //Loads data for first page
    let initData = {};
    if (defaultReadings) {
      initData = defaultReadings;
    } else {
      Object.keys(formFields).map((key) => {
        return (initData[key] = {});
      });
    }
    updateTextData(initData);
  }, []);
  return (
    <Card className="my-2">
      <Card.Header>{label}</Card.Header>
      <Card.Body>
        <Row>
          {formFields &&
            Object.entries(formFields).map(([key, value], index) => {
              return (
                <Col
                  className="border py-3"
                  sm={colSize}
                  key={`${label}_title_${key}_index`}
                >
                  <div>
                    <Card.Subtitle>{key}</Card.Subtitle>
                    <Row>
                      {Object.entries(value).map(
                        ([objKey, objValue], objIndex) => {
                          return (
                            <Col
                              sm="6"
                              key={`${key}_title_${objKey}_${objIndex}`}
                            >
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-default">
                                  {objKey}
                                </InputGroup.Text>

                                {objValue.type !== "select" && (
                                  <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    name={[key, objKey]}
                                    type={objValue.type}
                                    onChange={handleChange}
                                  />
                                )}
                                {objValue.type === "select" && (
                                  <Form.Select
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    name={[key, objKey]}
                                    type={objValue.type}
                                    onChange={handleChange}
                                  >
                                    {objValue.options.map((option, index) => {
                                      return (
                                        <option
                                          key={`option-${index}`}
                                          value={option}
                                        >
                                          {option}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                )}
                              </InputGroup>

                              {/* <Form.Label htmlFor="inputPassword5">
                          
                        </Form.Label>
                        <Form.Control
                          type="password"
                          id="inputPassword5"
                          aria-describedby="passwordHelpBlock"
                        /> */}
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </div>
                </Col>
              );
            })}
        </Row>
      </Card.Body>
    </Card>
  );
};

const MultipleLevelForm = (props) => {
  const { label, formFields, callback, colSize = 6, defaultReadings } = props;
  // console.log(label);
  // console.log(formFields);
  const [textData, updateTextData] = useState({});

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    const targetName = e.target.name;
    const targetValue = e.target.value;
    const tempData = { ...textData, [targetName]: targetValue };
    updateTextData({ ...textData, [targetName]: targetValue });
    let data = {};
    data["response"] = "";
    data["readings"] = tempData;
    console.log(data);
    callback(data);
  };

  useEffect(() => {
    //Loads data for first page
    let initData = {};
    if (defaultReadings) {
      initData = defaultReadings;
    } else {
      Object.keys(formFields).map((key) => {
        return (initData[key] = {});
      });
    }
    updateTextData(initData);
  }, []);
  return (
    <>
      {formFields &&
        Object.entries(formFields).map(([key, value], index) => {
          return (
            <Row key={`multiple_level_${key}`}>
              {value.type === "dropdown" && (
                <Card sm={colSize}>
                  <Card.Body>
                    <Form.Group>
                      <Row>
                        <Col sm="6" className="my-2">
                          <Form.Label>{key}</Form.Label>
                        </Col>
                        <Col sm="6">
                          <Form.Select
                            name={key}
                            required
                            onChange={handleChange}
                          >
                            {value.options.map((option, index) => {
                              return (
                                <option key={`option-${index}`} value={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Card.Body>
                </Card>
              )}
              {value.type === "string" && (
                <Card sm={colSize}>
                  <Card.Body>
                    <Form.Group>
                      <Row>
                        <Col sm="6" className="my-2">
                          <Form.Label>{key}</Form.Label>
                        </Col>
                        <Col sm="6">
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default">
                              {""}
                            </InputGroup.Text>
                            <FormControl
                              name={key}
                              aria-label="Default"
                              aria-describedby="inputGroup-sizing-default"
                              onChange={handleChange}
                            />
                          </InputGroup>
                          <small
                            id="passwordHelpInline"
                            className="px-2 text-muted"
                          >
                            {value.hint}
                          </small>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Card.Body>
                </Card>
              )}
            </Row>
          );
        })}
    </>
  );
};

const SwitchForm = (props) => {
  const { label, default_response, options, callback } = props;
  const [switchStatus, updateSwitchStatus] = useState(true);
  const handleChange = (e) => {
    let data = {};
    if (switchStatus === true) {
      updateSwitchStatus(false);
      data["response"] = options[1];
      callback(data);
      //i.e. workcenter was Up, and user is trying to put in down, so pop up the modal
      // let tempForm = statusForm;
      // tempForm.status = "Down";
      // updateStatusForm(tempForm);
      // setshowModal(true);
      //rest will be handled after submit
    } else {
      updateSwitchStatus(true);
      data["response"] = default_response;
      callback(data);
      //i.e. was Down, and user is trying to turn it on
      // initialFormData.status = "Up";
      // updateSwitchStatus(true);
      // setHeadingVariant("success");
      // handleStatusChange(initialFormData);
    }
  };
  return (
    <Form.Group>
      <Row>
        <Col xs="8">
          <Form.Label>{label}</Form.Label>
        </Col>
        <Col xs="4">
          <Form.Check
            name={options[0]}
            type="switch"
            id="custom-switch"
            checked={switchStatus}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

const SwitchForm2 = (props) => {
  const { label, extraKey, default_response, options, callback } = props;
  const [switchStatus, updateSwitchStatus] = useState(
    localStorage.getItem(`${extraKey} ${label}`) ? false : true
  );
  const [switchDisabled, updateSwitchDisabled] = useState(
    localStorage.getItem(`${extraKey} ${label}`) ? true : false
  );
  // const [switchStatus, updateSwitchStatus] = useState(true);
  const handleChange = (e) => {
    let data = {};
    if (switchStatus === true) {
      updateSwitchStatus(false);
      data["response"] = options[1];
      callback(data);
      localStorage.setItem(`${extraKey} ${label}`, options[1]);
    } else {
      updateSwitchStatus(true);
      data["response"] = default_response;
      data["clear_nsm_selection"] = true;
      callback(data);
      //i.e. was Down, and user is trying to turn it on
      // initialFormData.status = "Up";
      // updateSwitchStatus(true);
      // setHeadingVariant("success");
      // handleStatusChange(initialFormData);
    }
  };
  return (
    <Form.Group>
      <Row>
        <Col xs="8">
          <Form.Label>{label}</Form.Label>
        </Col>
        <Col xs="4">
          <Form.Check
            name={options[0]}
            type="switch"
            id="custom-switch"
            checked={switchStatus}
            onChange={handleChange}
            disabled={switchDisabled}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

const SwitchAndTextBox = (props) => {
  const { label, extraLabel, options, callback, checkboxOptions } = props;
  const [switchStatus, updateSwitchStatus] = useState(true);
  const [showCheckBox, updateShowCheckBox] = useState(false);
  const initialData = {
    response: "OK",
    readings: {},
    issuesL1: {},
  };
  const [formResponse, updateFormResponse] = useState(initialData);
  const handleChange = (e) => {
    console.log(e.target.name);
    let tempData = formResponse;
    if (e.target.name === "status") {
      if (switchStatus === true) {
        tempData.response = "NOT OK";
        updateSwitchStatus(false);
        updateShowCheckBox(true);
      } else {
        tempData.status = "OK";
        updateSwitchStatus(true);
        updateShowCheckBox(false);
      }
    } else if (e.target.name === "value") {
      tempData.readings["value"] = e.target.value;
    }
    console.log(tempData);
    updateFormResponse(tempData);
    callback(tempData);
  };

  const handleCheckBoxResponse = (checkBoxData) => {
    let tempData = { ...formResponse, ["issuesL1"]: checkBoxData };
    updateFormResponse(tempData);
    console.log(tempData);
    callback(tempData);
  };

  return (
    <Col className="py-2">
      <Col xs="4">
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col xs="8">
        <Row>
          <Col xs="3">
            <Form.Check
              name="status"
              type="switch"
              id="custom-switch"
              checked={switchStatus}
              onChange={handleChange}
            />
          </Col>
          <Col xs="7">
            <FormControl
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              placeholder={extraLabel}
              name="value"
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Col>
      {showCheckBox && (
        <CheckBoxForm
          label={label}
          checkboxOptions={checkboxOptions}
          callback={handleCheckBoxResponse}
        />
      )}
    </Col>
  );
};

const SwitchFormThenTextBox = (props) => {
  const { label, default_response, options, callback } = props;
  const initialData = {
    response: default_response,
    issuesL1: {},
    additional_data: null,
  };
  const [switchStatus, updateSwitchStatus] = useState(true);
  const [formResponse, updateFormResponse] = useState(initialData);
  const [whetherAskDescription, updateWhetherAskDescription] = useState(false);
  const [showModal, setshowModal] = useState(true);
  const handleClose = () => {
    setshowModal(false);
    updateWhetherAskDescription(false);
  };
  const handleDescriptionSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    updateWhetherAskDescription(false);
    callback(formResponse);
  };

  const handleDescriptionChange = (e) => {
    // console.log(e.target.value);
    let tempData = formResponse;
    tempData.additional_data = e.target.value;
    updateFormResponse(tempData);
  };
  const handleChange = (e) => {
    let tempData = formResponse;
    if (switchStatus === true) {
      updateSwitchStatus(false);
      tempData.response = options[1];
      updateFormResponse(tempData);
      callback(tempData);
      updateWhetherAskDescription(true);
      setshowModal(true);

      //i.e. workcenter was Up, and user is trying to put in down, so pop up the modal
      // let tempForm = statusForm;
      // tempForm.status = "Down";
      // updateStatusForm(tempForm);
      // setshowModal(true);
      //rest will be handled after submit
    } else {
      updateSwitchStatus(true);
      tempData.response = options[0];
      updateFormResponse(tempData);
      callback(tempData);
      updateWhetherAskDescription(false);
      setshowModal(false);

      //i.e. was Down, and user is trying to turn it on
      // initialFormData.status = "Up";
      // updateSwitchStatus(true);
      // setHeadingVariant("success");
      // handleStatusChange(initialFormData);
    }
  };
  return (
    <div>
      <Form.Group>
        <Row>
          <Col xs="8">
            <Form.Label>{label}</Form.Label>
          </Col>
          <Col xs="4">
            <Form.Check
              name={options[0]}
              type="switch"
              id="custom-switch"
              checked={switchStatus}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form.Group>
      {whetherAskDescription && (
        <Modal
          show={showModal}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form onSubmit={handleDescriptionSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {label}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  Description
                </InputGroup.Text>
                <FormControl
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  as="textarea"
                  name="description"
                  onChange={handleDescriptionChange}
                />
              </InputGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
};

const SingleDropdown = (props) => {
  const { label, options, callback } = props;

  const handleChange = (e) => {
    let data = {};
    data["response"] = e.target.value;
    callback(data);
  };
  return (
    <Col sm="12">
      <Form.Group>
        <InputGroup className="mb-3">
          <InputGroup.Text id="inputGroup-sizing-default">
            {label}
          </InputGroup.Text>
          <Form.Select name="12" required onChange={handleChange}>
            {options.map((option, index) => {
              return (
                <option key={`option-${index}`} value={option}>
                  {option}
                </option>
              );
            })}
          </Form.Select>
        </InputGroup>
      </Form.Group>
    </Col>
  );
};

const DoubleDropdown = (props) => {
  const { label, formFields, checkboxOptions, callback } = props;
  const initialData = {
    response: formFields.response[0],
    issuesL1: {},
    type: null,
  };
  const [formData, updateFormData] = useState(initialData);
  const [showCheckBox, updateShowCheckBox] = useState(false);
  const handleChange = (title, data) => {
    let tempData = { ...formData, [title]: data };
    // console.log(title, data);
    if (tempData.response !== formFields.response[0]) {
      if (checkboxOptions.length > 0) {
        updateShowCheckBox(true);
      }
    }
    if (tempData.response === "OK") {
      updateShowCheckBox(false);
    }
    updateFormData(tempData);
    callback(tempData);
  };

  const handleCheckBoxResponse = (checkBoxData) => {
    let tempData = { ...formData, ["issuesL1"]: checkBoxData };
    updateFormData(tempData);
    callback(tempData);
  };
  return (
    <div>
      <Form.Group className="m-3">
        <Col xs="12">
          <Row>
            <Col xs="5">
              <Form.Label>{label}</Form.Label>
            </Col>
            <Col xs="7">
              {formFields &&
                Object.entries(formFields).map(([key, value], index) => {
                  return (
                    <RenderDropdown
                      key={`doubledropdown_${label}_${key}_${index}`}
                      label={key}
                      options={value}
                      callback={handleChange}
                    />
                  );
                })}
            </Col>
          </Row>
        </Col>
      </Form.Group>
      {showCheckBox && (
        <CheckBoxForm
          label={label}
          checkboxOptions={checkboxOptions}
          callback={handleCheckBoxResponse}
        />
      )}
    </div>
  );
};

const RenderDropdown = (props) => {
  const { label, options, callback } = props;
  const handleOneRadio = (data) => {
    // console.log(label, data);
    callback(label, data.response);
  };
  return (
    <SingleDropdown label={label} options={options} callback={handleOneRadio} />
  );
};

const RadioForm = (props) => {
  const { label, extraKey, options, callback, whetherDefault = false } = props;
  const [radioSelected, updateRadioSelected] = useState(
    localStorage.getItem(`${extraKey}-${label}`)
      ? localStorage.getItem(`${extraKey}-${label}`)
      : whetherDefault
      ? options[0]
      : null
  );

  const handleChange = (e) => {
    // console.log(e.target.value);
    localStorage.setItem(`${extraKey}-${label}`, e.target.value);
    updateRadioSelected(e.target.value);
    let data = {};
    data["response"] = e.target.value;
    callback(data);
  };
  return (
    <Form.Group>
      <Row>
        <Col xs="6">
          <Form.Label className="fw-bold text-info">{label}</Form.Label>
        </Col>
        <Col xs="6">
          {options.map((option, index) => {
            return (
              <Form.Check
                key={`option_${label}_${index}`}
                inline
                checked={option === radioSelected}
                label={option}
                value={option}
                name={label}
                type={"radio"}
                id={`${label}_${option}_${index}_radio`}
                onChange={handleChange}
              />
            );
          })}
        </Col>
      </Row>
    </Form.Group>
  );
};

const DoubleRadioForm = (props) => {
  const { label, formFields, callback } = props;
  const initialData = {
    response: "",
    readings: {},
  };
  const [radioData, updateRadioData] = useState(initialData);
  const handleChange = (title, data) => {
    let tempData = radioData;
    let tempReadings = tempData.readings;
    console.log(tempReadings);
    tempReadings[title] = data;
    tempData.readings = tempReadings;
    updateRadioData(tempData);
    callback(tempData);
  };

  return (
    <Form.Group>
      <Row>
        <Col sm="12">
          {formFields &&
            Object.entries(formFields).map(([key, value], index) => {
              return (
                <RenderRadio
                  key={`doubleradio_${label}_${key}_${index}`}
                  label={key}
                  options={value}
                  callback={handleChange}
                />
              );
            })}
        </Col>
      </Row>
    </Form.Group>
  );
};

const RenderRadio = (props) => {
  const { label, options, callback } = props;
  const handleOneRadio = (data) => {
    console.log(label, data.response);
    callback(label, data.response);
  };
  return (
    <div>
      <RadioForm
        label={label}
        options={options}
        callback={handleOneRadio}
        whetherDefault={true}
      />
    </div>
  );
};

const CheckBoxForm = (props) => {
  const { label, checkboxOptions, callback } = props;
  const [checkBoxData, updateCheckBoxData] = useState([]);
  const [showModal, setshowModal] = useState(true);
  const handleClose = () => {
    setshowModal(false);
  };
  const handleIssueSubmit = (e) => {
    e.preventDefault();
    setshowModal(false);
    callback(checkBoxData);
  };
  const handleChange = (e) => {
    let tempData = checkBoxData;
    tempData.push(e.target.value);
    updateCheckBoxData(tempData);
    console.log(checkBoxData);
  };
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleIssueSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`${label}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Row>
              {checkboxOptions.map((option, index) => {
                return (
                  <Col sm="3" key={`option_${label}_${index}`}>
                    <Form.Check
                      inline
                      label={option}
                      value={option}
                      name={label}
                      type={"checkbox"}
                      id={`${label}_${option}_${index}_check`}
                      onChange={handleChange}
                    />
                  </Col>
                );
              })}
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const AlertMessage = (props) => {
  const { variant = "success", heading = "", message = "" } = props;
  return (
    <Alert className="text-center" variant={variant}>
      <Alert.Heading>{heading}</Alert.Heading>
      <p>{message}</p>
    </Alert>
  );
};
export const AlertMessageNSM = (props) => {
  const {
    variant = "success",
    heading = "",
    message = "",
    handleNSMClick,
  } = props;

  return (
    <Alert className="text-center" variant={variant}>
      <Alert.Heading>{heading}</Alert.Heading>
      <p>{message}</p>
      <Button
        variant="outline-warning"
        className="my-3"
        onClick={() => {
          handleNSMClick(true);
        }}
      >
        Edit
      </Button>
    </Alert>
  );
};
