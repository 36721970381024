import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../../../axios";
import "../../ioh.css";
import {
  FormatSimpleDate,
  FormatStringDate,
} from "../../../../components/dateformat";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { IOHForm } from "./iohChecklist";
import TrainInformation from "../../../../components/TrainInformation";
import ClearLocalStorage from "../../../../components/clearLocalStorage";

export const IOHDiagnosis = (props) => {
  //shows table for planned ioh coaches, allows filtering coaches
  const [diagnosisData, setDiagnosisData] = useState({});
  const [diagnosisDataOnTable, updateDiagnosisDataOnTable] = useState({});
  const [showDiagnoseTable, setShowDiagnoseTable] = useState(true);
  const [coachOptions, updateCoachOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");
  const [selectedCoach, setSelectedCoach] = useState(null);

  function sortByPlannedDate(a, b) {
    if (a.ioh_planned_date > b.ioh_planned_date) return 1;
    if (a.ioh_planned_date == b.ioh_planned_date) return 0;
    if (a.ioh_planned_date < b.ioh_planned_date) return -1;
  }

  useEffect(() => {
    //Loads data for first page
    axiosInstance
      .get(`assets/coaches/?keyword=diagnosis`, {})
      .then((res) => {
        const data = res.data;
        data.sort(sortByPlannedDate);
        setDiagnosisData(data);
        updateDiagnosisDataOnTable(data);
        const tempCoachData = [];
        Object.values(data).forEach((item) => {
          // console.group(item.coach_id);
          tempCoachData.push(item.coach_id);
        });
        updateCoachOptions(tempCoachData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCoachView = (coachID) => {
    // console.log(coachID);
    setSelectedCoach(coachID);
  };

  const handleFormSubmission = () => {
    console.log("submitted");
    setSelectedCoach(null);
    updateDiagnosisDataOnTable(diagnosisData);
    setShowDiagnoseTable(true);
  };

  const handleChange = (e) => {
    // console.log(e);
    setSelected();
    setTypeAheadValue(e[0]);
  };

  const handleFilterButtonSubmit = (e) => {
    e.preventDefault();
    // console.log(typeAheadValue);
    handleCoachNumberSubmit(typeAheadValue);
  };

  const handleCoachNumberSubmit = (coachID) => {
    // setButtonDisable(true);
    if (coachID) {
      let filteredData = diagnosisData.filter(
        (item) => item.coach_id === coachID
      );
      // console.log(filteredData);
      updateDiagnosisDataOnTable(filteredData);
    } else {
      updateDiagnosisDataOnTable(diagnosisData);
    }
    ClearLocalStorage();
  };

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>Diagnose</h6>
        </Card.Header>{" "}
        <Card.Body>
          <Form onSubmit={handleFilterButtonSubmit}>
            <Row>
              <Col sm="4">
                <Form.Group className="">
                  <InputGroup name="coach_id">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Typeahead
                      id="basic-example"
                      onChange={handleChange}
                      options={coachOptions}
                      placeholder="Enter Coach Number..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCoachNumberSubmit(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm="2">
                <Button
                  variant="outline-gray"
                  className=""
                  type="submit"
                  // disabled={buttonDisable}
                >
                  Lookup
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card className="my-2">
        {diagnosisData && showDiagnoseTable && (
          <Table
            // striped
            bordered
            hover
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="text-center">
              <tr>
                <th>#</th>
                <th>Coach Number</th>
                <th>Coach Type</th>
                <th>Train Number</th>
                {/* <th>Coach Returning Date</th> */}
                <th>Reason</th>
                <th>Planned IOH Date</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {Object.values(diagnosisDataOnTable).map((coach, index) => {
                return (
                  <ScheduleTable
                    key={`issue_${index}`}
                    coachData={coach}
                    index={index}
                    handleCoachView={handleCoachView}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </Card>
      {selectedCoach && (
        <IOHForm
          coachID={selectedCoach}
          handleFormSubmission={handleFormSubmission}
          setShowDiagnoseTable={setShowDiagnoseTable}
        />
      )}
    </div>
  );
};

const ScheduleTable = (props) => {
  //renders rows in table
  const { coachData, index, handleCoachView } = props;
  // console.log("called", coachData.coach_id);
  // let [datesToShow, updateDatesToShow] = useState([]);
  let [buttonVariant, updateButtonVariant] = useState("gray");

  // useEffect(() => {
  //   let returnDate = FormatSimpleDate(
  //     FormatStringDate(coachData.ioh_planned_date)
  //   );
  //   updateDatesToShow([returnDate]);
  // }, []);

  const handleViewClick = (e) => {
    // updateButtonVariant("success");
    handleCoachView(coachData.coach_dbid);
  };

  return (
    <tr>
      <td className="text-center">{index + 1}</td>
      <td className="text-center">{coachData.coach_id}</td>
      <td className="text-center">{coachData.coach_type}</td>
      <td className="text-center">
        <TrainInformation coachDataSchedule={coachData.schedule} />
      </td>
      {/* <td className="text-center">
        {datesToShow &&
          datesToShow.map((givenDate) => {
            return (
              <span className="mx-1" key={givenDate}>
                {givenDate}
              </span>
            );
          })}
      </td> */}
      <td className="text-center">{coachData.detachment_reason}</td>
      <td className="text-center">
        <fieldset disabled>
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            defaultValue={coachData.ioh_planned_date}
            name=""
            type="date"
          />
        </fieldset>
      </td>
      <td className="text-center">
        <Button
          variant={buttonVariant}
          onClick={handleViewClick}
          size="sm"
          // disabled={showChecked}
        >
          View
        </Button>
      </td>
    </tr>
  );
};
