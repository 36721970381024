import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { axiosInstance } from "../../../../axios";
import { FormatStringDate } from "../../../../components/dateformat";
import { coachTypeList, coachTypeListUtkrisht } from "../trc_manifest";

const AddCoach = (props) => {
  const { handleAddCoachClose, handleAddCoachSubmit, coachData } = props;

  const StationList = JSON.parse(localStorage.getItem("StationList"));

  const firstStationCode = Object.keys(StationList)[0];

  const [coachData2, setCoachData2] = useState(coachData ? coachData : null);
  const [isCoachExist, setIsCoachExist] = useState(false);
  const [ifUtkrisht, updateIfUtkrisht] = useState(false);
  const [coachType, updateCoachType] = useState("ICF GS");
  const [addCoachData, setAddCoachData] = useState({
    coach_type_id: 2,
    poh_cycle: "18",
    coach_depot_id: StationList[firstStationCode],
  });
  const [pohCycleOptions, updatePohCycleOptions] = useState(["18", "24"]);
  const [pohCycle, updatePohCycle] = useState("18");
  const [iohDueDate, updateIohDueDate] = useState(null);
  // added below 3 states for concatenation of coachId using coach no. & O-rly
  const [str, setStr] = useState(null);
  const [num, setNum] = useState(null);
  const [strNum, setStrNum] = useState(null);

  useEffect(() => {
    if ("coach_id" in addCoachData && "o_rly" in addCoachData) {
      if (
        addCoachData.coach_id !== null ||
        addCoachData.coach_id !== undefined ||
        addCoachData.o_rly !== null ||
        addCoachData.o_rly !== undefined
      ) {
        console.log("coach_id", addCoachData);
        let Combo1 = addCoachData.o_rly + addCoachData.coach_id;
        console.log(Combo1);
        console.log(coachData2);

        let temp1 = coachData2.filter((item) => item.coach_id == Combo1);
        console.log("temp1", temp1);
        if (temp1.length > 0) {
          setIsCoachExist(true);
        } else {
          setIsCoachExist(false);
        }
      }
    }
  }, [addCoachData?.coach_id, addCoachData?.o_rly]);

  const onValueChange2 = (e) => {
    setAddCoachData({ ...addCoachData, [e.target.name]: e.target.value });
    if (e.target.name === "p_date") {
      updateDueDate(e.target.value);
    }
    //s---concat logic for 2 feilds
    if (e.target.name === "o_rly") {
      setStr(e.target.value);
    }
    if (e.target.name === "coach_id") {
      setNum(e.target.value);
    }

    //E---concat logic for 2 feilds
  };
  useEffect(() => {
    if (num && str) {
      setStrNum(str + num);

      // console.log(strNum);
    }
  }, [str, num]);

  const onChangeUtkrisht = (e) => {
    if (!ifUtkrisht) {
      updateIfUtkrisht(true);
      setAddCoachData({
        ...addCoachData,
        coach_type_id: coachTypeListUtkrisht[coachType],
      });
    } else {
      updateIfUtkrisht(false);
      setAddCoachData({
        ...addCoachData,
        utkrisht: false,
        coach_type_id: coachTypeList[coachType],
      });
    }
  };

  const onChangeType = (e) => {
    updateCoachType(e.target.value);
    if (e.target.value.includes("ICF")) {
      updatePohCycleOptions(["18", "24"]);
      onChangePohCycle("18");
    } else {
      updatePohCycleOptions(["36"]);
      onChangePohCycle("36");
    }
    if (!ifUtkrisht) {
      setAddCoachData({
        ...addCoachData,
        coach_type_id: coachTypeList[e.target.value],
      });
    } else {
      setAddCoachData({
        ...addCoachData,
        coach_type_id: coachTypeListUtkrisht[e.target.value],
      });
    }
  };

  const onChangePohCycle = (sent_poh_cycle) => {
    updatePohCycle(sent_poh_cycle);
    // setAddCoachData({
    //   ...addCoachData,
    //   poh_cycle: sent_poh_cycle,
    // });
    if (Object.keys(addCoachData).includes("p_date")) {
      updateDueDate(addCoachData.p_date, sent_poh_cycle);
    }
  };

  const onChangeDueDate = (e) => {
    updateIohDueDate(FormatStringDate(e.target.value));
  };

  const updateDueDate = (p_date, given_poh_cycle = pohCycle) => {
    const date = new Date(p_date);
    date.setMonth(date.getMonth() + given_poh_cycle / 2);
    updateIohDueDate(FormatStringDate(date));
  };

  useEffect(() => {
    setAddCoachData({
      ...addCoachData,
      ioh_due_date: iohDueDate,
    });
  }, [iohDueDate]);

  const addCoachDetails = async () => {
    const todayDate = FormatStringDate(new Date());
    console.log(addCoachData.utkrisht, addCoachData.coachtype);
    const submittedCoachData = {
      ...addCoachData,
      ["last_data_update_date"]: todayDate,
      ["poh_cycle"]: pohCycle,
      //now
      coach_id: strNum,
    };
    console.log(submittedCoachData);
    try {
      const res = await axiosInstance.post(
        `assets/coaches/`,
        submittedCoachData
      );
      console.log(res.data);
      handleAddCoachSubmit(res.data.coach_dbid);
    } catch (error) {
      console.log(error);
    }
  };
  const onDepotChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    setAddCoachData({
      ...addCoachData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleAddCoachClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Coach</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <MyFormComponent
              label="O Rly"
              type="text"
              placeholder="O Rly"
              name="o_rly"
              handleChange={onValueChange2}
              required
            />
            <MyFormComponent
              label="Coach No."
              type="number"
              placeholder="Coach No."
              name="coach_id"
              required={true}
              handleChange={onValueChange2}
            />
            {isCoachExist === false && (
              <>
                <Row className="my-3">
                  <Col sm="3" className="my-2">
                    <Form.Label>Coach Type</Form.Label>
                  </Col>
                  <Col sm="9">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={onChangeType}
                      name="coach_type"
                      required
                    >
                      {Object.keys(coachTypeList).map((coachtype, index) => {
                        return (
                          <option value={coachtype} key={`coach_type_${index}`}>
                            {coachtype}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col sm="3" className="my-2">
                    <Form.Label>Depot</Form.Label>
                  </Col>
                  <Col sm="9">
                    <Form.Select
                      onChange={onDepotChange}
                      name="coach_depot_id"
                      required
                    >
                      {Object.keys(StationList).map((station, index) => {
                        return (
                          <option
                            value={StationList[station]}
                            key={`coach_depot_id_${index}`}
                          >
                            {station}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>

                {/* <MyFormComponent
              label="No. of Compartments"
              type="number"
              placeholder="No. of Compartments"
              name="number_of_compartments"
              required={true}
              handleChange={onValueChange2}
            /> */}

                <MyFormComponent
                  label="POH Station"
                  type="text"
                  placeholder="POH Station"
                  name="p_shop"
                  handleChange={onValueChange2}
                />

                <MyFormComponent
                  label="POH Date"
                  type="date"
                  name="p_date"
                  required={true}
                  handleChange={onValueChange2}
                />

                <Row className="my-3">
                  <Col sm="3" className="my-2">
                    <Form.Label>POH Cycle</Form.Label>
                  </Col>
                  <Col sm="9">
                    <Form.Select
                      aria-label="Default select"
                      onChange={(e) => {
                        onChangePohCycle(e.target.value);
                      }}
                      name="poh_cycle"
                      required
                    >
                      {pohCycleOptions.map((p_cycle, index) => {
                        return (
                          <option
                            value={p_cycle}
                            key={`p_cycle_${index}`}
                            selected={p_cycle === pohCycle}
                          >
                            {p_cycle}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>

                <MyFormComponent
                  label="Return Date"
                  type="date"
                  name="r_date"
                  handleChange={onValueChange2}
                />

                <MyFormComponent
                  label="Reduced Return Date"
                  type="date"
                  name="reduced_r_date"
                  handleChange={onValueChange2}
                />

                <MyFormComponent
                  label="Extended Return Date"
                  type="date"
                  name="extended_r_date"
                  handleChange={onValueChange2}
                />

                <MyFormComponent
                  label="IOH Shop"
                  type="text"
                  placeholder="IOH Shop"
                  name="ioh_shop"
                  handleChange={onValueChange2}
                />

                <Row className="my-3">
                  <Col sm="3" className="my-2">
                    <Form.Label>IOH Due Date</Form.Label>
                  </Col>
                  <Col sm="9">
                    <Form.Control
                      type="date"
                      onChange={onChangeDueDate}
                      value={iohDueDate}
                      name="ioh_due_date"
                    />
                  </Col>
                </Row>

                <MyFormComponent
                  label="IOH Execution Date"
                  type="date"
                  name="ioh_execution_date"
                  handleChange={onValueChange2}
                />
                <MyFormComponent
                  label="Utkrisht Modification"
                  type="checkbox"
                  name="utkrisht"
                  handleChange={onChangeUtkrisht}
                />
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {isCoachExist === true && (
            <Alert variant="danger">
              This Coach No. already exists, You can make PRO entry for this coach.
            </Alert>
          )}
          <Button
            variant="success"
            onClick={() => {
              handleAddCoachClose();
              addCoachDetails();
            }}
            disabled={isCoachExist === false ? false : true}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MyFormComponent = (props) => {
  const {
    label,
    type,
    placeholder = null,
    defaultValue,
    name,
    required = false,
    handleChange,
  } = props;
  return (
    <Row className="my-3">
      <Col sm="3" className="my-2">
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col sm="9">
        {type !== "checkbox" && (
          <Form.Control
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            defaultValue={defaultValue}
            name={name}
            required={required}
          />
        )}
        {type === "checkbox" && (
          <Form.Check
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            name={name}
            required={required}
          />
        )}
      </Col>
    </Row>
  );
};

export default AddCoach;
