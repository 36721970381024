import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  InputGroup,
  Col,
  Button,
  Form,
  Row,
  Card,
  Spinner,
} from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../../../axios";
import { Typeahead } from "react-bootstrap-typeahead";
import ViewRakes from "./ViewRakes";
import AddRake from "./AddRake";

const Rakes = (props) => {
  const location = useLocation();

  // Start- modal add rake
  const [rakeName, setRakeName] = useState(null);
  const [highlightedCoach, setHighlightedCoach] = useState(null);
  const [allRakesData, updateAllRakesData] = useState(null);
  const [rakeOptions, updateRakeOptions] = useState([]);
  const [selectedRakeID, updateSelectedRakeID] = useState(null);
  const [showAddRake, setShowAddRake] = useState(false);
  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`assets/rakes`, {})
      .then((res) => {
        const data = res.data;
        // console.log(data);
        updateAllRakesData(data);
        const tempRakeData = [];
        Object.values(data).forEach((item) => {
          // console.group(item.coach_id);
          tempRakeData.push(item.rake_name);
        });
        updateRakeOptions(tempRakeData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (location.state) {
      // setTypeAheadValue(location.state.rake_name);
      // handleRakeSelection(location.state.rake_name);
      updateSelectedRakeID(location.state.rake_dbid);
      if (location.state.selected_coach_dbid !== null) {
        setHighlightedCoach(location.state.selected_coach_dbid);
      }
    }
  }, []);

  const handleChange = (e) => {
    // console.log(e);
    setSelected();
    setTypeAheadValue(e[0]);
    setRakeName(null);
    updateSelectedRakeID(null);
  };

  const handleRakeSelectionButton = (e) => {
    e.preventDefault();
    // console.log(typeAheadValue);
    handleRakeSelection(typeAheadValue);
  };
  const handleRakeSelection = (rakeName) => {
    setRakeName(rakeName);
    const tempSelectedRakeID = allRakesData.find(
      (item) => item.rake_name === rakeName
    );
    updateSelectedRakeID(tempSelectedRakeID.rake_dbid);
  };

  const handleRakeAdditionClose = () => setShowAddRake(false);
  const handleRakeAdditionSubmit = (new_rake_data) => {
    handleRakeAdditionClose();
    console.log(new_rake_data);
    updateSelectedRakeID(new_rake_data.rake_dbid);
    setRakeName(new_rake_data.rake_name);
  };

  return (
    <>
      <div className="page-container">
        <Card>
          <Card.Header className="bg-light d-flex justify-content-between align-items-center">
            <h6>Rakes</h6>

            <Button
              variant="outline-success"
              className="mx-2"
              onClick={() => {
                setShowAddRake(true);
                updateSelectedRakeID(null);
                setRakeName(null);
              }}
            >
              Add New Rake
            </Button>
          </Card.Header>

          <Card.Body>
            <Form onSubmit={handleRakeSelectionButton}>
              <Row>
                <Col xs="8">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>

                    <Typeahead
                      id="find-rake"
                      onChange={handleChange}
                      options={rakeOptions}
                      placeholder="Enter Rake Name..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleRakeSelection(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Col>

                <Col xs="2">
                  <Button variant="outline-gray" className="" type="submit">
                    Lookup
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
          {allRakesData === null && (
            <Card.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                style={{
                  width: "100px",
                  height: "100px",
                }}
                animation="border"
                role="status"
              ></Spinner>
            </Card.Body>
          )}
        </Card>

        {selectedRakeID && (
          <ViewRakes
            rakeID={selectedRakeID}
            highlightedCoach={highlightedCoach}
          />
        )}
        {showAddRake && (
          <AddRake
            handleRakeAdditionClose={handleRakeAdditionClose}
            handleRakeAdditionSubmit={handleRakeAdditionSubmit}
          />
        )}
      </div>
    </>
  );
};

export default Rakes;
