import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faChartBar,
  faGears,
  faUsers,
  faSignOutAlt,
  faTimes,
  faGear,
  faMagnifyingGlass,
  faTrain,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import "./Sidebar.css";

import { URLs } from "../routes";
// import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import crlyLogo from "../assets/img/technologies/crly-logo.webp";
import ProfilePicture from "../assets/img/team/picture_profile.png";

const Sidebar = (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);
  const userdeptList = JSON.parse(localStorage.getItem("user_dept"));

  let user_groups = [];
  try {
    user_groups = JSON.parse(localStorage.getItem("user_group"));
  } catch {
    console.log("Subscribed Products list empty");
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    // #262B40
    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              {icon && (
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />{" "}
                </span>
              )}
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Accordion className="flex-column">{children}</Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        {/* <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={URLs.DashboardOverview.path}
        >
          <Image src={crlyLogo} className="navbar-brand-light" />
        </Navbar.Brand> */}
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, {localStorage.getItem("user_email")}</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={URLs.Signin.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>

            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                external
                title="Central Railway"
                link=""
                target="_blank"
                image={crlyLogo}
                className="logo"
              />
              <Dropdown.Divider className="my-3 border-indigo" />
              {(user_groups?.includes("SRDME") ||
                user_groups?.includes("CDO")) && (
                <>
                  {window.matchMedia("(min-width: 600px)").matches && (
                    <CollapsableNavItem
                      eventKey="dashboard/"
                      title="Reports"
                      icon={faChartBar}
                      className="text-center logo d-none d-sm-block"
                    >
                      {userdeptList.find(
                        (dept) => dept.dept_name === "Mechanical"
                      ) && (
                        <NavItem
                          title="Sick Line"
                          link={URLs.SickLineDashboard.path}
                        />
                      )}
                      {userdeptList.find(
                        (dept) => dept.dept_name === "Mechanical"
                      ) && (
                        <NavItem
                          title="Inventory"
                          link={URLs.InventoryDashboard.path}
                        />
                      )}
                      {userdeptList.find(
                        (dept) => dept.dept_name === "Mechanical"
                      ) &&
                        userdeptList.find(
                          (dept) => dept.dept_name === "Electrical"
                        ) && (
                          <NavItem
                            title="Rail Madad"
                            link={URLs.RailMadadDashboard.path}
                          />
                        )}
                      {userdeptList.find(
                        (dept) => dept.dept_name === "Mechanical"
                      ) && (
                        <NavItem
                          title="Rail Madad Mech."
                          link={URLs.RailMadadMecDashboard.path}
                        />
                      )}
                      {userdeptList.find(
                        (dept) => dept.dept_name === "Electrical"
                      ) && (
                        <NavItem
                          title="Rail Madad Elect."
                          link={URLs.RailMadadElecDashboard.path}
                        />
                      )}
                      {userdeptList.find(
                        (dept) => dept.dept_name === "Mechanical"
                      ) && (
                        <NavItem
                          title="Coach Summary"
                          link={URLs.CoachSummary.path}
                        />
                      )}
                    </CollapsableNavItem>
                  )}
                  {window.matchMedia("(max-width: 600px)").matches &&
                    (user_groups?.includes("SRDME") ||
                      user_groups?.includes("CDO")) && (
                      <NavItem
                        title="Reports"
                        link={URLs.DashboardOMobile.path}
                        icon={faChartBar}
                        className="d-block"
                      />
                    )}
                </>
              )}

              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem
                title="Modules"
                link={URLs.Modules.path}
                icon={faGears}
                className="text-center logo"
              />
              {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
                <CollapsableNavItem eventKey="ioh-nsm/" title="Non Schedule">
                  {user_groups?.includes("Supervisor") && (
                    <NavItem title="Plan" link={URLs.NSMPlanner.path} />
                  )}
                </CollapsableNavItem>
              )}
              {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
                <CollapsableNavItem eventKey="ioh/" title="IOH Schedule">
                  {user_groups?.includes("ACDO") && (
                    <NavItem title="Plan" link={URLs.IOHPlanner.path} />
                  )}
                  {(user_groups?.includes("Supervisor") ||
                    user_groups?.includes("Writer")) && (
                    <NavItem title="Diagnose" link={URLs.IOHDiagnosis.path} />
                  )}
                  {(user_groups?.includes("Supervisor") ||
                    user_groups?.includes("ACDO")) && (
                    <NavItem
                      title="Address Issues"
                      link={URLs.IOHIssues.path}
                    />
                  )}
                </CollapsableNavItem>
              )}
              {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
                <CollapsableNavItem eventKey="poh/" title="POH">
                  {user_groups?.includes("Supervisor") && (
                    <NavItem title="Plan" link={URLs.POHPlanner.path} />
                  )}
                </CollapsableNavItem>
              )}
              {userdeptList.find((dept) => dept.dept_name === "Mechanical") &&
                user_groups?.includes("Writer") && (
                  <NavItem
                    title="On Pit Attention"
                    link={URLs.OnPitPlanner.path}
                    className="text-center logo"
                  />
                )}
              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem
                title="Complaints Mgmt"
                link={URLs.ComplaintsManagement.path}
                icon={faClipboardCheck}
                className="text-center logo"
              />
              {userdeptList.find((dept) => dept.dept_name === "Mechanical") &&
                (user_groups?.includes("ACDO") ||
                  user_groups?.includes("Super Admin") ||
                  user_groups?.includes("CDO")) && (
                  <CollapsableNavItem
                    eventKey="trc-management/"
                    title="TRC Management"
                    icon={faTrain}
                    className="text-center logo"
                  >
                    <NavItem title="Trains" link={URLs.TrainManagement.path} />
                    <NavItem title="Rakes" link={URLs.RakeManagement.path} />
                    <NavItem title="Coaches" link={URLs.CoachManagement.path} />
                  </CollapsableNavItem>
                )}
              {userdeptList.find((dept) => dept.dept_name === "Mechanical") &&
                user_groups?.includes("Super Admin") && (
                  <NavItem
                    title="User Management"
                    link={URLs.UserManagement.path}
                    icon={faUsers}
                    className="text-center logo"
                  />
                )}
              {userdeptList.find((dept) => dept.dept_name === "Mechanical") &&
                (user_groups?.includes("Super Admin") ||
                  user_groups?.includes("ACDO")) && (
                  <NavItem
                    title="Staff Management"
                    link={URLs.StaffManagement.path}
                    icon={faUsers}
                    className="text-center logo"
                  />
                )}
              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem
                title="Support"
                link={URLs.Support.path}
                className="text-center logo"
              />

              <Dropdown.Divider className="my-3 border-indigo" />

              {/* <NavItem
                external
                title="Role Management"
                link=""
                target="_blank"
                icon={faChartPie}
                className="text-center logo"
              />

              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem
                external
                title="Search"
                link=""
                target="_blank"
                icon={faMagnifyingGlass}
                className="text-center logo"
              />

              <Dropdown.Divider className="my-3 border-indigo" />
              <NavItem
                external
                title="Settings"
                link=""
                target="_blank"
                icon={faGear}
                className="text-center logo"
              /> */}

              {/* <Dropdown.Divider className="my-3 border-indigo" /> */}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
