import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { InputGroup } from "@themesberg/react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../../axios";
import { FormatSimpleDate } from "../../../components/dateformat";
import { AlertMessage } from "../ioh/diagnose/formHandling/formComponents";
import ClearLocalStorage from "../../../components/clearLocalStorage";
import DiagnoseOnPit from "./DiagnoseOnPit";
import { Spinner } from "react-bootstrap";

const OnPit = () => {
  const [coachOptions, updateCoachOptions] = useState([]);
  const [plannerData, setPlannerData] = useState(null);
  const [coachID, setCoachID] = useState(null);

  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const getData = async () => {
    //Loads data for first page for typehead list
    try {
      const res = await axiosInstance.get(`assets/coaches/`, {});
      const data = res.data;
      setPlannerData(data);
      ClearLocalStorage();
      const tempCoachData = [];
      Object.values(data).forEach((item) => {
        tempCoachData.push(item.coach_id);
      });
      updateCoachOptions(tempCoachData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => getData(), []);

  const handleCoachMarking = async (coachId, onPitData) => {
    try {
      // send on pit data in patch req
      const res = await axiosInstance.patch(`assets/coaches/${coachId}/`, {
        on_pit_data: onPitData,
      });
      ClearLocalStorage();
      setShowSuccessAlert(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setSelected();
    setTypeAheadValue(e[0]);
    setCoachID(null);
    setShowSuccessAlert(false);
  };

  const handleFilterButtonSubmit = (e) => {
    e.preventDefault();
    handleCoachNumberSubmit(typeAheadValue);
  };

  const handleCoachNumberSubmit = (coachID) => {
    const selectedCoach = plannerData.find((item) => item.coach_id === coachID);
    if (selectedCoach) {
      setCoachID(selectedCoach.coach_dbid);
    }
    console.log(coachID);
  };

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>On Pit Attention</h6>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleFilterButtonSubmit}>
            <Row>
              <Col sm="4">
                <Form.Group className="">
                  <InputGroup name="coach_id">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Typeahead
                      id="basic-example"
                      onChange={handleChange}
                      options={coachOptions}
                      placeholder="Enter Coach Number..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCoachNumberSubmit(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm="2">
                <Button variant="outline-gray" className="" type="submit">
                  Lookup
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        {plannerData === null && (
          <Card.Body
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              style={{
                width: "100px",
                height: "100px",
              }}
              animation="border"
              role="status"
            ></Spinner>
          </Card.Body>
        )}
      </Card>

      {showSuccessAlert && (
        <div className="my-2 d-flex justify-content-center">
          <AlertMessage
            variant={"success"}
            // heading={`${coachID}`}
            message={`On-Pit-Attention Done`}
          />
        </div>
      )}

      <Card className="my-2">
        {coachID && !showSuccessAlert && (
          <ShowCoachInfo
            coachID={coachID}
            handleCoachMarking={handleCoachMarking}
          />
        )}
      </Card>
    </div>
  );
};

const ShowCoachInfo = (props) => {
  const { coachID, handleCoachMarking } = props;
  const [coachData, setCoachData] = useState(null);
  const [showDiagnoseForm, setShowDiagnoseForm] = useState(false);

  useEffect(() => {
    //Loads data for first page
    axiosInstance
      .get(`assets/coaches/${coachID}`, {})
      .then((res) => {
        const data = res.data;
        setCoachData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [coachID]);

  const handleOnPitDiagnoseClick = () => {
    setShowDiagnoseForm(true);
  };

  const handleDiagnosisOnPit = (onPitData) => {
    handleCoachMarking(coachID, onPitData);
  };

  return (
    <>
      {coachData && (
        <Card className="text-center">
          <Card.Body>
            <Row>
              <Col sm="4">
                <Table hover>
                  <tbody>
                    <tr>
                      <th>Coach No</th>
                      <td>{coachData.coach_id}</td>
                    </tr>
                    <tr>
                      <th>Coach Type</th>
                      <td>{`${coachData.coach_properties?.coach_make} ${coachData.coach_properties?.coach_type} ${coachData.coach_properties?.coach_level}`}</td>
                    </tr>
                    <tr>
                      <th>Rake/Train</th>
                      <td>
                        {coachData.rake_details?.map((item, index) => {
                          return (
                            <span
                              key={`rake_details-${index}`}
                              className="mx-1"
                            >
                              {item.rake_name}
                            </span>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col sm="4">
                <Table hover>
                  <tbody>
                    <tr>
                      <th>POH Date</th>
                      <td>{FormatSimpleDate(coachData.p_date)}</td>
                    </tr>
                    <tr>
                      <th>IOH Date</th>
                      <td>{FormatSimpleDate(coachData.ioh_due_date)}</td>
                    </tr>
                    <tr>
                      <th>Return Date</th>
                      <td>{FormatSimpleDate(coachData.r_date)}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col sm="4">
                <Table hover>
                  <tbody>
                    <tr>
                      <th>POH Station</th>
                      <td>{coachData.p_shop}</td>
                    </tr>
                    <tr>
                      <th>Data Updated on</th>
                      <td>
                        {FormatSimpleDate(coachData.last_data_update_date)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            {!showDiagnoseForm && (
              <Button
                variant="outline-warning"
                className="my-3"
                onClick={handleOnPitDiagnoseClick}
              >
                Start
              </Button>
            )}
          </Card.Body>
        </Card>
      )}
      {showDiagnoseForm && (
        <DiagnoseOnPit handleDiagnosisOnPit={handleDiagnosisOnPit} />
      )}
    </>
  );
};

export default OnPit;
