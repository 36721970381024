export const OnPitItems = [
    {
        asset_l1_dbid: 162,
        asset_l1_name: "V Belt",
        asset_l1_quantity: 1,
    },
    {
        asset_l1_dbid: 210,
        asset_l1_name: "P Tube",
        asset_l1_quantity: 16,
    },
    {
        asset_l1_dbid: 211,
        asset_l1_name: "Buffer Case",
        asset_l1_quantity: 4,
    },
    {
        asset_l1_dbid: 212,
        asset_l1_name: "Buffer Plunger",
        asset_l1_quantity: 4,
    },
    {
        asset_l1_dbid: 213,
        asset_l1_name: "Dashpot Spring",
        asset_l1_quantity: 16,
    },
    {
        asset_l1_dbid: 214,
        asset_l1_name: "Oil Cup",
        asset_l1_quantity: 16,
    },
];

export const OtherItems = [
    {
      asset_l1_dbid: 209,
      asset_l1_name: "Other",
      asset_l1_quantity: 1,
    },
  ];