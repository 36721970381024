import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  MapAssetL1WithAccordion,
  MapAssetL1WithoutAccordion,
} from "./assetForm";

const CategoryAccordion = (props) => {
  const {
    eventKey,
    label,
    categoryData,
    updateCoachFormData,
    updateInitialData,
    mode = "accordion",
  } = props;
  return (
    <Accordion.Item eventKey={eventKey} className="no__background">
      <Accordion.Button
        variant="link"
        className="w-100 d-flex justify-content-between product__button"
        bg="light"
      >
        <div className="d-flex justify-content-between">
          <span className="h6 mb-0 fw-bold">{label}</span>
        </div>
      </Accordion.Button>
      <Accordion.Body>
        <Accordion>
          <Row>
            {categoryData.map((asset, index) => {
              return (
                <Col
                  sm="6"
                  key={`${eventKey}_asset_${asset.asset_l1_dbid}${index}`}
                >
                  {mode === "nsm" && (
                    <MapAssetL1WithoutAccordion
                      assetCategory={label}
                      assetL1={asset}
                      index={index}
                      callback={updateCoachFormData}
                      callbackinit={updateInitialData}
                      mode={mode}
                      colSize="12"
                    />
                  )}
                  {mode === "accordion" && (
                    <MapAssetL1WithAccordion
                      assetCategory={label}
                      assetL1={asset}
                      index={index}
                      callback={updateCoachFormData}
                      callbackinit={updateInitialData}
                    />
                  )}
                  {mode === "on-pit" && (
                    <MapAssetL1WithAccordion
                      assetCategory={label}
                      assetL1={asset}
                      index={index}
                      callback={updateCoachFormData}
                      mode={mode}
                      callbackinit={updateInitialData}
                    />
                  )}
                  
                </Col>
              );
            })}
          </Row>
        </Accordion>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default CategoryAccordion;
