import React, { useState, useContext, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checklistDataL1, checklistDataL2 } from "./formData";
import { nsmchecklistDataL1 } from "./nsmformData";
import { GenerateFormComponent } from "./formComponents";

export const MapAssetL1WithoutAccordion = (props) => {
  const {
    assetCategory = null,
    assetL1,
    index,
    callback,
    callbackinit,
    colSize = 6,
    compartmentNum = null,
    mode = null,
  } = props;

  let correspondingCheckListL1;
  if (mode === "nsm") {
    correspondingCheckListL1 = nsmchecklistDataL1.find(
      (item) => item.asset_name === assetL1.asset_l1_name
    );
  } else {
    correspondingCheckListL1 = checklistDataL1.find(
      (item) => item.asset_name === assetL1.asset_l1_name
    );
  }
  const numOfItems = assetL1.asset_l1_quantity;
  // console.log(assetL1);

  return (
    <Row>
      {correspondingCheckListL1 && (
        <Col sm={colSize}>
          <GenerateAssetL1Form
            assetCategory={assetCategory}
            assetL1={assetL1}
            correspondingCheckListL1={correspondingCheckListL1}
            itemNum={0}
            callback={callback}
            callbackinit={callbackinit}
            compartmentNum={compartmentNum}
            parentQuanity={numOfItems}
          />
        </Col>
      )}
    </Row>
  );
};

export const MapAssetL1WithAccordion = (props) => {
  const {
    assetCategory = "",
    assetL1,
    index,
    callback,
    mode,
    callbackinit,
  } = props;
  let correspondingCheckListL1;
  if (mode === "on-pit") {
    correspondingCheckListL1 = nsmchecklistDataL1.find(
      (item) => item.asset_name === assetL1.asset_l1_name
    );
  } else {
    correspondingCheckListL1 = checklistDataL1.find(
      (item) => item.asset_name === assetL1.asset_l1_name
    );
  }
  const numOfItems = assetL1.asset_l1_quantity;

  // console.log(correspondingCheckListL1);
  // console.log(assetL1);

  const [isCurrentEventKey, updateIsCurrentEventKey] = useState(false);
  const { activeEventKey } = useContext(AccordionContext);
  useEffect(() => {
    //Loads data for first page
    if (activeEventKey === `assetL1_${assetL1.asset_l1_dbid}${index}`) {
      updateIsCurrentEventKey(true);
      // console.log(assetL1.asset_l1_dbid);
    } else {
      updateIsCurrentEventKey(false);
    }
  }, [activeEventKey]);

  return (
    <div>
      <Accordion.Item
        eventKey={`assetL1_${assetL1.asset_l1_dbid}${index}`}
        className="no__background"
      >
        <Accordion.Button
          variant="link"
          className="w-100 d-flex justify-content-between"
          style={{
            backgroundColor: isCurrentEventKey ? "PowderBlue" : "#eaedf2",
          }}
        >
          <div className="d-flex justify-content-between">
            <span className="h6 mb-0 fw-bold">
              {assetL1.asset_l1_name
                ? assetL1.asset_l1_name
                : assetL1.asset_name}
            </span>
          </div>
        </Accordion.Button>
        <Accordion.Body>
          <Row>
            {correspondingCheckListL1 &&
              [...Array(assetL1.asset_l1_quantity).keys()].map((item) => {
                return (
                  <Col sm="6" key={`${assetL1.asset_l1_dbid}_${item}`}>
                    <GenerateAssetL1Form
                      assetCategory={assetCategory}
                      assetL1={assetL1}
                      correspondingCheckListL1={correspondingCheckListL1}
                      itemNum={item}
                      callback={callback}
                      callbackinit={callbackinit}
                      parentQuanity={numOfItems}
                    />
                  </Col>
                );
              })}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

const GenerateAssetL1Form = (props) => {
  const {
    assetCategory,
    assetL1,
    correspondingCheckListL1,
    itemNum,
    callback,
    callbackinit,
    compartmentNum = null,
    parentQuanity,
  } = props;

  let customAssetName = "";
  if (assetCategory) {
    customAssetName =
      parentQuanity > 1
        ? `${assetCategory} ${assetL1.asset_l1_name} ${itemNum + 1}`
        : `${assetCategory} ${assetL1.asset_l1_name}`;
  } else {
    customAssetName =
      parentQuanity > 1
        ? `${assetL1.asset_l1_name} ${itemNum + 1}`
        : `${assetL1.asset_l1_name}`;
  }
  if (compartmentNum !== null) {
    customAssetName = `${customAssetName} comp(${compartmentNum + 1})`;
  } else {
    customAssetName = `${customAssetName}`;
  }
  const [whetherShowNextQue, updateWhetherShowNextQue] = useState(false);

  const initialData = {
    asset_l1_dbid: assetL1.asset_l1_dbid,
    response: correspondingCheckListL1.default_response,
    compartment: null,
    type: correspondingCheckListL1.default_type,
    issuesL1: [],
    issuesL2: {},
    readings: correspondingCheckListL1.default_readings,
    additional_data: null,
  };
  const [formDataL1, updateFormDataL1] = useState(initialData);
  const [whetherShowEditButton, updateWhetherShowEditButton] = useState(false);
  const [clearIssues, setClearIssues] = useState(false);

  const handleModalData = (issueData) => {
    updateFormDataL1({
      ...formDataL1,
      issuesL2: issueData,
    });
    callback(`${customAssetName}`, {
      ...formDataL1,
      issuesL2: issueData,
    });
  };

  useEffect(() => {
    //Loads data for first page
    callbackinit(customAssetName, initialData);
  }, []);

  const handleClearingIssues = () => {
    console.log(customAssetName);
    let filteredObj = Object.keys(localStorage).filter((item) =>
      item.includes(customAssetName)
    );
    filteredObj.forEach((item) => localStorage.removeItem(item));
  };

  const handleResponse = (data) => {
    // console.log(initialData);
    const {
      response,
      type = null,
      readings = null,
      issuesL1 = null,
      additional_data = null,
      clear_nsm_selection = false,
    } = data;
    console.log(clear_nsm_selection);
    if (formDataL1 !== initialData) {
      updateWhetherShowEditButton(true);
    } else if (formDataL1 === initialData) {
      updateWhetherShowEditButton(false);
    }

    let tempResponse = { ...formDataL1 };
    tempResponse["response"] = response;
    if (type) {
      tempResponse["type"] = type;
    }
    if (issuesL1) {
      tempResponse["issuesL1"] = issuesL1;
    }
    if (readings) {
      tempResponse["readings"] = readings;
    }
    if (additional_data) {
      tempResponse["additional_data"] = additional_data;
    }

    if (response === correspondingCheckListL1.transition_key) {
      setClearIssues(false);
      updateWhetherShowNextQue(true);
      updateFormDataL1(tempResponse);
      callback(`${customAssetName}`, tempResponse);
    } else if (response !== correspondingCheckListL1.transition_key) {
      setClearIssues(true);
      handleClearingIssues();
      updateWhetherShowNextQue(false);
      updateWhetherShowEditButton(false);
      tempResponse["issuesL2"] = {};
      updateFormDataL1(tempResponse);
      callback(`${customAssetName}`, tempResponse);
    }
    if (clear_nsm_selection) {
      setClearIssues(true);
      handleClearingIssues();
    }
  };

  return (
    <Col xs="12">
      <Row>
        <Col xs={assetCategory === null ? 12 : 10} className="mr-0">
          <GenerateFormComponent
            key={`${assetL1.asset_l1_dbid}_${itemNum}`}
            asset={assetL1}
            checklist={correspondingCheckListL1}
            itemNum={itemNum}
            callback={handleResponse}
            whetherSingleItem={parentQuanity < 2}
          />
        </Col>
        <Col xs={assetCategory === null ? 0 : 2} className="ml-0 mr-2">
          {whetherShowEditButton && (
            <Button
              variant="outline-warning"
              size="sm"
              onClick={(e) => updateWhetherShowNextQue(true)}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          )}
        </Col>

        <ModalToShowL2
          assetL1={assetL1}
          customAssetName={customAssetName}
          itemNum={itemNum}
          whetherShowNextQue={whetherShowNextQue}
          updateWhetherShowNextQue={updateWhetherShowNextQue}
          handleModalData={handleModalData}
          clearIssues={clearIssues}
        />
      </Row>
    </Col>
  );
};

const ModalToShowL2 = (props) => {
  const {
    assetL1,
    customAssetName,
    itemNum,
    whetherShowNextQue,
    updateWhetherShowNextQue,
    handleModalData,
    clearIssues,
  } = props;
  const [issueData, updateIssueData] = useState({});
  const handleClose = () => {
    // setshowModal(false);
    updateWhetherShowNextQue(false);
  };

  const handleIssues = (issuingAsset, assetL2Info) => {
    console.log(issuingAsset, assetL2Info);
    updateIssueData({ ...issueData, [issuingAsset]: assetL2Info });
  };

  const handleIssueSubmit = (e) => {
    e.preventDefault();
    // setshowModal(false);
    updateWhetherShowNextQue(false);
    handleModalData(issueData);
  };

  useEffect(() => {
    //Loads data for first page
    if (clearIssues) {
      updateIssueData({});
    }
  }, [clearIssues]);
  // if (!whetherShowNextQue) {
  //   return null;
  // }
  return (
    <Modal
      show={whetherShowNextQue}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ display: whetherShowNextQue ? "block" : "none" }}
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`${customAssetName}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Accordion> */}
          <Row>
            {assetL1.asset_l2 &&
              assetL1.asset_l2.map((assetl2, index) => {
                return (
                  <MapAssetL2
                    key={`${assetL1.asset_l1_name}_${itemNum}_asset_l2_${index}`}
                    assetL2={assetl2}
                    index={index}
                    callback={handleIssues}
                    whetherShowNextQue={whetherShowNextQue}
                    extraKey={customAssetName}
                  />
                );
              })}
          </Row>
          {/* </Accordion> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleIssueSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const MapAssetL2 = (props) => {
  const { assetL2, index, callback, extraKey } = props;
  let correspondingCheckListL2 = checklistDataL2.find(
    (item) => item.asset_name === assetL2.asset_l2_name
  );
  // console.log(correspondingCheckListL2);
  const numOfItems = assetL2.asset_l2_quantity;

  return (
    <>
      {assetL2.asset_l2_quantity > 0 && (
        <Row className="border p-2 m-1">
          {correspondingCheckListL2 &&
            [...Array(assetL2.asset_l2_quantity).keys()].map((item) => {
              return (
                <Col sm="6" key={`${assetL2.asset_l2_dbid}_${index}_${item}`}>
                  <GenerateAssetL2Form
                    assetL2={assetL2}
                    correspondingCheckListL2={correspondingCheckListL2}
                    itemNum={item}
                    callback={callback}
                    extraKey={extraKey}
                    parentQuanity={numOfItems}
                  />
                </Col>
              );
            })}
        </Row>
      )}
    </>
  );
};

const GenerateAssetL2Form = (props) => {
  const {
    assetL2,
    correspondingCheckListL2,
    itemNum,
    extraKey,
    callback,
    parentQuanity,
  } = props;
  // const customAssetName = `${assetL2.asset_l2_name} ${itemNum + 1}`;
  const customAssetName =
    parentQuanity > 1
      ? `${assetL2.asset_l2_name} ${itemNum + 1}`
      : `${assetL2.asset_l2_name}`;
  const initialData = {
    asset_l2_dbid: assetL2.asset_l2_dbid,
    response: correspondingCheckListL2.default_response,
  };
  const [formDataL2, updateFormDataL2] = useState(initialData);
  const handleResponse = (data) => {
    // console.log(response);
    updateFormDataL2({
      ...formDataL2,
      response: data.response,
    });
    callback(`${customAssetName}`, {
      ...formDataL2,
      response: data.response,
    });
  };
  return (
    <Row>
      <Col sm="12">
        <GenerateFormComponent
          key={`${assetL2.asset_l2_dbid}_${itemNum}`}
          asset={assetL2}
          checklist={correspondingCheckListL2}
          itemNum={itemNum}
          callback={handleResponse}
          extraKey={extraKey}
          whetherSingleItem={parentQuanity < 2}
        />
      </Col>
    </Row>
  );
};
