import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { URLs } from "../routes";

// pages
import Settings from "./Settings";
import Signin from "./auth/Signin";
import Signup from "./auth/Signup";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import LogOut from "./auth/Logout";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import { Modules } from "./modules/modules";
import { IOHDiagnosis } from "./modules/ioh/diagnose/iohDiagnose";
import { IOHIssues } from "./modules/ioh/addressIssues/iohIssues";
import { IOHPlanner } from "./modules/ioh/plan/planner";
import POHPlanner from "./modules/poh/plan/POHPlanner";
import { NSMPlanner } from "./modules/ioh-nsm/planner";
import { WebPage } from "../website/website";
import DashboardOMobile from "./dashboard/mobile";
import SickLineDashboard from "./dashboard/sickLine";
import InventoryDashboard from "./dashboard/inventory";
import RailMadadDashboard from "./dashboard/railMadad";
import UserManagement from "./modules/user-management/UserManagement";
import Trains from "./modules/TRC-management/Train/Trains";
import Complaints from "./modules/complaints-management/complaints";
import Coaches from "./modules/TRC-management/Coach/Coaches";
import Rakes from "./modules/TRC-management/Rake/Rakes";
import OnPit from "./modules/on-pit/OnPit";
import Support from "./modules/support/Support";
import MainStaffManagement from "./modules/staff-management/MainStaffManagement";
import CoachSummary from "./dashboard/coachSummary";
import RailMadadMech from "./dashboard/RailMadadMech";
import RailMadadElec from "./dashboard/RailMadadElec";

const RouteWithLoader = ({ element: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {/* <Preloader show={loaded ? false : true} />  */}
          <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ componentName: componentName, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const isAuthenticated = localStorage.getItem("access_token") ? true : false;
  // const isAuthenticated = true;
  // const isVerified =
  //   localStorage.getItem("group") && localStorage.getItem("manifest")
  //     ? true
  //     : false;

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isAuthenticated ? (
        <>
          {/* <Preloader show={loaded ? false : true} /> */}
          <Sidebar />

          <main className="content">
            <Navbar />
            {componentName === "Modules" && <Modules />}
            {componentName === "IOHDiagnosis" && <IOHDiagnosis />}
            {componentName === "IOHIssues" && <IOHIssues />}
            {componentName === "IOHPlanner" && <IOHPlanner />}
            {componentName === "POHPlanner" && <POHPlanner />}
            {componentName === "NSMPlanner" && <NSMPlanner />}
            {componentName === "DashboardOMobile" && <DashboardOMobile />}
            {componentName === "UserManagement" && <UserManagement />}
            {componentName === "MainStaffManagement" && <MainStaffManagement />}
            {componentName === "TrainManagement" && <Trains />}
            {componentName === "ComplaintsManagement" && <Complaints />}
            {componentName === "RakeManagement" && <Rakes />}
            {componentName === "CoachManagement" && <Coaches />}
            {componentName === "InventoryDashboard" && <InventoryDashboard />}
            {componentName === "RailMadadDashboard" && <RailMadadDashboard />}
            {componentName === "RailMadadMech" && <RailMadadMech />}
            {componentName === "RailMadadElec" && <RailMadadElec />}
            {componentName === "SickLineDashboard" && <SickLineDashboard />}
            {componentName === "CoachSummary" && <CoachSummary />}
            {componentName === "OnPitPlanner" && <OnPit />}
            {componentName === "Support" && <Support />}
            <Footer />
          </main>
        </>
      ) : (
        <Navigate to={URLs.Signin.path} />
      )}
    </>
  );
};

const HomePage = () => {
  return (
    // <div>
    //   <CheckListForm />
    // </div>
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route exact path={URLs.WebPage.path} element={<WebPage />} />
      <Route
        exact
        path={URLs.DashboardOMobile.path}
        element={
          <>
            <RouteWithSidebar componentName="DashboardOMobile" />
          </>
        }
      />
      <Route
        exact
        path={URLs.SickLineDashboard.path}
        element={
          <>
            <RouteWithSidebar componentName="SickLineDashboard" />
          </>
        }
      />
      <Route
        exact
        path={URLs.InventoryDashboard.path}
        element={
          <>
            <RouteWithSidebar componentName="InventoryDashboard" />
          </>
        }
      />
      <Route
        exact
        path={URLs.RailMadadDashboard.path}
        element={
          <>
            <RouteWithSidebar componentName="RailMadadDashboard" />
          </>
        }
      />
      <Route
        exact
        path={URLs.RailMadadMecDashboard.path}
        element={
          <>
            <RouteWithSidebar componentName="RailMadadMech" />
          </>
        }
      />
      <Route
        exact
        path={URLs.RailMadadElecDashboard.path}
        element={
          <>
            <RouteWithSidebar componentName="RailMadadElec" />
          </>
        }
      />
      <Route
        exact
        path={URLs.CoachSummary.path}
        element={
          <>
            <RouteWithSidebar componentName="CoachSummary" />
          </>
        }
      />

      <Route
        exact
        path={URLs.Modules.path}
        element={
          <>
            <RouteWithSidebar componentName="Modules" />
          </>
        }
      />
      <Route
        exact
        path={URLs.ComplaintsManagement.path}
        element={
          <>
            <RouteWithSidebar componentName="ComplaintsManagement" />
          </>
        }
      />
      <Route
        exact
        path={URLs.IOHDiagnosis.path}
        element={
          <>
            <RouteWithSidebar componentName="IOHDiagnosis" />
          </>
        }
      />
      <Route
        exact
        path={URLs.IOHIssues.path}
        element={
          <>
            <RouteWithSidebar componentName="IOHIssues" />
          </>
        }
      />
      <Route
        exact
        path={URLs.IOHPlanner.path}
        element={
          <>
            <RouteWithSidebar componentName="IOHPlanner" />
          </>
        }
      />
      <Route
        exact
        path={URLs.POHPlanner.path}
        element={
          <>
            <RouteWithSidebar componentName="POHPlanner" />
          </>
        }
      />
      <Route
        exact
        path={URLs.NSMPlanner.path}
        element={
          <>
            <RouteWithSidebar componentName="NSMPlanner" />
          </>
        }
      />
      <Route
        exact
        path={URLs.OnPitPlanner.path}
        element={
          <>
            <RouteWithSidebar componentName="OnPitPlanner" />
          </>
        }
      />
      <Route
        exact
        path={URLs.Support.path}
        element={
          <>
            <RouteWithSidebar componentName="Support" />
          </>
        }
      />
      <Route
        exact
        path={URLs.UserManagement.path}
        element={
          <>
            <RouteWithSidebar componentName="UserManagement" />
          </>
        }
      />
      <Route
        exact
        path={URLs.StaffManagement.path}
        element={
          <>
            <RouteWithSidebar componentName="MainStaffManagement" />
          </>
        }
      />
      <Route
        exact
        path={URLs.TrainManagement.path}
        element={
          <>
            <RouteWithSidebar componentName="TrainManagement" />
          </>
        }
      />
      <Route
        exact
        path={URLs.RakeManagement.path}
        element={
          <>
            <RouteWithSidebar componentName="RakeManagement" />
          </>
        }
      />
      <Route
        exact
        path={URLs.CoachManagement.path}
        element={
          <>
            <RouteWithSidebar componentName="CoachManagement" />
          </>
        }
      />
      <Route exact path={URLs.Signin.path} element={<Signin />} />
      <Route exact path={URLs.Signup.path} element={<Signup />} />
      <Route exact path={URLs.Logout.path} element={<LogOut />} />
      <Route
        exact
        path={URLs.ForgotPassword.path}
        element={<ForgotPassword />}
      />
      <Route exact path={URLs.ResetPassword.path} element={<ResetPassword />} />
      <Route exact path={URLs.NotFound.path} element={<NotFoundPage />} />
      <Route exact path={URLs.ServerError.path} element={<ServerError />} />

      {/* pages */}

      <Route exact path={URLs.Settings.path} element={<Settings />} />
      {/* <Route path={URLs.NotFound.path} /> */}
    </Routes>
  );
};

export default HomePage;
