export const URLs = {
  // pages
  WebPage: { path: "/" },
  DashboardOMobile: { path: "/reports/mobile" },
  SickLineDashboard: { path: "/reports/sick-line" },
  InventoryDashboard: { path: "/reports/inventory" },
  RailMadadDashboard: { path: "/reports/rail-madad" },
  RailMadadMecDashboard: { path: "/reports/rail-madad/mechanical" },
  RailMadadElecDashboard: { path: "/reports/rail-madad/electrical" },
  CoachSummary: { path: "/reports/coach-summary" },
  Settings: { path: "/settings" },
  Modules: { path: "/modules" },
  IOHDiagnosis: { path: "/ioh/diagnose" },
  IOHIssues: { path: "/ioh/address-issues" },
  IOHPlanner: { path: "/ioh/plan" },
  POHPlanner: { path: "/poh/plan" },
  NSMPlanner: { path: "/ioh-nsm/plan" },
  OnPitPlanner: { path: "/on-pit/plan" },
  Support: { path: "/support" },
  UserManagement: { path: "/user-management" },
  StaffManagement: { path: "/staff-management" },
  TrainManagement: { path: "/trc-management/trains" },
  RakeManagement: { path: "/trc-management/rake" },
  CoachManagement: { path: "/trc-management/coach" },
  ComplaintsManagement: { path: "/complaints" },
  IntelligentReporting: { path: "/intelligent-reporting" },
  Signin: { path: "/sign-in" },
  Logout: { path: "/logout" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },
  NotFound: { path: "/404" },
  ServerError: { path: "/500" },
};
