import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  faSearch,
  faChalkboardUser,
  faGears,
  faScrewdriverWrench,
  faClipboard,
  faCalendarDay,
  faUsers,
  faTrainSubway,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import "./ioh.css";
import { URLs } from "../../routes";
import { axiosInstance } from "../../axios";

export const Modules = (props) => {
  let navigate = useNavigate();
  const userdeptList = JSON.parse(localStorage.getItem("user_dept"));

  let user_groups = [];
  try {
    user_groups = JSON.parse(localStorage.getItem("user_group"));
  } catch {
    console.log("Subscribed Products list empty");
  }

  useEffect(() => {
    getCoachList();
    getDeptList();
  }, []);

  const getCoachList = async () => {
    try {
      const res = await axiosInstance.get(`assets/stations/`, {});
      const stationData = {};
      // console.log(res.data);
      res.data.forEach((station) => {
        stationData[station.station_code] = station.station_dbid;
      });
      localStorage.setItem("StationList", JSON.stringify(stationData));
    } catch (error) {
      console.log(error);
    }
  };
  const getDeptList = async () => {
    try {
      const res = await axiosInstance.get(`assets/departments/`, {});
      // console.log(res.data)
      let tempDept = res.data;
      localStorage.setItem("DeptList", JSON.stringify(tempDept));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card className="page-container">
      <Row>
        <Col lg="3">
          <Card
            as="a"
            className="m-2 text-center bg-sky-blue"
            onClick={(e) => {
              let path = null;
              if (
                user_groups?.includes("SRDME") ||
                user_groups?.includes("CDO") ||
                user_groups?.includes("ACDO")
              ) {
                path = URLs.ComplaintsManagement.path;
              }
              navigate(path);
            }}
          >
            <FontAwesomeIcon className="fa-5x m-2" icon={faChalkboardUser} />
            <Card.Subtitle className="m-2 p-2">
              Complaints Management
            </Card.Subtitle>
          </Card>
        </Col>
        {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
          <Col lg="3">
            <Card
              as="a"
              className="m-2 text-center bg-sky-blue"
              onClick={(e) => {
                let path = null;
                if (user_groups?.includes("Supervisor")) {
                  path = URLs.NSMPlanner.path;
                }
                navigate(path);
              }}
            >
              <FontAwesomeIcon className="fa-5x m-2" icon={faGears} />
              <Card.Subtitle className="m-2 p-2">IOH-NSM</Card.Subtitle>
            </Card>
          </Col>
        )}
        {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
          <Col lg="3">
            <Card
              as="a"
              className="m-2 text-center bg-sky-blue"
              onClick={(e) => {
                let path = null;
                if (user_groups?.includes("ACDO")) {
                  path = URLs.IOHPlanner.path;
                } else if (user_groups?.includes("Supervisor")) {
                  path = URLs.IOHDiagnosis.path;
                } else if (user_groups?.includes("Writer")) {
                  path = URLs.IOHDiagnosis.path;
                }
                navigate(path);
              }}
            >
              <FontAwesomeIcon
                className="fa-5x m-2"
                icon={faScrewdriverWrench}
              />
              <Card.Subtitle className="m-2 p-2">IOH Schedule</Card.Subtitle>
            </Card>
          </Col>
        )}
        {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
          <Col lg="3">
            <Card
              as="a"
              className="m-2 text-center bg-sky-blue"
              onClick={(e) => {
                let path = null;
                if (
                  user_groups?.includes("SRDME") ||
                  user_groups?.includes("CDO") ||
                  user_groups?.includes("ACDO")
                ) {
                  path = URLs.SickLineDashboard.path;
                }
                navigate(path);
              }}
            >
              <FontAwesomeIcon className="fa-5x m-2" icon={faClipboard} />
              <Card.Subtitle className="m-2 p-2">
                Reports Management
              </Card.Subtitle>
            </Card>
          </Col>
        )}
        {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
          <Col lg="3">
            <Card
              as="a"
              className="m-2 text-center bg-sky-blue"
              onClick={(e) => {
                let path = null;
                if (user_groups?.includes("ACDO")) {
                  path = URLs.POHPlanner.path;
                } else if (user_groups?.includes("Supervisor")) {
                  path = URLs.POHPlanner.path;
                }
                navigate(path);
              }}
            >
              <FontAwesomeIcon className="fa-5x m-2" icon={faCalendarDay} />
              <Card.Subtitle className="m-2 p-2">POH Planning</Card.Subtitle>
            </Card>
          </Col>
        )}
        {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
          <Col lg="3">
            <Card
              as="a"
              className="m-2 text-center bg-sky-blue"
              onClick={(e) => {
                let path = null;
                if (user_groups?.includes("ACDO")) {
                  path = URLs.TrainManagement.path;
                } else if (user_groups?.includes("Supervisor")) {
                  path = URLs.TrainManagement.path;
                }
                navigate(path);
              }}
            >
              <FontAwesomeIcon className="fa-5x m-2" icon={faTrainSubway} />
              <Card.Subtitle className="m-2 p-2">
                Train-Rake-Coach Management{" "}
              </Card.Subtitle>
            </Card>
          </Col>
        )}
        {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
          <Col lg="3">
            <Card
              as="a"
              className="m-2 text-center bg-sky-blue"
              onClick={(e) => {
                let path = null;
                if (user_groups?.includes("Super Admin")) {
                  path = URLs.UserManagement.path;
                }
                navigate(path);
              }}
            >
              <FontAwesomeIcon className="fa-5x m-2" icon={faUsers} />
              <Card.Subtitle className="m-2 p-2">User Management</Card.Subtitle>
            </Card>
          </Col>
        )}
        {userdeptList.find((dept) => dept.dept_name === "Mechanical") && (
          <Col lg="3">
            <Card
              as="a"
              className="m-2 text-center bg-sky-blue"
              onClick={(e) => {
                let path = null;
                if (user_groups?.includes("ACDO")) {
                  path = URLs.StaffManagement.path;
                }
                //  else if (user_groups?.includes("Supervisor")) {
                //   path = URLs.StaffManagement.path;
                // }
                navigate(path);
              }}
            >
              <FontAwesomeIcon className="fa-5x m-2" icon={faUsers} />
              <Card.Subtitle className="m-2 p-2">
                Staff Management
              </Card.Subtitle>
            </Card>
          </Col>
        )}
      </Row>
    </Card>
  );
};
