import React, { useState } from "react";
import {
  Col,
  Button,
  Form,
  Row,
  Accordion,
  ButtonGroup,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FormatStringDate } from "../../../components/dateformat";
import { faCheckToSlot, faXmark } from "@fortawesome/free-solid-svg-icons";

const FilterComplaints = (props) => {
  const { handleFilter, dataCount } = props;
  const DeptList = JSON.parse(localStorage.getItem("DeptList"));
  const userdeptList = JSON.parse(localStorage.getItem("user_dept"));
  // console.log(userdeptList[0].dept_name);

  const initialState = {
    complaint_number: "",
    train_number: "",
    main_heading: "",
    sub_heading: "",
    depot: "",
    receiving_datetime: "",
    responsibility: "",
    created_on: "",
    department: "",
  };
  const [filterRequest, setFilterRequest] = useState(initialState);

  const handleChange = (e) => {
    let tempFormData = { ...filterRequest, [e.target.name]: e.target.value };
    setFilterRequest(tempFormData);
    submitFilterRequest(tempFormData);
  };
  const handleClearFilter = () => {
    setFilterRequest(initialState);
    submitFilterRequest(initialState);
  };
  const submitFilterRequest = (formData = filterRequest) => {
    console.log(formData);
    let parameterLink = Object.entries(formData).reduce(
      (parameterLink, [itemKey, itemValue]) => {
        if (itemValue) {
          parameterLink = `${parameterLink}&${itemKey}=${itemValue}`;
        }
        return parameterLink;
      },
      ""
    );
    console.log(parameterLink);
    handleFilter(parameterLink);
  };

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="1" className="no__background">
          <Accordion.Button className="product__button" bg="light">
            Filter Complaints{" "}
            <span className="mx-3">
              <Badge bg="primary">{dataCount}</Badge>
            </span>
          </Accordion.Button>
          <Accordion.Body>
            <Form>
              <Row>
                <Col lg="3" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Complaint No"
                    className="mb-3"
                  >
                    <Form.Control
                      name="complaint_number"
                      type="text"
                      placeholder="Complaint No"
                      value={filterRequest.complaint_number}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </FloatingLabel>
                </Col>
                <Col lg="3" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Train No"
                    className="mb-3"
                  >
                    <Form.Control
                      name="train_number"
                      type="text"
                      placeholder="Train No"
                      value={filterRequest.train_number}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </FloatingLabel>
                </Col>

                <Col lg="3" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Main Heading"
                    className="mb-3"
                  >
                    <Form.Control
                      name="main_heading"
                      type="text"
                      onChange={handleChange}
                      placeholder="Main Heading"
                      aria-label="Main Heading"
                      aria-describedby="basic-addon1"
                      value={filterRequest.main_heading}
                    />
                  </FloatingLabel>
                </Col>

                <Col lg="3" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sub Heading"
                    className="mb-3"
                  >
                    <Form.Control
                      name="sub_heading"
                      type="text"
                      onChange={handleChange}
                      placeholder="Sub Heading"
                      aria-label="Sub Heading"
                      aria-describedby="basic-addon1"
                      value={filterRequest.sub_heading}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg="3" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Depot"
                    className="mb-3"
                  >
                    <Form.Control
                      name="depot"
                      type="text"
                      onChange={handleChange}
                      placeholder="Depot"
                      aria-label="Depot"
                      aria-describedby="basic-addon1"
                      value={filterRequest.depot}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg="3" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Responsibility"
                    className="mb-3"
                  >
                    <Form.Control
                      name="responsibility"
                      type="text"
                      onChange={handleChange}
                      placeholder="Responsibility"
                      aria-label="Responsibility"
                      aria-describedby="basic-addon1"
                      value={filterRequest.responsibility}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg="3" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Receiving Datetime"
                    className="mb-3"
                  >
                    <Form.Control
                      name="receiving_datetime"
                      type="text"
                      onChange={handleChange}
                      placeholder="Receiving Datetime"
                      aria-label="Receiving Datetime"
                      aria-describedby="basic-addon1"
                      value={filterRequest.receiving_datetime}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg="3" md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Created On"
                    className="mb-3"
                  >
                    <Form.Control
                      name="created_on"
                      type="date"
                      value={filterRequest.created_on}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </FloatingLabel>
                </Col>
                <Col lg="3" md="6">
                  {userdeptList.length === 1 ? (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Department"
                      className="mb-3"
                    >
                      <Form.Control
                        name="department"
                        type="text"
                        disabled={true}
                        placeholder="department"
                        aria-label="department"
                        aria-describedby="basic-addon1"
                        value={userdeptList[0].dept_name}
                      />
                    </FloatingLabel>
                  ) : (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Department"
                      className="mb-3"
                    >
                      <Form.Select
                        value={filterRequest.department}
                        onChange={handleChange}
                        name="department"
                        required
                      >
                        <option value="">All</option>
                        {DeptList.map((dept, index) => {
                          return (
                            <option
                              value={dept.dept_dbid}
                              key={`dept_${index}`}
                            >
                              {dept.dept_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  )}
                </Col>
              </Row>

              <Row className="d-flex justify-content-center">
                <Col md="3">
                  <Button onClick={handleClearFilter} variant="outline-gray">
                    <FontAwesomeIcon icon={faXmark} />
                    <span className="mx-2">Clear Filter</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default FilterComplaints;
