import React, { useState } from 'react'
import { Button, Modal, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { PostStaffAPI } from '../../../api/StaffApi';

const CreateStaff = (props) => {
  const { handleCloseAddModal,handlePostDataOnList } = props;

  const [newStaff, setNewStaff] = useState({});

  const onChangeStaffInputs = (e) => {
    setNewStaff({ ...newStaff, [e.target.name]: e.target.value })
  }
  const handleStaffSubmit = () => {
    callPostStaffAPI();
  }
  const callPostStaffAPI =async () => {
    try {
      const res = await PostStaffAPI(newStaff);
      console.log(res.data)
      handlePostDataOnList(res.data.data);
     
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Modal show={true} size="xl" >

      <Modal.Header >
        <Modal.Title>Add Staff</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Full Name"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="full_name"
                type="text"
                required
                placeholder="name" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Designation"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="designation"
                type="text"
                required
                placeholder="designation" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="DOB"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="dob"
                type="date"
                required
                placeholder="dob" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="DOA"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="doa"
                type="date"
                required
                placeholder="doa" />
            </FloatingLabel>
          </Col>

        </Row>
        <Row>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="MOJ"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="moj"
                type="text"
                required
                placeholder="moj" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="DOP"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="dop"
                type="date"
                required
                placeholder="dop" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Qualification"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="qualification"
                type="text"
                required
                placeholder="qualification" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Present Posting"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="present_posting"
                type="text"
                required
                placeholder="presentposting" />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Specialization"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="specialization"
                type="text"
                required
                placeholder="specialization" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Award & Year"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="award_details"
                type="text"
                required
                placeholder="award" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Hobby"
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="hobby"
                type="text"
                required
                placeholder="hobby" />
            </FloatingLabel>
          </Col>
          <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Tr. req."
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="tr_req"
                type="text"
                required
                placeholder="tr.req" />
            </FloatingLabel>
          </Col>        
        </Row>
        <Row>
        <Col sm="3">
            <FloatingLabel
              controlId="floatingInput"
              label="Mobile No."
              className="mb-3"
            >
              <Form.Control
                onChange={onChangeStaffInputs}
                name="mobile_num"
                type="number"
                required
                placeholder="mobile" />
            </FloatingLabel>
          </Col>
         
        
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAddModal}>
          Close
        </Button>
        <Button variant="success" onClick={handleStaffSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateStaff