import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateTimePicker from "react-datetime-picker";
import { axiosInstance } from "../../../axios";
import moment from "moment";
import {
  ElectricalMainHead,
  ElectricalSubHead,
  coachTypesData,
} from "./ComplaintsData";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";

const AddComplaintModal = (props) => {
  const { handleModalClose, handleComplaintAddition, trainNoList } = props;
  const DeptList = JSON.parse(localStorage.getItem("DeptList"));
  const userdeptList = JSON.parse(localStorage.getItem("user_dept"));

  // console.log(trainNoList)

  const [typeAheadValue, setTypeAheadValue] = useState("");
  const [selected, setSelected] = useState(null);
  const [trainNoList2, setTrainNoList2] = useState(
    trainNoList ? trainNoList : null
  );
  const [complaintData, updateComplaintData] = useState({});

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);

    let value = e.target.value;
    if (
      e.target.name === "receiving_datetime" ||
      e.target.name === "closing_datetime" ||
      e.target.name === "interim_reply_datetime"
    ) {
      value = moment(value).format("YYYY-MM-DD HH:mm:ss");

      console.log(value);
    } else if (e.target.name === "date_of_departure") {
      value = moment(value).format("YYYY-MM-DD HH:mm:ss");
      console.log(value);
    }

    updateComplaintData({
      ...complaintData,
      [e.target.name]: value,
    });
  };
  const handleChange3 = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
  };
  const handleChange2 = (e) => {
    let value = e.target.value;
    const numericValue = parseInt(value);

    const foundDept = DeptList.find((d) => d.dept_dbid === numericValue);
    const temp2 = foundDept ? foundDept.dept_name : "department";

    console.log(temp2);

    updateComplaintData({
      ...complaintData,
      [e.target.name]: value,
      department_name: temp2,
    });
  };
  const handleChangeTH = (e) => {
    setSelected();
    setTypeAheadValue(e[0]);
    updateComplaintData({
      ...complaintData,
      ["train_number"]: e[0],
    });
    console.log(e[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempCD = {};
    if (userdeptList.length === 1) {
      tempCD = { ...complaintData, department: userdeptList[0].dept_name };
    } else {
      tempCD = complaintData;
    }
    // console.log(tempCD);
    console.log(complaintData)
    axiosInstance
      .post(`assets/complaints/`, complaintData)
      .then((res) => {
        const data = res.data;
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
    handleModalClose();
    handleComplaintAddition(complaintData);
  };
  return (
    <>
      <Modal
        show={true}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
        backdrop="static"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>New Complaint</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card
              border
              //   className="d-flex justify-content-between align-items-center"
            >
              <Card.Body>
                <Row className="align-items-center">
                  <FormComponent
                    label="Complaint No."
                    placeholder="Complaint No."
                    type="string"
                    name="complaint_number"
                    handleChange={handleChange}
                    required={true}
                  />
                  <FormComponent
                    label="Train No."
                    placeholder="Enter Train No."
                    type="string"
                    name="train_number"
                    handleChange={handleChange}
                    required={true}
                  />
                  {userdeptList.length === 1 ? (
                    <Col lg="6" className="px-3">
                      <Form.Group as={Row}>
                        <Col sm="4" className="my-2">
                          <Form.Label>
                            Department <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Control
                            value={userdeptList[0].dept_name}
                            name="department"
                            type="string"
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  ) : (
                    <FormComponent
                      label="Department"
                      placeholder="Department"
                      type="string"
                      name="department"
                      handleChange2={handleChange2}
                    />
                  )}
                  {/* {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Electrical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Train Name"
                      placeholder="Train Name"
                      type="string"
                      name="train_name"
                      handleChange={handleChange}
                      disabled
                    />
                  )}{" "} */}
                  <Col lg="6" className="px-3"></Col>
                  <DateTimeComponent
                    label="Receiving Date Time"
                    placeholder="Date Time"
                    type="datetime"
                    name="receiving_datetime"
                    handleChange={handleChange}
                    // required={true}
                  />
                  <DateTimeComponent
                    label="Closing Date Time"
                    placeholder="Date Time"
                    type="datetime"
                    name="closing_datetime"
                    handleChange={handleChange}
                  />
                  <Col lg="6" className="px-3">
                    <Form.Group as={Row}>
                      <Col sm="4" className="my-2">
                        <Form.Label>Time Taken for Closing</Form.Label>
                      </Col>
                      <Col sm="8">
                        <Form.Control
                          placeholder="Format d:hr:min"
                          name="time_taken_for_closing"
                          onChange={handleChange}
                          type="string"
                          required={false}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Source"
                      placeholder="Source"
                      type="string"
                      name="source"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Railway"
                      placeholder="CR/SWR/WR"
                      type="string"
                      name="railway"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Owning Division"
                      placeholder="Enter Owning Division"
                      type="string"
                      name="owning_division"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Main Heading"
                      placeholder="Main Heading"
                      type="string"
                      name="main_heading"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Sub Heading"
                      placeholder="Sub Heading"
                      type="string"
                      name="sub_heading"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Electrical") ||
                    userdeptList.length > 1) && (
                    <Col lg="6" className="px-3">
                      <Form.Group as={Row}>
                        <Col sm="4" className="my-2">
                          <Form.Label>Elect. Main heading</Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => handleChange(e)}
                            name="main_heading"
                          >
                            <option value="">-</option>
                            {ElectricalMainHead.map((opt, index) => {
                              return (
                                <option
                                  value={opt}
                                  key={`${opt}_${index}`}
                                  data-dept={opt}
                                >
                                  {opt}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Electrical") ||
                    userdeptList.length > 1) && (
                    <Col lg="6" className="px-3">
                      <Form.Group as={Row}>
                        <Col sm="4" className="my-2">
                          <Form.Label>Elect. Sub heading</Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => handleChange(e)}
                            name="sub_heading"
                          >
                            <option value="">-</option>
                            {ElectricalSubHead.map((opt, index) => {
                              return (
                                <option
                                  value={opt}
                                  key={`${opt}_${index}`}
                                  data-dept={opt}
                                >
                                  {opt}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    // <FormComponent
                    //   label="SLA1/SLA2"
                    //   placeholder="SLA1/SLA2"
                    //   type="string"
                    //   name="sla_type"
                    //   handleChange={handleChange}
                    // />
                    <Col lg="6" className="px-3">
                      <Form.Group as={Row}>
                        <Col sm="4" className="my-2">
                          <Form.Label>SLA1/SLA2</Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => handleChange(e)}
                            name="sla_type"
                          >
                            <option value="">-</option>
                            <option value="SLA 1">SLA 1</option>
                            <option value="SLA 2">SLA 2</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Electrical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Channel"
                      placeholder="Channel"
                      type="string"
                      name="channel"
                      handleChange={handleChange}
                    />
                  )}{" "}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    // <FormComponent
                    //   label="Territory"
                    //   placeholder="Territory"
                    //   type="string"
                    //   name="territory"
                    //   handleChange={handleChange}
                    // />
                    <Col lg="6" className="px-3">
                      <Form.Group as={Row}>
                        <Col sm="4" className="my-2">
                          <Form.Label>Territory</Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => handleChange(e)}
                            name="territory"
                          >
                            <option value="">-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  )}
                  {/* <FormComponent
                    label="Passenger Feedback"
                    placeholder="Passenger Feedback"
                    type="string"
                    name="passenger_feedback"
                    handleChange={handleChange}
                  /> */}
                  <Col lg="6" className="px-3">
                    <Form.Group as={Row}>
                      <Col sm="4" className="my-2">
                        <Form.Label>Passenger Feedback</Form.Label>
                      </Col>
                      <Col sm="8">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => handleChange(e)}
                          name="passenger_feedback"
                        >
                          <option value="">-</option>
                          <option value="Excellent">Excellent</option>
                          <option value="Satisfactory">Satisfactory</option>
                          <option value="Unsatisfactory">Unsatisfactory</option>
                          <option value="Nil">Nil</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      onChange={handleChange}
                      rows={3}
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>
            <Card border>
              <Card.Body>
                <Row className="align-items-center">
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Date of Departure"
                      placeholder="Date of Departure"
                      type="date"
                      name="date_of_departure"
                      handleChange={handleChange}
                      required={false}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    // <FormComponent
                    //   label="Interim Reply Time"
                    //   placeholder="Reply Time"
                    //   type="time"
                    //   name="interim_reply_datetime"
                    //   handleChange={handleChange}
                    // />
                    <DateTimeComponent
                      label="Interim Reply Time"
                      placeholder="Reply Time"
                      type="datetime"
                      name="interim_reply_datetime"
                      handleChange={handleChange}
                    />
                  )}
                  <FormComponent
                    label="Coach No."
                    placeholder="Coach no"
                    type="string"
                    name="coach_number"
                    handleChange={handleChange}
                  />
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Physical Coach No."
                      placeholder="Physical Coach No"
                      type="string"
                      name="physical_coach_num"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Coach ID"
                      placeholder="Coach id"
                      type="string"
                      name="coach_id"
                      handleChange={handleChange}
                    />
                  )}

                  <Col lg="6" className="px-3">
                    <Form.Group as={Row}>
                      <Col sm="4" className="my-2">
                        <Form.Label>
                          Coach Type <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Col>
                      <Col sm="8">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => handleChange(e)}
                          name="coach_type"
                          required={true}
                        >
                          <option value="">-</option>
                          {coachTypesData.map((opt, index) => {
                            return (
                              <option
                                value={opt.coach_type}
                                key={`${opt.coach_type}_${index}`}
                                data-dept={opt}
                              >
                                {opt.coach_type}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Upcoming Station"
                      placeholder="Upcoming Station"
                      type="string"
                      name="upcoming_station"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Resolved Location"
                      placeholder="Resolved Location"
                      type="string"
                      name="resolved_location"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Resposibility"
                      placeholder="Resposibility"
                      type="string"
                      name="responsibility"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Responsible Contractor"
                      placeholder="Responsible Contractor"
                      type="string"
                      name="responsible_contractor"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Responsible Contractor Staff"
                      placeholder="Responsible Contractor Staff"
                      type="string"
                      name="responsible_contractor_staff"
                      handleChange={handleChange}
                    />
                  )}
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Mechanical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="On Duty Staff"
                      placeholder="On Duty Staff"
                      type="string"
                      name="on_duty_staff"
                      handleChange={handleChange}
                    />
                  )}
                  <FormComponent
                    label="Depot"
                    placeholder="Depot"
                    type="string"
                    name="depot"
                    handleChange={handleChange}
                  />
                  {((userdeptList.length === 1 &&
                    userdeptList[0].dept_name === "Electrical") ||
                    userdeptList.length > 1) && (
                    <FormComponent
                      label="Supervisor"
                      placeholder="Supervisor"
                      type="string"
                      name="supervisor"
                      handleChange={handleChange}
                    />
                  )}

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Action Taken & Closing Remark</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="action_taken_and_closing_remark"
                      onChange={handleChange}
                      rows={3}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="comment"
                      onChange={handleChange}
                      rows={3}
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="gray" onClick={handleModalClose}>
              Close
            </Button>
            <Button
              disabled={
                !complaintData.train_number ||
                !complaintData.train_number.trim()
              }
              variant="tertiary"
              type="submit"
            >
              Add Complaint
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const FormComponent = (props) => {
  const {
    label,
    placeholder,
    type,
    name,
    handleChange,
    handleChange2,
    required = false,
  } = props;
  const DeptList = JSON.parse(localStorage.getItem("DeptList"));

  return (
    <>
      <Col lg="6" className="px-3">
        <Form.Group as={Row}>
          <Col sm="4" className="my-2">
            <Form.Label>
              {label}{" "}
              {name === "complaint_number" ||
              name === "train_number" ||
              name === "department" ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )}
            </Form.Label>
          </Col>
          <Col sm="8">
            {label !== "Source" && label !== "Department" && (
              <Form.Control
                placeholder={placeholder}
                name={name}
                onChange={handleChange}
                type={type}
                required={required}
              />
            )}
            {label === "Source" && (
              <Form.Select
                aria-label="Default select example"
                onChange={handleChange}
                name={name}
                required={required}
              >
                <option>Open this select menu</option>
                <option value="Twitter">Twitter</option>
                <option value="RailMadad">RailMadad</option>
                <option value="OBHS Staff">OBHS Staff</option>
              </Form.Select>
            )}
            {label === "Department" && (
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => handleChange2(e)}
                name={name}
                required
              >
                <option value="">-</option>
                {DeptList.map((dept, index) => {
                  return (
                    <option
                      value={dept.dept_dbid}
                      key={`dept_${index}`}
                      data-dept={dept}
                    >
                      {dept.dept_name}
                    </option>
                  );
                })}
              </Form.Select>
            )}
          </Col>
        </Form.Group>
      </Col>
    </>
  );
};

const DateTimeComponent = (props) => {
  const { label, name, handleChange, required = false } = props;
  const [value, onChange] = useState(null);
  useEffect(() => {
    // console.log(value);

    handleChange({ target: { name: name, value: value } });
  }, [value]);
  return (
    <Col lg="6" className="px-3">
      <Form.Group as={Row}>
        <Col sm="4" className="my-2">
          <Form.Label>
            {label}{" "}
            {name === "receiving_datetime" ? (
              <span style={{ color: "red" }}>*</span>
            ) : (
              ""
            )}
          </Form.Label>
        </Col>

        <Col sm="8">
          <DateTimePicker
            className="form-control"
            name={name}
            onChange={onChange}
            value={value}
            // style={{ color: "#66799e" }}
            format="y-MM-dd HH:mm"
            required={required}
          />
        </Col>
      </Form.Group>
    </Col>
  );
};

export default AddComplaintModal;

{
  /* <Card.Body>
<Row className="align-items-center">
  <Col lg="6" className="px-3">
    <Form.Group as={Row}>
      <Col sm="4" className="my-2">
        <Form.Label>
          Train No.<span style={{ color: "red" }}>*</span>
        </Form.Label>
      </Col>
      <Col sm="8">
        <InputGroup>
          <Typeahead
            id="basic-example"
            onChange={handleChangeTH}
            options={trainNoList2.map((opt, index) => {
              return opt.train_number;
            })}
            placeholder="Enter to train no..."
            selected={selected}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     handleOptionSubmit(e.target.value);
            //   }
            // }}
          ></Typeahead>
        </InputGroup>
      </Col>
    </Form.Group>
  </Col>
 
  <Col lg="6" className="px-3">
    <Form.Group as={Row}>
      <Col sm="4" className="my-2">
        <Form.Label>Train Name</Form.Label>
      </Col>
      <Col sm="8">
        {trainNoList2
          .filter((opt) => opt.train_number === typeAheadValue)
          .map((filteredTrain) => (
            <Form.Control
              placeholder=""
              name="train_name"
              value={filteredTrain.alias.train_name}
              type="string"
              required={true}
              disabled={true}
              key={filteredTrain.alias.train_name}
            />
          ))}
      </Col>
    </Form.Group>
  </Col>
</Row>
</Card.Body> */
}
