import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import { InputGroup } from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../../../axios";
import { FormatSimpleDate } from "../../../../components/dateformat";
import { UpperDeckForm } from "./formHandling/upperDeckForm";
import { UnderGearForm } from "./formHandling/underGearForm";
import { ShowIssueTable } from "./previewIssues";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AlertMessage } from "./formHandling/formComponents";
import ClearLocalStorage from "../../../../components/clearLocalStorage";

export const IOHForm = (props) => {
  //renders details of selected coach
  const { coachID, handleFormSubmission, setShowDiagnoseTable } = props;
  const [coachData, setCoachData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    //Loads data for first page
    axiosInstance
      .get(`assets/coaches/${coachID}`, {})
      .then((res) => {
        const data = res.data;
        console.log(data);
        setCoachData(data);
      })
      .catch((error) => {
        console.log(error);
      });
    setShowForm(false);
    setShowAlert(false);
  }, [coachID]);

  const handleShowForm = (e) => {
    e.preventDefault();
    if (coachData.ioh_status === "Planned / Under Diagnosis") {
      setShowForm(true);
      //   console.log(coachData.assets.length);
      if (coachData.assets.length !== 0) {
        setShowDiagnoseTable(false);
      }
    } else if (coachData.ioh_status === "Issues Registered") {
      setShowAlert(true);
    }
    ClearLocalStorage();
  };

  const handleFormSubmit = (response) => {
    console.log(response);
    setShowForm(false);

    let form_data = new FormData();
    // form_data.append("coach_id", coachData.coach_id);
    form_data.append("response", JSON.stringify(response));
    axiosInstance
      .post(`assets/ioh_response/${coachData.coach_dbid}/`, form_data)
      .then((res) => {
        const data = res.data;
        console.log(data);
        // setCoachData(null);
      })
      .catch((error) => {
        console.log(error);
      });
    setCoachData(null);
    handleFormSubmission();
  };

  return (
    <>
      <div className="my-2">
        {coachData && (
          <Card className="text-center">
            <Card.Body>
              <Row>
                <Col sm="4">
                  <Table hover>
                    <tbody>
                      <tr>
                        <th>Coach No</th>
                        <td>{coachData.coach_id}</td>
                      </tr>
                      <tr>
                        <th>Coach Type</th>
                        <td>{`${coachData.coach_properties?.coach_make} ${coachData.coach_properties?.coach_type} ${coachData.coach_properties?.coach_level}`}</td>
                      </tr>
                      <tr>
                        <th>Rake/Train</th>
                        <td>
                          {coachData.rake_details?.map((item, index) => {
                            return (
                              <span
                                key={`rake_details-${index}`}
                                className="mx-1"
                              >
                                {item.rake_name}
                              </span>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col sm="4">
                  <Table hover>
                    <tbody>
                      <tr>
                        <th>POH Date</th>
                        <td>{FormatSimpleDate(coachData.p_date)}</td>
                      </tr>
                      <tr>
                        <th>IOH Due Date</th>
                        <td>{FormatSimpleDate(coachData.ioh_due_date)}</td>
                      </tr>
                      <tr>
                        <th>IOH Planned Date</th>
                        <td>{FormatSimpleDate(coachData.ioh_planned_date)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col sm="4">
                  <Table hover>
                    <tbody>
                      <tr>
                        <th>IOH Return Date</th>
                        <td>{FormatSimpleDate(coachData.r_date)}</td>
                      </tr>
                      <tr>
                        <th>POH Station</th>
                        <td>{coachData.p_shop}</td>
                      </tr>
                      <tr>
                        <th>Data Updated on</th>
                        <td>
                          {FormatSimpleDate(coachData.last_data_update_date)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {!showForm && (
                <Button
                  variant="outline-gray"
                  className="my-3"
                  onClick={handleShowForm}
                  disabled={coachData.rake_details.length !== 0}
                >
                  Start Coach Diagnosis
                </Button>
              )}
            </Card.Body>
          </Card>
        )}
      </div>
      {coachData && showAlert && (
        <AlertMessage
          variant={"warning"}
          heading={coachData.coach_id}
          message={`Issues with Coach ${coachData.coach_id} is pending`}
        />
      )}

      {coachData && showForm && coachData.assets.length === 0 && (
        <AlertMessage
          variant={"warning"}
          heading={coachData.coach_id}
          message={`Data not updated`}
        />
      )}

      {coachData && showForm && coachData.assets.length > 0 && (
        <CheckListForm
          coachData={coachData}
          handleIOHFormSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};

let myFormData = {};
const CheckListForm = (props) => {
  const { coachData, handleIOHFormSubmit } = props;
  //take assets of a coach, map it with checklist data and generates form
  //Particular asset can be multiple in quantity, so it maps & generates form for each item
  const initialFormData = {};
  const [formData, updateFormData] = useState(initialFormData);
  const [miscFormData, updateMiscFormData] = useState(initialFormData);

  const [issues, updateIssues] = useState();

  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [showModal, setshowModal] = useState(true);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const [groupTab, setGroupTab] = useState("upperDeck");
  const handleClose = () => {
    setshowModal(false);
    setShowSubmitConfirmation(false);
  };
  const upperDeckAssets = coachData.assets.filter(
    (asset) => asset.asset_group === "Upper Deck"
  );

  const underGearAssets = coachData.assets.filter(
    (asset) => asset.asset_group === "Under Gear"
  );

  const updateCoachFormData = (assetName, assetInfo) => {
    console.log(assetName, assetInfo);
    updateFormData({
      ...formData,
      [assetName]: assetInfo,
    });
    // console.log(formData);
  };

  const updateInitialData = (assetName, assetInfo) => {
    myFormData = { ...myFormData, [assetName]: assetInfo };
    updateFormData(myFormData);
  };

  const updateCoachMiscFormData = (assetName, assetInfo) => {
    console.log(assetName, assetInfo);
    updateMiscFormData(assetInfo);
    // console.log(formData);
  };

  const updateCoachMiscInitialData = (assetName, assetInfo) => {
    // myMiscFormData = { ...myMiscFormData, [assetName]: assetInfo };
    updateMiscFormData(assetInfo);
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();
    console.log(formData);
    let issues_object = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) =>
          value?.response === "NOT OK" ||
          value?.response === "Damaged" ||
          value?.response === "Missing" ||
          value?.response === "NOT AVAILABLE" ||
          value?.response === "Tilt" ||
          value?.response === "Lug Loose" ||
          value?.response === "Cock Missing" ||
          value?.response === "Torn" ||
          value?.response === "Clip Loose" ||
          value?.response === "Clip Missing" ||
          value?.response === "Shifting" ||
          value?.response === "Shifted" ||
          value?.response === "Loose" ||
          value?.response === "Dent" ||
          value?.response === "Leakage" ||
          value?.response === "Not Done" ||
          value?.response === "Failed"
      )
    );
    let fields = [
      "Not Responded",
      "Not Applied",
      "Not Released",
      "Failed",
      "Not Done",
      "No",
      "Not Stopped",
      "NOT OK",
      "Not Activated",
    ];

    let issue_from_fields = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (value?.readings) {
        // console.log(value.readings);
        Object.entries(value.readings).forEach((itemKey) => {
          // console.log(itemKey[0], itemKey[1]);
          if (itemKey) {
            if (fields.includes(itemKey[1])) {
              console.log(key, itemKey[0], itemKey[1]);
              issue_from_fields[key] = value;
            }
          }
        });
      }
    });
    // console.log(issue_from_fields);

    console.log({ ...issues_object, ...issue_from_fields });
    updateIssues({ ...issues_object, ...issue_from_fields });
    setshowModal(true);
    setShowSubmitConfirmation(true);
  };

  const handleFinalSubmission = (e) => {
    e.preventDefault();
    const dataToSend = { formData, miscFormData };
    handleIOHFormSubmit(dataToSend);
  };

  return (
    <div className="my-2">
      <MiscInfoForm
        updateCoachFormData={updateCoachMiscFormData}
        updateInitialData={updateCoachMiscInitialData}
        detachment_reason={coachData.detachment_reason}
      />
      <Tabs
        id="controlled-tab-example"
        activeKey={groupTab}
        onSelect={(k) => setGroupTab(k)}
        className="my-2 mb-3"
      >
        <Tab eventKey={"upperDeck"} title={"UpperDeck"}>
          <UpperDeckForm
            upperDeckAssets={upperDeckAssets}
            updateCoachFormData={updateCoachFormData}
            updateInitialData={updateInitialData}
            numOfCompartments={coachData.number_of_compartments}
          />
        </Tab>
        <Tab eventKey={"underGear"} title={"UnderGear"}>
          <UnderGearForm
            underGearAssets={underGearAssets}
            updateCoachFormData={updateCoachFormData}
            updateInitialData={updateInitialData}
            coachMake={coachData.coach_properties["coach_make"]}
          />
        </Tab>
      </Tabs>

      <Form onSubmit={handleFormSubmission} className="my-2">
        <Row>
          <Col xs="4">
            {/* <Button
              size="md"
              variant="outline-gray"
              onClick={(e) => {
                setShowSubmitButton(true);
                setGroupTab("underGear");
              }}
            >
              Save UpperDeck Data
            </Button> */}
            { groupTab==="upperDeck" && (
           <Button
              size="md"
              variant="outline-gray"
              onClick={(e) => {
                // setShowSubmitButton(true);
                setGroupTab("underGear");
              }}
            >
              Save UpperDeck Data
            </Button>)}

            { groupTab==="underGear" && (<Button
              size="md"
              variant="outline-gray"
              onClick={(e) => {
                setShowSubmitButton(true);
              }}
            >
              Save UnderGear Data
            </Button>)}
          </Col>
          {showSubmitButton && (
            <Col xs="4">
              <Button size="md" type="submit" variant="outline-tertiary">
                Submit IOH Diagnosis
              </Button>
            </Col>
          )}
          <Col xs="4"></Col>
        </Row>
      </Form>
      {showSubmitConfirmation && (
        <Modal
          show={showModal}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form onSubmit={handleFinalSubmission}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {coachData.coach_id}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ShowIssueTable issuesData={issues} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit Form
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
};

const MiscInfoForm = (props) => {
  const { updateCoachFormData, updateInitialData, detachment_reason } = props;
  const today = new Date().toISOString().split("T")[0];
  const initialData = {
    working_date: today,
    line_end: "BB",
    line_num: "1",
    ioh_type: detachment_reason === "IOH-NSM" ? "NSM" : "A",
    supervisor: "",
  };
  const [miscData, updateMiscData] = useState(initialData);

  useEffect(() => {
    //Loads data for first page
    updateInitialData("Misc Info", initialData);
  }, []);

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    const tempResponse = miscData;
    tempResponse[e.target.name] = e.target.value;
    updateMiscData(tempResponse);
    updateCoachFormData("Misc Info", tempResponse);
  };

  return (
    <Card>
      <Form className="m-2">
        <Row>
          <Col lg="3">
            <fieldset disabled>
              <InputGroup>
                <InputGroup.Text>Working Date</InputGroup.Text>

                <Form.Control
                  id="disabledTextInput"
                  defaultValue={today}
                  type="date"
                  name="working_date"
                  onChange={handleChange}
                />
              </InputGroup>
            </fieldset>
          </Col>

          <Col lg="2">
            <InputGroup>
              <InputGroup.Text>Line End</InputGroup.Text>
              <Form.Select name="line_end" required onChange={handleChange}>
                {["BB", "KOP"].map((option, index) => {
                  return (
                    <option key={`line-end-${index}`} value={option}>
                      {option}
                    </option>
                  );
                })}
              </Form.Select>
            </InputGroup>
          </Col>

          <Col lg="2">
            <InputGroup>
              <InputGroup.Text>Line No.</InputGroup.Text>
              <Form.Select name="line_num" required onChange={handleChange}>
                {["1", "2", "3"].map((option, index) => {
                  return (
                    <option key={`line-num-${index}`} value={option}>
                      {option}
                    </option>
                  );
                })}
              </Form.Select>
            </InputGroup>
          </Col>

          <Col lg="2">
            <InputGroup>
              <InputGroup.Text>IOH Type</InputGroup.Text>

              <Form.Select
                name="ioh_type"
                required
                onChange={handleChange}
                defaultValue={detachment_reason === "IOH-NSM" ? "NSM" : "A"}
              >
                {["A", "B", "C", "D", "NSM"].map((option, index) => {
                  return (
                    <option key={`misc-option-${index}`} value={option}>
                      {option}
                    </option>
                  );
                })}
              </Form.Select>
            </InputGroup>
          </Col>

          <Col lg="3">
            <InputGroup>
              <InputGroup.Text>Supervisor</InputGroup.Text>

              <Form.Control
                name="supervisor"
                required
                onChange={handleChange}
              />
            </InputGroup>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
