import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { axiosInstance } from "../../axios";
import Form from "react-bootstrap/Form";
import { InputGroup } from "@themesberg/react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearLocalStorage from "../../components/clearLocalStorage";
import fileDownload from "js-file-download";
import { Alert, Spinner } from "react-bootstrap";

const CoachSummary = () => {
  const [coachList, setCoachList] = useState(null);
  const [coachID, setCoachID] = useState(null);
  const [coachOptions, updateCoachOptions] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");

  useEffect(() => {
    //Loads data for first page
    axiosInstance
      .get(`assets/coaches/`, {})
      .then((res) => {
        const data = res.data;
        setCoachList(data);
        const tempCoachData = [];
        Object.values(data).forEach((item) => {
          // console.group(item.coach_id);
          tempCoachData.push(item.coach_id);
        });
        updateCoachOptions(tempCoachData);
        // console.log(tempCoachData);
        ClearLocalStorage();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getPDFReport = (selectedCoach) => {
    console.log(selectedCoach);
    axiosInstance
      .get(`assets/coach_pdf_report/?coach_id=${selectedCoach.coach_id}`, {
        responseType: "blob",
      })
      .then((res) => {
        const data = res.data;
        fileDownload(res.data, `${selectedCoach.coach_id}_report.pdf`);
        console.log(res);
        setAlertMsg("");
        setAlertVariant("");
        setShowAlert(false);
      })
      .catch((error) => {
        console.log(error);
        setShowAlert(true);
        setAlertMsg(`No report found for ${selectedCoach.coach_id}!`);
        setAlertVariant("danger");
      });
  };

  const handleChange = (e) => {
    setSelected();
    setTypeAheadValue(e[0]);
    setCoachID(null);
    setShowSuccessAlert(false);
  };
  const handleFilterButtonSubmit = (e) => {
    e.preventDefault();
    handleCoachNumberSubmit(typeAheadValue);
  };

  const handleCoachNumberSubmit = (coachID) => {
    const selectedCoach = coachList.find((item) => item.coach_id === coachID);
    if (selectedCoach) {
      setCoachID(selectedCoach.coach_dbid);
      getPDFReport(selectedCoach);
    }
  };
  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <h6>Coach Summary</h6>
        </Card.Header>{" "}
        <Card.Body>
          <Form onSubmit={handleFilterButtonSubmit}>
            <Row>
              <Col md="4">
                <Form.Group className="">
                  <InputGroup name="coach_id">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Typeahead
                      id="basic-example"
                      onChange={handleChange}
                      options={coachOptions}
                      placeholder="Enter Coach Number..."
                      selected={selected}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCoachNumberSubmit(e.target.value);
                        }
                      }}
                    ></Typeahead>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md="2">
                <Button variant="outline-gray" className="" type="submit">
                  Lookup
                </Button>
              </Col>
              {coachList === null && (
                <Card.Body
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                    animation="border"
                    role="status"
                  ></Spinner>
                </Card.Body>
              )}
              <Col md="6">
                {showAlert && (
                  <Alert
                    variant={alertVariant}
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    {alertMsg}
                  </Alert>
                )}
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CoachSummary;
