import React from "react";
import { Ratio } from "react-bootstrap";

const RailMadadElec = () => {
  return (
    <>
      {/* <div>
        <h4>RailMadad Electrical</h4>
      </div> */}
      <Ratio aspectRatio={"1x1"} className="d-none d-sm-block">
        <iframe
          // allowfullscreen
          src={`${process.env.REACT_APP_DASHBOARD_RAIL_MADAD_ELECT}`}
          height="100%"
          width="100%"
          title="Dashboard"
        ></iframe>
      </Ratio>
    </>
  );
};

export default RailMadadElec;
