import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import {
  MapAssetL1WithAccordion,
  MapAssetL1WithoutAccordion,
} from "./assetForm";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from "react-bootstrap";

export const UnderGearForm = (props) => {
  const { underGearAssets, updateCoachFormData, updateInitialData, coachMake } =
    props;
  // console.log(underGearAssets);
  const [activePage, setactivePage] = useState(1);
  const [itemToOpen, setItemToOpen] = useState(1);

  const getPageAssets = (page) => {
    return underGearAssets.filter((asset) => asset.show_in_page_num === page);
  };
  const [page1FormData, setpage1FormData] = useState(getPageAssets(1));
  const [page2FormData, setpage2FormData] = useState(getPageAssets(2));
  const [page3FormData, setpage3FormData] = useState(getPageAssets(3));
  const [page4FormData, setpage4FormData] = useState(getPageAssets(4));
  const [page5FormData, setpage5FormData] = useState(getPageAssets(5));
  const [page6FormData, setpage6FormData] = useState(getPageAssets(6));
  // const [page7FormData, setpage7FormData] = useState(getPageAssets(7));
  const [page7FormData, setpage7FormData] = useState(coachMake === "LHB" ? getPageAssets(8) : getPageAssets(7));

  const [page8FormData, setpage8FormData] = useState(getPageAssets(8));

  const {
    totalPages = coachMake === "LHB" ? 7 : 8,
    // totalPages = coachMake === "LHB" ? 3 : 7,
    size = "md",
    withIcons = false,
    disablePrev = false,
  } = props;

  const onPrevItem = () => {
    const prevactivePage = activePage === 1 ? activePage : activePage - 1;
    setactivePage(prevactivePage);
    setItemToOpen(prevactivePage);
  };

  const onNextItem = (totalPages) => {
    const nextactivePage =
      activePage === totalPages ? activePage : activePage + 1;
    setactivePage(nextactivePage);
    setItemToOpen(nextactivePage);
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    const isItemActive = activePage === number;

    const handlePaginationChange = () => {
      setactivePage(number);
      setItemToOpen(number);
    };

    items.push(
      <Pagination.Item
        aria-controls={`example-collapse-text-${number}`}
        aria-expanded={itemToOpen}
        active={isItemActive}
        key={number}
        onClick={handlePaginationChange}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div>
      <Col xl={12}>
        <Pagination size={size} className="mt-3">
          <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
            {withIcons ? (
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            ) : (
              "Previous"
            )}
          </Pagination.Prev>
          {items}
          <Pagination.Next onClick={() => onNextItem(totalPages)}>
            {withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Next"}
          </Pagination.Next>
        </Pagination>
      </Col>
      {page1FormData.length > 0 && (
        <Collapse in={itemToOpen === 1}>
          <div id="example-collapse-text-1">
            <RenderPageData
              pageData={page1FormData}
              updateCoachFormData={updateCoachFormData}
              updateInitialData={updateInitialData}
            />
          </div>
        </Collapse>
      )}

      {page2FormData.length > 0 && (
        <Collapse in={itemToOpen === 2}>
          <div id="example-collapse-text-2">
            <RenderPageData
              pageData={page2FormData}
              updateCoachFormData={updateCoachFormData}
              updateInitialData={updateInitialData}
            />
          </div>
        </Collapse>
      )}

      {page3FormData.length > 0 && (
        <Collapse in={itemToOpen === 3}>
          <div id="example-collapse-text-3">
            {coachMake === "ICF" && (
              <RenderPageDataWithAccordion
                pageData={page3FormData}
                updateCoachFormData={updateCoachFormData}
                updateInitialData={updateInitialData}
              />
            )}
            {coachMake === "LHB" && (
              <RenderPageData
                pageData={page3FormData}
                updateCoachFormData={updateCoachFormData}
                updateInitialData={updateInitialData}
              />
            )}
          </div>
        </Collapse>
      )}

      {page4FormData.length > 0 && (
        <Collapse in={itemToOpen === 4}>
          <div id="example-collapse-text-4">
            <RenderPageDataWithAccordion
              pageData={page4FormData}
              updateCoachFormData={updateCoachFormData}
              updateInitialData={updateInitialData}
            />
           
          </div>
        </Collapse>
      )}

      {page5FormData.length > 0 && (
        <Collapse in={itemToOpen === 5}>
          <div id="example-collapse-text-5">
            <RenderPageDataWithAccordion
              pageData={page5FormData}
              updateCoachFormData={updateCoachFormData}
              updateInitialData={updateInitialData}
            />
          </div>
        </Collapse>
      )}

      {page6FormData.length > 0 && (
        <Collapse in={itemToOpen === 6}>
          <div id="example-collapse-text-6">
            <RenderPageDataWithAccordion
              pageData={page6FormData}
              updateCoachFormData={updateCoachFormData}
              updateInitialData={updateInitialData}
            />
          </div>
        </Collapse>
      )}

      {page7FormData.length > 0 && (
        <Collapse in={itemToOpen === 7}>
          <div id="example-collapse-text-7">
            <RenderPageDataWithAccordion
              pageData={page7FormData}
              updateCoachFormData={updateCoachFormData}
              updateInitialData={updateInitialData}
            />
          </div>
        </Collapse>
      )}
      {page8FormData.length > 0 && (
        <Collapse in={itemToOpen === 8}>
          <div id="example-collapse-text-8">
            <RenderPageDataWithAccordion
              pageData={page8FormData}
              updateCoachFormData={updateCoachFormData}
              updateInitialData={updateInitialData}
            />
          </div>
        </Collapse>
      )}

      <Col xl={12}>
        <Pagination size={size} className="mt-3">
          <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
            {withIcons ? (
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            ) : (
              "Previous"
            )}
          </Pagination.Prev>
          {items}
          <Pagination.Next onClick={() => onNextItem(totalPages)}>
            {withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Next"}
          </Pagination.Next>
        </Pagination>
      </Col>
    </div>
  );
};

const RenderPageData = (props) => {
  const { pageData, updateCoachFormData, updateInitialData } = props;
  return (
    <div>
      {pageData.map((asset, index) => {
        return (
          <Col
            sm="12"
            key={`page1_asset_${asset.asset_l1_dbid}${index}`}
            class="collapse"
          >
            <MapAssetL1WithoutAccordion
              assetL1={asset}
              index={index}
              callback={updateCoachFormData}
              callbackinit={updateInitialData}
              colSize={12}
            />
          </Col>
        );
      })}
    </div>
  );
};

const RenderPageDataWithAccordion = (props) => {
  const { pageData, updateCoachFormData, updateInitialData, pageNumber } =
    props;

  return (
    <Accordion
      // defaultActiveKey={`assetL1_${pageData[0].asset_l1_dbid}0`}
      class="collapse"
      id={`multiCollapseExample${pageNumber}`}
    >
      <Row>
        {pageData.map((asset, index) => {
          return (
            <Col sm="12" key={`page3_asset_${asset.asset_l1_dbid}${index}`}>
              <MapAssetL1WithAccordion
                assetL1={asset}
                index={index}
                callback={updateCoachFormData}
                callbackinit={updateInitialData}
                colSize={12}
              />
            </Col>
          );
        })}
      </Row>
    </Accordion>
  );
};
