import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Badge from "react-bootstrap/Badge";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

const TrainInformation = (props) => {
  const { coachDataSchedule } = props;
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-top">
            {coachDataSchedule[0]?.sun && (
              <span className="mx-1" key="sun">
                <Badge className="mx-2" bg="gray">
                  Sun
                </Badge>
              </span>
            )}
            {coachDataSchedule[0]?.mon && (
              <span className="mx-1" key="mon">
                <Badge className="mx-2" bg="gray">
                  Mon
                </Badge>
              </span>
            )}
            {coachDataSchedule[0]?.tue && (
              <span className="mx-1">
                <Badge className="mx-2" bg="gray">
                  Tue
                </Badge>
              </span>
            )}
            {coachDataSchedule[0]?.wed && (
              <span className="mx-1">
                <Badge className="mx-2" bg="gray">
                  Wed
                </Badge>
              </span>
            )}
            {coachDataSchedule[0]?.thu && (
              <span className="mx-1">
                <Badge className="mx-2" bg="gray">
                  Thu
                </Badge>
              </span>
            )}
            {coachDataSchedule[0]?.fri && (
              <span className="mx-1">
                <Badge className="mx-2" bg="gray">
                  Fri
                </Badge>
              </span>
            )}
            {coachDataSchedule[0]?.sat && (
              <span className="mx-1">
                <Badge className="mx-2" bg="gray">
                  Sat
                </Badge>
              </span>
            )}
          </Tooltip>
        }
      >
        <Button variant="light">{coachDataSchedule[0]?.train_number}</Button>
      </OverlayTrigger>
    </>
  );
};

export default TrainInformation;
