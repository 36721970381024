import React, { useState, useEffect } from "react";
import { faEdit, faXmark, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Form,
  Table,
  Row,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import CreateStaff from "./CreateStaff";
import DeleteStaff from "./DeleteStaff";
import UpdateStaff from "./UpdateStaff";
import UploadStaffFile from "./UploadStaffFile";
import { GetStaffListAPI } from "../../../api/StaffApi";
import { Typeahead } from "react-bootstrap-typeahead";

const MainStaffManagement = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddFile, setShowAddFile] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [allStaffList, setAllStaffList] = useState(null);
  const [oneStaffData, setOneStaffData] = useState(null);
  const [delStaffData, setDelStaffData] = useState(null);

  // searchbar logic
  const [selected, setSelected] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState("");
  const [typeAheadSerchingOptions, setTypeAheadSerchingOptions] = useState([]);
  // const [currentItem, setCurrentItem] = useState(null);
  const [specificStaffList, setSpecificStaffList] = useState(null);

  useEffect(() => {
    callGetStaffList();
  }, []);
  useEffect(() => {
    setSpecificStaffList(allStaffList);
  }, [allStaffList]);

  const callGetStaffList = async () => {
    try {
      const res = await GetStaffListAPI();
      console.log(res.data);
      setAllStaffList(res.data);

      // searchbar
      const tempData = [];
      res.data.forEach((item) => {
        if (item.full_name && !tempData.includes(item.full_name)) {
          tempData.push(item.full_name);
        }
        if (item.mobile_num && !tempData.includes(item.mobile_num)) {
          tempData.push(item.mobile_num);
        }
        if (item.designation && !tempData.includes(item.designation)) {
          tempData.push(item.designation);
        }
      });
      setTypeAheadSerchingOptions(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  // add
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => {
    setShowAddModal(true);
  };
  const handleCloseAddFile = () => setShowAddFile(false);
  const handleShowAddFile = () => {
    setShowAddFile(true);
  };

  // update
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = (oneStaff) => {
    setShowEditModal(true);
    setOneStaffData(oneStaff);
  };

  // delete
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = (oneStaff) => {
    setShowDeleteModal(true);
    setDelStaffData(oneStaff);
  };

  // handle list when post, patch, delete
  const handlePostDataOnList = (data) => {
    const tempList = allStaffList;
    tempList.unshift(data);
    setAllStaffList(tempList);
    handleCloseAddModal();
  };
  const handleDeleteDataOnList = (id) => {
    const tempList = allStaffList;
    const tempList2 = tempList.filter((item) => item.staff_dbid !== id);
    setAllStaffList(tempList2);
    handleCloseDeleteModal();
  };
  const handlePatchDataOnList = (staffID, updatedData) => {
    const tempList = allStaffList;
    const tempList2 = tempList.filter((item) => item.staff_dbid !== staffID);
    tempList2.unshift(updatedData);
    setAllStaffList(tempList2);

    handleCloseEditModal();
  };
  const handleNewRespData = (data) => {
    setAllStaffList(data);
    handleCloseAddFile();
  };

  // searchbar logic
  const handleSearchbarChange = (e) => {
    console.log(e);
    setSelected();
    setTypeAheadValue(e[0]);
    // setCurrentItem(null);
    setSpecificStaffList(null);
  };

  const handleSearchButtonSubmit = (e) => {
    e.preventDefault();
    handleSubmitMain(typeAheadValue);
  };

  const handleSubmitMain = (term) => {
    if (term) {
      let filteredData = allStaffList.filter((item) => item.full_name === term);
      if (filteredData.length === 0) {
        filteredData = allStaffList.filter((item) => item.mobile_num === term);
      }
      if (filteredData.length === 0) {
        filteredData = allStaffList.filter((item) => item.designation === term);
      }

      // setCurrentItem(null);
      setSpecificStaffList(filteredData);
    }
  };

  return (
    <div className="page-container">
      <Card>
        <Card.Header className="bg-light d-flex justify-content-between align-items-center">
          <Col sm="8">
            <h4>Staff Management</h4>
          </Col>
          <Col>
            <Button
              onClick={handleShowAddFile}
              className="mx-1"
              variant="outline-tertiary"
            >
              File Upload
            </Button>
            <Button
              onClick={handleShowAddModal}
              className="mx-1"
              variant="outline-tertiary"
            >
              Create New Staff
            </Button>
          </Col>
        </Card.Header>
      </Card>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSearchButtonSubmit}>
            <Row>
              <Col xs="8">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>

                  <Typeahead
                    id="basic-example"
                    onChange={handleSearchbarChange}
                    options={typeAheadSerchingOptions}
                    placeholder="Enter details..."
                    selected={selected}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmitMain(e.target.value);
                      }
                    }}
                  ></Typeahead>
                </InputGroup>
              </Col>

              <Col xs="2">
                <Button variant="outline-gray" className="" type="submit">
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
          {allStaffList === null && (
            <Card.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                style={{
                  width: "100px",
                  height: "100px",
                }}
                animation="border"
                role="status"
              ></Spinner>
            </Card.Body>
          )}
          <Table className="text-center" striped bordered hover responsive>
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Mobile</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {specificStaffList &&
                specificStaffList.map((oneStaff, index) => {
                  return (
                    <tr key={index + 1} className="my-3">
                      <th>{index + 1}</th>
                      <th>{oneStaff.full_name}</th>
                      <th>{oneStaff.designation}</th>
                      <th>{oneStaff.mobile_num}</th>
                      <th>
                        <>
                          <Button
                            onClick={() => handleShowEditModal(oneStaff)}
                            variant="outline-gray"
                            className="mx-2"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>

                          <Button
                            onClick={() => handleShowDeleteModal(oneStaff)}
                            variant="outline-gray"
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </Button>
                        </>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {showAddModal && (
        <CreateStaff
          handleCloseAddModal={handleCloseAddModal}
          handlePostDataOnList={handlePostDataOnList}
        />
      )}
      {showAddFile && (
        <UploadStaffFile
          handleCloseAddFile={handleCloseAddFile}
          handleNewRespData={handleNewRespData}
        />
      )}
      {showEditModal && oneStaffData && (
        <UpdateStaff
          handleCloseEditModal={handleCloseEditModal}
          handlePatchDataOnList={handlePatchDataOnList}
          oneStaffData={oneStaffData}
        />
      )}
      {showDeleteModal && delStaffData && (
        <DeleteStaff
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDeleteDataOnList={handleDeleteDataOnList}
          delStaffData={delStaffData}
        />
      )}
    </div>
  );
};

export default MainStaffManagement;
