import React, { useEffect, useState } from "react";
import { clickOrFocusInput } from "react-bootstrap-typeahead/types/core/Typeahead";
import Table from "react-bootstrap/Table";

export const ShowIssueTable = (props) => {
  const { issuesData } = props;
  console.log(issuesData);
  return (
    <Table
      bordered
      hover
      responsive
      className="table-centered table-nowrap rounded mb-0"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Asset Name</th>
          <th>Response</th>
          <th>IssuesL1</th>
          <th>IssuesL2</th>
        </tr>
      </thead>

      <tbody>
        {Object.keys(issuesData).map((assetIssue, index) => {
          return (
            <IssueTable
              key={`issue_${index}`}
              assetName={assetIssue}
              asset={issuesData[assetIssue]}
              index={index}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

const IssueTable = (props) => {
  const { assetName, asset, index } = props;
  const [issuesL1, updateIssuesL1] = useState([]);
  console.log(asset.issuesL1);
  const issuesL2 = asset.issuesL2;

  let fields = [
    "Not Responded",
    "Not Applied",
    "Not Released",
    "Failed",
    "Not Done",
    "No",
    "Not Stopped",
    "NOT OK",
    "Not Activated",
  ];

  useEffect(() => {
    if (asset?.readings) {
      let other_issues = asset.issuesL1;
      // console.log(value.readings);
      Object.entries(asset.readings).forEach((itemKey) => {
        // console.log(itemKey[0], itemKey[1]);

        if (itemKey) {
          if (fields.includes(itemKey[1])) {
            // console.log(itemKey[0], itemKey[1]);
            other_issues.push(`${itemKey[0]} : ${itemKey[1]} `);
          }
        }
      });
      updateIssuesL1(other_issues);
      // console.log(other_issues);
    }
  }, []);

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{assetName}</td>
      <td>{asset.response}</td>
      <td>
        {issuesL1.length > 0 &&
          issuesL1.map((assetL1Issue, index) => {
            return <li key={`${assetName}_issues_${index}`}>{assetL1Issue}</li>;
          })}
      </td>
      <td>
        {issuesL2 &&
          Object.keys(issuesL2).map((assetL2Issue, index) => {
            return (
              <li
                key={`${assetName}_issues_${index}`}
              >{`${assetL2Issue} : ${issuesL2[assetL2Issue].response}`}</li>
            );
          })}
      </td>
    </tr>
  );
};
