import { axiosInstance } from "../axios";

const url = "auth";

export const GetStaffListAPI = async () => {
    return await axiosInstance.get(`${url}/staff/`);
};

export const PostStaffAPI = async (data) => {
    return await axiosInstance.post(`${url}/staff/`, data);
}

export const PatchStaffAPI = async (id, data) => {
    return await axiosInstance.patch(`${url}/staff/${id}/`, data);
}

export const DeleteStaffAPI = async (id) => {
    return await axiosInstance.delete(`${url}/staff/${id}/`);
}

// file upload api has different header
export const PostFileStaffAPI = async (data, headerData) => {
    return await axiosInstance.post(`${url}/staff-upload/`, data, headerData);
}