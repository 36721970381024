import React, { useEffect, useState } from "react";
import {
  Accordion,
  Card,
  Form,
  Button,
  Row,
  Col,
  FormControl,
} from "react-bootstrap";
import { useItem } from "react-bootstrap-typeahead";
import { axiosInstance } from "../../../axios";
import { MapAssetL1WithoutAccordion } from "../ioh/diagnose/formHandling/assetForm";
import CategoryAccordion from "../ioh/diagnose/formHandling/categoryAccordion";
import {
  airBrakeItems,
  CarpentryItems,
  ElectricalItems,
  MiscellaneousItems,
  PestAndRodentItems,
  PlumbingItems,
  SuspensionItems,
  TrimmingItems,
  UnderGearItems,
  WheelSetItems,
  OtherItems,
} from "./checklist";

const NSMDiagnose = (props) => {
  const { handleNSMDiagnosis, reEditing, coachID } = props;
  const [markingData, updateMarkingData] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [removalLocation, updateRemovalLocation] = useState(null);

  useEffect(async () => {
    if (reEditing) {
      const res = await axiosInstance.get(
        `assets/coach-issues/?coach_id=${coachID}`,
        {}
      );
      const data = res.data;
      Object.values(data.issues).forEach((item) => {
        localStorage.setItem(item.asset_l1_detailed_name, item.asset_l1_issue);
      });
      setOpenForm(true);
    } else {
      setOpenForm(true);
    }
  }, []);
  const handleIssueMarking = (label, data) => {
    let tempMarkingData = markingData;
    if (data.response === "OK") {
      delete tempMarkingData[label];
      updateMarkingData(tempMarkingData);
      console.log(tempMarkingData);
    } else {
      updateMarkingData({ ...markingData, [label]: data });
      console.log({ ...markingData, [label]: data });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(markingData);
    if (markingData && Object.keys(markingData).length > 0) {
      handleNSMDiagnosis(markingData, removalLocation);
    }
  };

  const handleLocationChange = (e) => {
    console.log(e.target.value);
    updateRemovalLocation(e.target.value.toUpperCase());
  };
  return (
    <Card>
      <Form>
        {openForm && (
          <Card.Body>
            <Accordion>
              <MainHeadRender
                label="Air Brake"
                eventKey="air_brake"
                categoryData={airBrakeItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Carpentry"
                eventKey="carpentry"
                categoryData={CarpentryItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Electrical"
                eventKey="electrical"
                categoryData={ElectricalItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Miscellaneous"
                eventKey="miscellaneous"
                categoryData={MiscellaneousItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Pest and Rodent"
                eventKey="pest_and_rodent"
                categoryData={PestAndRodentItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Plumbing"
                eventKey="plumbing"
                categoryData={PlumbingItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Suspension"
                eventKey="suspension"
                categoryData={SuspensionItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Trimming"
                eventKey="trimming"
                categoryData={TrimmingItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Under Gear"
                eventKey="under_gear"
                categoryData={UnderGearItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Wheel Set"
                eventKey="wheelset"
                categoryData={WheelSetItems}
                handleIssueMarking={handleIssueMarking}
              />
              <MainHeadRender
                label="Other"
                eventKey="other"
                categoryData={OtherItems}
                handleIssueMarking={handleIssueMarking}
              />
            </Accordion>
          </Card.Body>
        )}
        <Card.Footer>
          <Row className="">
            <Col sm="3">
              <Form.Label>Current Location</Form.Label>
            </Col>
            <Col sm="6">
              <FormControl
                placeholder="Enter Location"
                aria-label="location"
                aria-describedby="basic-addon1"
                onChange={handleLocationChange}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Button variant="outline-success" onClick={handleSubmit}>
                Submit
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Form>
    </Card>
  );
};

const MainHeadRender = (props) => {
  const { label, eventKey, categoryData, handleIssueMarking } = props;

  const updateInitialData = (data, data2) => {
    // console.log(data, data2);
  };
  const updateCoachFormData = (heading, data) => {
    if (data) {
      data["main_head"] = label;
      // console.log(heading, data);
      handleIssueMarking(heading, data);
    }
  };
  return (
    <>
      {label !== "Other" && (
        <CategoryAccordion
          eventKey={eventKey}
          label={label}
          categoryData={categoryData}
          updateInitialData={updateInitialData}
          updateCoachFormData={updateCoachFormData}
          mode="nsm"
        />
      )}
      {label === "Other" && (
        <Row className="my-2 mx-1">
          <Col sm="6">
            <MapAssetL1WithoutAccordion
              assetCategory={"Other"}
              assetL1={categoryData[0]}
              index={0}
              callback={updateCoachFormData}
              callbackinit={updateInitialData}
              mode="nsm"
              colSize="12"
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default NSMDiagnose;
