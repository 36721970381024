import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { axiosInstance } from "../../../axios";
import Col from "react-bootstrap/Col";
import { FloatingLabel } from "react-bootstrap";

const UploadComplaints = (props) => {
  const { handleModalClose, handleComplaintUpload } = props;

  const DeptList = JSON.parse(localStorage.getItem("DeptList"));
  const userdeptList = JSON.parse(localStorage.getItem("user_dept"));

  const [showSpinner, setShowSpinner] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);

  const [file, setFile] = useState(null);
  const [department, setDepartmnt] = useState(
    userdeptList.length === 1 ? userdeptList[0].dept_dbid : ""
  );
  const handleChange = (e) => {
    const document = e.target.files[0];
    //console.log(img);
    if (document) {
      setFile(document);
      console.log(document);
    } else {
      setFile(null);
    }
  };
  const handleDepartmentChange = (e) => {
    setDepartmnt(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("department", department);
    setShowSpinner(true);

    if (file) {
      let form_data = new FormData();
      form_data.append("document", file, file.name);
      if (userdeptList.length === 1) {
        form_data.append("department", userdeptList[0].dept_dbid.toString());
      } else {
        form_data.append("department", department);
      }

      console.log(form_data);
      axiosInstance
        .post("assets/complaints-upload/", form_data, {
          headers: {
            "content-type": "multipart/form-data",
          },
          timeout: 1 * 120000,
        })
        .then((res) => {
          const data = res.data;
          console.log(data);
          handleComplaintUpload(data);
          setShowSpinner(false);
          handleModalClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <Modal
        show={true}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Complaints</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <>
              <Form.Control
                type="file"
                accept=".xls,.xlsx"
                onChange={handleChange}
                required
              />

              <Form.Group as={Row}>
                <Col sm="12" className="my-2">
                  {userdeptList.length === 1 ? (
                    <Form.Control
                      value={userdeptList[0].dept_name}
                      name="department"
                      type="string"
                      disabled
                    />
                  ) : (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Department"
                      className="mb-3"
                    >
                      <Form.Select
                        onChange={handleDepartmentChange}
                        name="department"
                        required
                      >
                        <option value="">-</option>

                        {DeptList.map((dept, index) => {
                          return (
                            <option
                              value={dept.dept_dbid}
                              key={`dept_${index}`}
                            >
                              {dept.dept_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  )}
                </Col>
              </Form.Group>

              {showSpinner && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="grow" variant="info" size="md" />
                  <Spinner animation="grow" variant="success" />
                  <Spinner animation="grow" variant="warning" />
                </div>
              )}

              {/* <>
                {showAlert && (
                  <Alert
                    variant="success"
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    Successfully uploaded!
                  </Alert>
                )}
              </> */}
            </>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="gray" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="tertiary" type="submit">
              Upload
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UploadComplaints;
